import Cookies from "js-cookie";

export async function fetchUserData(userId?: string, token?: string): Promise<any> {
  const urlMapping: { [key: string]: string } = {
    prod: process.env.REACT_APP_PROD_URL!,
    dev: process.env.REACT_APP_DEV_URL!,
    // you can add staging or other environments here
  };

  const environment = process.env.REACT_APP_ENVIRONMENT!;
  const url = urlMapping[environment] || process.env.REACT_APP_DEV_URL!;

  // Retrieve userId and token from cookies if they are not provided as arguments
  if (!userId) {
    userId = Cookies.get("userId")!;
  }

  if (!token) {
    token = Cookies.get("token")!;
  }

  // Check again after trying to get them from cookies
  if (!userId || !token) {
    console.log("UserId or token not available.");
    return null;
  }

  try {
    const response = await fetch(`${url}user/${userId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      return await response.json();
    } else {
      const error = await response.json();

      // Check if the error message indicates that the token has expired
      if (error.error === 'Token expired') {
        // Remove userId and token from the cookie
        Cookies.remove("userId");
        Cookies.remove("token");
      }
      throw new Error('Error fetching user data.');
    }
  } catch (error) {
    // Check if error is an instance of Error and access its properties
    if (error instanceof Error) {
      return error.message;
    }

    return 'Error occurred';
  }
}
