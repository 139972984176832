import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { motion, AnimatePresence } from "framer-motion";
import './CookieConsent.css'; // Assuming that your styles are saved in a file called CookieConsent.css in the same directory

interface CookieProps {
    cookieName: string;
    cookieDuration: number;
    message: string;
    buttonTextAllow: string;
    buttonTextReject: string;
}

const CookieConsent: React.FC<CookieProps> = ({cookieName, cookieDuration, message, buttonTextAllow, buttonTextReject}) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if(!Cookies.get(cookieName)) {
            setVisible(true);
        }
    }, [cookieName]);

    const setCookie = (accepted: boolean) => {
        Cookies.set(cookieName, accepted ? "accepted" : "rejected", { expires: cookieDuration });
        setVisible(false);
    }

    return (
        <AnimatePresence>
            {visible && (
                <motion.div className="cookie-banner" initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 100 }}>
                    <div className="box">
                        {message}
                    </div>
                    <div>
                        <button className="navigation-icon reject" onClick={() => setCookie(false)}>
                            {buttonTextReject}
                        </button>
                        <button className="navigation-icon" onClick={() => setCookie(true)}>
                            {buttonTextAllow}
                        </button>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default CookieConsent;
