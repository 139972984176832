import React from "react";
import "./NotLoggedIn.css";
import { Link } from "react-router-dom";

const NotLoggedIn: React.FC = () => {
  return (
    <div className="not-logged-in">
      <h1>Nie ste prihlásený</h1>
      <p>
        Prosím <Link to="/login">prihláste sa</Link> a pokračujte
      </p>
    </div>
  );
};

export default NotLoggedIn;
