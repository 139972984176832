import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./UserDetails.css";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface User {
  _id: string;
  email: string;
  role: string;
  variableSymbol: string;
  createdAt?: Date;
  updatedAt?: Date;
  name?: string;
  surname?: string;
  address?: string;
  city?: string;
  postCode?: string;
  phoneNumber?: string;
  childrens?: string[];
  payments?: string[];
  // Add other properties as needed
}

const UserDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedFields, setEditedFields] = useState<Partial<User>>({});
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(url + `user/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setUser(data);
        console.log("data", data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [id, token, url]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setEditedFields({ ...editedFields, [name]: value });
  };

  const handleSaveChanges = async (): Promise<void> => {
    if (user && editedFields._id !== undefined) {
      //   try {
      //     const response = await fetch(url + `user/${id}`, {
      //       method: "PUT",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(editedFields),
      //     });
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok.");
      //     }
      //     setIsEditing(false);
      //   } catch (error) {
      //     console.error("Error updating user:", error);
      //   }
    }
  };

  const paymentDetails = (paymentId: string | undefined) => {
    console.log("TODO + " + paymentId);
  };

  const handleAddChild = () => {
    setEditedFields({
      ...editedFields,
      childrens: [...(editedFields?.childrens || []), "newChildId"],
    });
  };

  const handleAddPayment = () => {
    setEditedFields({
      ...editedFields,
      payments: [...(editedFields?.payments || []), "newPaymentId"],
    });
  };

  const handleRemoveChild = (childId: string) => {
    setEditedFields({
      ...editedFields,
      childrens: (editedFields?.childrens || []).filter((id) => id !== childId),
    });
  };

  const handleRemovePayment = (paymentId: string) => {
    setEditedFields({
      ...editedFields,
      payments: (editedFields?.payments || []).filter((id) => id !== paymentId),
    });
  };

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="user-details-container">
      {user ? (
        <>
          <h2>User Details</h2>
          <div className="user-info">
            <span>Email: {user.email}</span>
            <span>Role: {user.role}</span>
            <span>Variable Symbol: {user.variableSymbol}</span>
            {isEditing ? (
              <>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  value={editedFields.name || user.name || ""}
                  onChange={handleInputChange}
                  //   className={`user-info-edit ${editedFields === "name" ? "edited" : ""}`}
                />
                <label htmlFor="surname">Surname</label>
                <input
                  type="text"
                  name="surname"
                  value={editedFields.surname || user.surname || ""}
                  onChange={handleInputChange}
                  //   className={`user-info-edit ${editedFields === "surname" ? "edited" : ""}`}
                />
                {/* Add other editable fields here */}
                <label htmlFor="address">Address</label>
                <input
                  type="text"
                  name="address"
                  value={editedFields.address || user.address || ""}
                  onChange={handleInputChange}
                  //   className={`user-info-edit ${editedFields === "address" ? "edited" : ""}`}
                />
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  value={editedFields.city || user.city || ""}
                  onChange={handleInputChange}
                  //   className={`user-info-edit ${editedFields === "city" ? "edited" : ""}`}
                />
                <label htmlFor="postCode">Post Code</label>
                <input
                  type="text"
                  name="postCode"
                  value={editedFields.postCode || user.postCode || ""}
                  onChange={handleInputChange}
                  //   className={`user-info-edit ${editedFields === "postCode" ? "edited" : ""}`}
                />
                <label htmlFor="phoneNumber">Phone Number</label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={editedFields.phoneNumber || user.phoneNumber || ""}
                  onChange={handleInputChange}
                  //   className={`user-info-edit ${editedFields === "phoneNumber" ? "edited" : ""}`}
                />
                <label htmlFor="childrens">Childrens</label>
                <div>
                  {(editedFields?.childrens || user.childrens || []).map((childId) => (
                    <div key={childId}>
                      <span>Child ID: {childId}</span>
                      <button onClick={() => handleRemoveChild(childId)}>Remove</button>
                    </div>
                  ))}
                  <button onClick={handleAddChild}>Add Child</button>
                </div>
                <label htmlFor="payments">Payments</label>
                <div>
                  {(editedFields?.payments || user.payments || []).map((paymentId) => (
                    <div key={paymentId}>
                      <span>Payment ID: {paymentId}</span>
                      <button onClick={() => handleRemovePayment(paymentId)}>Remove</button>
                    </div>
                  ))}
                  <button onClick={handleAddPayment}>Add Payment</button>
                </div>
                <button onClick={handleSaveChanges}>Save</button>
                <button onClick={() => setIsEditing(false)}>Cancel</button>
              </>
            ) : (
              <>
                <span>Name: {user.name || ""}</span>
                <span>Surname: {user.surname || ""}</span>
                <span>Address: {user.address || ""}</span>
                <span>City: {user.city || ""}</span>
                <span>Post Code: {user.postCode || ""}</span>
                <span>Phone Number: {user.phoneNumber || ""}</span>
                {user.childrens && user.childrens.length > 0 && (
                  <span>
                    {user.childrens.map((childId) => (
                      <div key={childId}>
                        <span>Child Details: {childId}</span>
                        <Link to={`/children/${childId}/details`}>
                          <button>View Details</button>
                        </Link>
                      </div>
                    ))}
                  </span>
                )}
                {user.payments && user.payments.length > 0 && (
                  <span>
                    {user.payments.map((paymentId) => (
                      <div key={paymentId}>
                        <span>Payment Details: {paymentId}</span>
                        <button onClick={() => paymentDetails(paymentId)}>View Details</button>
                      </div>
                    ))}
                  </span>
                )}
                <span>Created At: {user.createdAt || ""}</span>
                <span>Updated At: {user.updatedAt || ""}</span>
                <span>Id: {user._id}</span>
                <span>Variable Symbol: {user.variableSymbol}</span>
                <button onClick={() => setIsEditing(true)}>Edit</button>
              </>
            )}
          </div>
          <div className="user-links">
            <Link to={`/attendance/details/${user._id}`}>
              <button>Attendance Details</button>
            </Link>
            <Link to={`/children/details/${user._id}`}>
              <button>Children Details</button>
            </Link>
            {/* Add other links here */}
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default UserDetails;
