import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import "./style/assignCourseToLector.css"; // Import the CSS file for styling

interface Course {
  _id: string;
  time: string;
  schoolName: string;
  lectorId: string;
  // Add other properties as needed
}

interface AssignCourseToLectorProps {
  userId: string;
}


const AssignCourseToLector = ({userId}:AssignCourseToLectorProps ) => {
  const { id } = useParams<{ id: string }>(); // Get the lector ID from the URL
  const [allCourses, setAllCourses] = useState<Course[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<string>(""); // Initialize as an empty string
  const navigate = useNavigate();
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod"
      ? process.env.REACT_APP_PROD_URL
      : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchAllCourses = async () => {
      try {
        const response = await fetch(url + "course/allCourses",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        // Filter out courses that are already assigned to the lector
        const filteredCourses = data.courses.filter(
            (course: Course) => course.lectorId === undefined || course.lectorId === "" || course.lectorId === null
        );
        setAllCourses(filteredCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchAllCourses();
  }, [token, url, userId]);

  const handleAssignCourse = async (): Promise<void> => {
    if (selectedCourseId && id) {
      try {
        const response = await fetch(url + `lector/assignCourse/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
          body: JSON.stringify({ courseId: selectedCourseId }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        // Course successfully assigned to the lector
        // You can perform any other actions here if needed
      } catch (error) {
        console.error("Error assigning course to lector:", error);
      }
    }
    // redirect to lector page
    navigate(`/admin/lector/`);

  };

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="assign-course-to-lector-container">
      <h2>Assign Course to Lector</h2>
      <label>Select a Course:</label>
      <select
        value={selectedCourseId}
        onChange={(e) => setSelectedCourseId(e.target.value)}
        className="course-select"
      >
        <option value="" disabled>
          Select Course
        </option>
        {allCourses.map((course) => (
          <option key={course._id} value={course._id}>
            {course.schoolName + ' ' + course.time}
          </option>
        ))}
      </select>
      <button onClick={handleAssignCourse}>Assign Course</button>
    </div>
  );
};

export default AssignCourseToLector;
