// Main2.tsx
import React from "react";
import { FaGraduationCap, FaClipboardCheck, FaBook, FaUserTie } from "react-icons/fa"; // Importing the required icons
// check mark icon

// icon for town
import { MdLocationOn } from "react-icons/md";
// icon for primary school
import { GiSchoolBag } from "react-icons/gi";
import "./Main2.css";
import Cookies from "js-cookie";

interface Main2Props {
  userId: string;
}

const Main2 = ({ userId }: Main2Props) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (userId) {
      setIsLoggedIn(true);
    }
    // get userId from Cookies
    const userIdCookie = Cookies.get("userId");
    if (userIdCookie) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userId]);

  return (
    <>
      {!isLoggedIn ? (
        <>
         <div className="container2">
        <div className="content">
          <h1>Programování kroužků přímo na základních školách</h1>
          <p>
          Chcete se naučit programovat z pohodlí <strong>vlastní školy?</strong> Vyberte si z naší široké nabídky kurzů.
            Kurzy programování a přidejte se k nám! Naše kurzy jsou určeny pro studenty, kteří chtějí rozvíjet logické myšlení a
            zároveň naučit základy programování.
          </p>
          <a href="#/courses">
            <strong>Aktuální nabídka kurzů</strong>
          </a>
        </div>
      </div>
        <div className="infoBoxContainer">
          <div className="infoBox">
            <MdLocationOn className="icon" />
            <h3>Města</h3>
            <p>Jsme v 5 městech v České republice</p>
          </div>
          <div className="infoBox">
            <GiSchoolBag className="icon" />
            <h3>Školy</h3>
            <p>Naše kurzy programování probíhají na více než 25 školách</p>
          </div>
          <div className="infoBox">
            <FaBook className="icon" />
            <h3>Kurzy</h3>
            <p>Široká nabídka kurzů programování</p>
          </div>
          <div className="infoBox">
            <FaUserTie className="icon" />
            <h3>Lektoři</h3>
            <p>Zkušení a obětaví lektoři</p>
          </div>
          <div className="infoBox">
            <FaClipboardCheck className="icon" />
            <h3>Uskutečněné kurzy</h3>
            <p>Více než 50 dokončených kurzů </p>
          </div>
          <div className="infoBox">
            <FaGraduationCap className="icon" />
            <h3>Absolventi</h3>
            <p>Více než 400 úspěšných absolventů</p>
          </div>
        </div>
        </>
      ) : (
        <>
         <div className="container22">
        <div className="content22">
          <h1>Programování kroužků přímo na základních školách</h1>
          <p>
          Chcete se naučit programovat z pohodlí <strong>vlastní školy?</strong> Vyberte si z naší široké nabídky kurzů.
            Kurzy programování a přidejte se k nám! Naše kurzy jsou určeny pro studenty, kteří chtějí rozvíjet logické myšlení a
            zároveň naučit základy programování.
          </p>
          <a href="#/courses">
            <strong>Aktuální nabídka kurzů</strong>
          </a>
        </div>
      </div>
        <div className="infoBoxContainer2">
          <div className="infoBox2">
            <MdLocationOn className="icon" />
            <h3>Města</h3>
            <p>Jsme v 5 městech v České republiceu</p>
          </div>
          <div className="infoBox2">
            <GiSchoolBag className="icon" />
            <h3>Školy</h3>
            <p>Naše kurzy programování probíhají na více než 25 školách</p>
          </div>
          <div className="infoBox2">
            <FaBook className="icon" />
            <h3>Kurzy</h3>
            <p>Široká nabídka kurzů programování</p>
          </div>
          <div className="infoBox2">
            <FaUserTie className="icon" />
            <h3>Lektoři</h3>
            <p>Zkušení a obětaví lektoři</p>
          </div>
          <div className="infoBox2">
            <FaClipboardCheck className="icon" />
            <h3>Uskutečněné kurzy</h3>
            <p>Více než 50 dokončených kurzů </p>
          </div>
          <div className="infoBox2">
            <FaGraduationCap className="icon" />
            <h3>Absolventi</h3>
            <p>Více než 400 úspěšných absolventů</p>
          </div>
        </div>
        </>
      )}
    </>
  );
};


export default Main2;
