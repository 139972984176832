import React, { useState, useEffect } from "react";
import { Card, Typography, Stepper, Step, StepLabel, Box } from "@mui/material";

const RegistrationCircle = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const steps = [
    {
      title: "Prihlásenie",
      description:
        "Zaregistrujte sa alebo prihláste do svojho existujúceho účtu. Je dôležité, aby ste mali vytvorený účet, cez ktorý budete môcť sledovať svoj pokrok a informácie týkajúce sa kurzu.",
    },
    {
      title: "Osobné údaje",
      description:
        "Poskytnite svoje osobné údaje pre overenie identity. Tieto údaje nám pomôžu lepšie prispôsobiť kurz vašim potrebám a zaistiť, aby všetky informácie boli správne.",
    },
    {
      title: "Detaily o detiach",
      description:
        "Vyplňte podrobné informácie o vašich deťoch, ak sa prihlasujú do kurzu. Chceme sa uistiť, že kurz je vhodný pre vek a záujmy vášho dieťaťa.",
    },
    {
      title: "Mesto & Škola",
      description:
        "Určte svoje mesto a vyberte si školu, ktorú preferujete. Máme rôzne centrá v rôznych mestách, takže je dôležité vedieť, kde by ste chceli navštevovať hodiny.",
    },
    {
      title: "Programovací jazyk",
      description:
        "Vyberte si programovací jazyk, ktorý chcete študovať. Ponúkame rôzne jazyky od základných po pokročilé, takže si môžete vybrať ten, ktorý vás najviac zaujíma.",
    },
    {
      title: "Výber kurzu",
      description:
        "Vyberte si kurz, ktorý najlepšie vyhovuje vašim potrebám. Ponúkame širokú škálu kurzov pre rôzne vekové kategórie a úrovne znalostí.",
    },
    {
      title: "VOP Dokumenty",
      description:
        "Preskúmajte a podpíšte VOP dokumenty. Je veľmi dôležité dôkladne si prečítať všetky podmienky a pravidlá, aby ste vedeli, čo môžete očakávať od nášho kurzu.",
    },
    {
      title: "Platba",
      description:
        "Uskutočnite platbu, aby ste potvrdili svoju registráciu. Po obdržaní platby vás začleníme do vybranej triedy a oznámime vám všetky ďalšie detaily.",
    },
  ];

  const firstHalfSteps = steps.slice(0, Math.ceil(steps.length / 2));
  const secondHalfSteps = steps.slice(firstHalfSteps.length);

  return (
    <Card
      sx={{
        maxWidth: "800px",
        margin: "20px auto",
        padding: "20px",
        boxShadow: "0 0 15px rgba(0,0,0,0.2)",
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ textAlign: "center", marginBottom: "30px" }}>
        Postup registrácie na kurz programovania
      </Typography>
      {isMobile ? (
        <>
          <Stepper activeStep={-1} alternativeLabel sx={{ marginBottom: "10px" }}>
            {firstHalfSteps.map((step) => (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Stepper activeStep={-1} alternativeLabel sx={{ marginBottom: "10px" }}>
            {secondHalfSteps.map((step, index) => (
              <Step key={step.title}>
                <StepLabel icon={firstHalfSteps.length + index + 1}>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      ) : (
        <Stepper activeStep={-1} alternativeLabel sx={{ marginBottom: "10px" }}>
          {steps.map((step) => (
            <Step key={step.title}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      <Box mt={3}>
        {steps.map((step, index) => (
          <Box mt={2} key={step.title}>
            <Typography variant="h6">
              {index + 1}. {step.title}
            </Typography>
            <Typography variant="body1">{step.description}</Typography>
          </Box>
        ))}
      </Box>
    </Card>
  );
};

export default RegistrationCircle;
