import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import "./LectorAttendanceHistoryDetails.css";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface Child {
  _id: string;
  name: string;
  className: string;
}

interface Attendance {
  note: string | null;
  children: any;
  attended: Array<string>;
  absent: Array<string>;
  apologies: Array<string>;
  _id: string;
  courseId: string;
  childrenId: string;
  status: string; // "attended", "missed", "ill"
  held: boolean;
  className: string;
}

interface LectorAttendanceHistoryDetailsProps {
  userId: string;
}

const LectorAttendanceHistoryDetails = ({ userId }: LectorAttendanceHistoryDetailsProps) => {
  const { id } = useParams<{ id: string }>();
  const [attendanceData, setAttendanceData] = useState<Attendance | null>(null);
  const [childrens, setChildrens] = useState<Child[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [editedStatuses, setEditedStatuses] = useState<{ [key: string]: string }>({});
  const [editedHeldStatus, setEditedHeldStatus] = useState<boolean | null>(null);
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    // Fetch attendance details by attendId
    const fetchAttendanceDetails = async () => {
      if (!id) return;
      try {
        const response = await fetch(url + `attend/course/details/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setAttendanceData(data.attendancesWithChildren);
      } catch (error) {
        console.error("Error fetching attendance details:", error);
      }
    };

    fetchAttendanceDetails();
  }, [id, token, url, userId]);

  useEffect(() => {
    // Bind children names to childrenIds from attended, absent, and apologies arrays
    if (attendanceData) {
      const childrenIds = [...attendanceData.attended, ...attendanceData.absent, ...attendanceData.apologies];

      const filteredChildren = attendanceData.children.filter((child: Child) => childrenIds.includes(child._id));

      setChildrens(filteredChildren);

      // Initialize the editedStatuses object with the current status for each child
      const initialStatuses: { [key: string]: string } = {};
      filteredChildren.forEach((child: { _id: string }) => {
        const isAttended = attendanceData.attended.includes(child._id);
        const isAbsent = attendanceData.absent.includes(child._id);
        const isApology = attendanceData.apologies.includes(child._id);

        initialStatuses[child._id] = isAttended ? "attended" : isAbsent ? "missed" : isApology ? "ill" : "";
      });

      setEditedStatuses(initialStatuses);
    }
  }, [attendanceData]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSaveAttendance = async () => {
    try {
      if (!id) return;

      // Implement the PUT fetch to update the attendance status for each child
      const updatedAttendanceData = {
        ...attendanceData,
        attended: [] as string[],
        absent: [] as string[],
        apologies: [] as string[],
        held: editedHeldStatus === null ? attendanceData?.held : editedHeldStatus, // Use edited value if present
      };

      Object.entries(editedStatuses).forEach(([childId, status]) => {
        if (status === "attended") updatedAttendanceData.attended.push(childId);
        else if (status === "missed") updatedAttendanceData.absent.push(childId);
        else if (status === "ill") updatedAttendanceData.apologies.push(childId);
      });

      const response = await fetch(url + `attend/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
        body: JSON.stringify(updatedAttendanceData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      await response.json();
      setEditMode(false);
    } catch (error) {
      console.error("Error updating attendance:", error);
    }
  };

  const handleChangeStatus = (childId: string, status: string) => {
    setEditedStatuses((prevStatuses) => ({
      ...prevStatuses,
      [childId]: status,
    }));
  };

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="attendance-history-details-container">
      <div className="top-section">
        <p style={{ textAlign: "center" }}>
          Údaje o dochádzke krúžku z týždňa:
          {attendanceData && <span style={{ fontWeight: "bold" }}>{attendanceData.note} </span>}
        </p>
        <Link to="/lector/attendance-history" className="back-link">
          Späť na históriu dochádzky
        </Link>
      </div>

      <div style={{ overflowX: "auto", padding: "0 15px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Meno žiaka</TableCell>
              <TableCell>Trieda žiaka</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Konanie krúžku</TableCell>
              {editMode ? (
                <>
                  <TableCell>Upraviť status</TableCell>
                  <TableCell>Upraviť konanie</TableCell>
                  <TableCell>Uložiť</TableCell>
                </>
              ) : (
                <TableCell>Upraviť</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {childrens.map((child) => {
              const { _id, name, className } = child;
              const isAttended = editedStatuses[_id] === "attended";
              const isMissed = editedStatuses[_id] === "missed";
              const isIll = editedStatuses[_id] === "ill";

              return (
                <TableRow key={_id}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{className}</TableCell>
                  <TableCell>{isAttended ? "Attended" : isMissed ? "Missed" : isIll ? "Apologied" : "-"}</TableCell>
                  <TableCell>{attendanceData?.held ? "Yes" : "No"}</TableCell>
                  {editMode ? (
                    <>
                      <TableCell>
                        <select
                          value={editedStatuses[_id]}
                          onChange={(e) => handleChangeStatus(_id, e.target.value)}
                          style={{ padding: "5px", width: "100%" }}
                        >
                          <option value="">Select Status</option>
                          <option value="attended">Attended</option>
                          <option value="missed">Missed</option>
                          <option value="ill">Apologies</option>
                        </select>
                      </TableCell>
                      <TableCell>
                        <select
                          value={
                            editedHeldStatus === null
                              ? attendanceData?.held
                                ? "true"
                                : "false"
                              : editedHeldStatus
                              ? "true"
                              : "false"
                          }
                          onChange={(e) => setEditedHeldStatus(e.target.value === "true")}
                          style={{ padding: "5px", width: "100%" }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </TableCell>
                      <TableCell>
                        <button
                          className="save-button"
                          style={{ padding: "10px 15px", width: "100%" }}
                          onClick={handleSaveAttendance}
                        >
                          Uložiť
                        </button>
                      </TableCell>
                    </>
                  ) : (
                    <TableCell>
                      <button
                        className="edit-button"
                        style={{ padding: "10px 15px", width: "100%" }}
                        onClick={handleEdit}
                      >
                        Upraviť
                      </button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default LectorAttendanceHistoryDetails;
