interface School {
  _id: string; // Use _id as the unique identifier
  name: string;
  location: string;
  town: string;
  address: string; // Add address property
  imgURL: string; // Add imgURL property
  //
}
  
export const fetchSchools = async (selectedTown: string | null, isPublic: boolean): Promise<School[]> => {
    const url =
      process.env.REACT_APP_ENVIRONMENT === "prod"
        ? process.env.REACT_APP_PROD_URL
        : process.env.REACT_APP_DEV_URL;

    if(isPublic) {
      try {
        let finalUrl = url + "school/allSchools/public";
  
        if (selectedTown)  {
          finalUrl = url + "school/town/" + selectedTown + "/public";
        }
        const response = await fetch(finalUrl,   {
          headers: {
            "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
          },
        }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        return data.schools;
      } catch (error) {
        console.error("Error fetching schools:", error);
        return [];
      }
    }
    else {
    try {
      let finalUrl = url + "school/allSchools";

      if (selectedTown)  {
        finalUrl = url + "school/town/" + selectedTown;
      }
      const response = await fetch(finalUrl,
        {
          headers: {
            "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      return data.schools;
    } catch (error) {
      console.error("Error fetching schools:", error);
      return [];
    }
  }
  };  