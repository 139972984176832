import { useState, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useAuth } from '../Auth/AuthContext';
import "./Login.css";

interface LoginProps {
  onLogin: () => void;
  setUserId: (userId: string) => void;
}

function Login({ onLogin, setUserId }: LoginProps): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [moveToHome, setMoveToHome] = useState(false);
  const { setToken } = useAuth();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    let url = process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
    try {
      const response = await fetch(url + "auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      
      if (response.ok) {
        // Login successful
        const data = await response.json();
        const { token } = data;
        setToken(token);
        Cookies.set("token", token);
        // Reset the form
        setEmail("");
        setPassword("");

        setMessage("");

        // Show success popup
        setIsSuccess(true);
        setShowPopup(true);

        setMoveToHome(true);

        // Call the onLogin callback
        onLogin();
        try {
          // Make a GET request to the protected endpoint with the token in the header
          const protectedResponse = await fetch(url + "user/protected", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (protectedResponse.ok) {
            // The protected resource was accessed successfully
            const protectedData = await protectedResponse.json();
            setUserId(protectedData.userId);
            // Cookies.set("token", token);
            Cookies.set("userId", protectedData.userId);
          } else {
            // Unable to access the protected resource
            console.error("Failed to access protected resource");
          }
        } catch (error) {
          console.error("An error occurred while accessing protected resource:", error);
        }
      } else {
        // Login failed
        setShowPopup(true);
        setIsSuccess(false);
        setMessage("Invalid email or password");
      }
    } catch (error) {
      console.error("An error occurred while logging in:", error);
      setShowPopup(true);
      setIsSuccess(false);
    }
  };
  

  const handleRegister = async (e: FormEvent) => {
    e.preventDefault();

    // Navigate to the Register component
    navigate("/register");
  };

  const handleForgotPassword = async (e: FormEvent) => {
    e.preventDefault();

    navigate("/forgot-password");

    // Perform forgot password logic here
  };

  useEffect(() => {
    const moveHome = () => {
      if (moveToHome) {
        navigate("/");
      }
    };

    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
        setIsSuccess(false);
        moveHome();
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showPopup, navigate, moveToHome]);

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>Prihlásenie</h2>
        <input
          className="login-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Mail"
          type="text"
          autoComplete="email"
        />
        <input
          className="login-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Heslo"
          type="password"
          autoComplete="current-password"
        />
        <button className="login-button" onClick={handleLogin} type="submit">
          Prihlásiť sa
        </button>
      </form>
      {message && <p className="login-error">{message}</p>}
      <div className="login-options">
        <p>
          Ešte nemáte účet?{" "}
          <button className="login-link" onClick={handleRegister}>
            Registrovať sa
          </button>
        </p>
        <p>
          Zabudnuté heslo?{" "}
          <button className="login-link" onClick={handleForgotPassword}>
            Resetovať heslo
          </button>
        </p>
      </div>
      {showPopup && (
        <div className={`login-popup ${isSuccess ? "success" : "error"}`}>
          <p>{isSuccess ? "Prihlasovanie úspešné!" : "Prihlasovanie neúspešné. Skúste znova."}</p>
        </div>
      )}
    </div>
  );
}

export default Login;
