import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import "./Profile.css";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import PersonalDataAgreement from "../PersonalTerms/PersonalDataAgreement";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";

interface Child {
  name: string;
  class: string;
}

interface ProfilProps {
  userId: string;
  userData:
    | {
        name: string;
        surname: string;
        variableSymbol: string;
        address: string;
        city: string;
        postCode: string;
        phoneNumber: string;
        email: string;
        children: Child[];
        signedPersonalData: boolean;
      }
    | any;
  updateUserData: () => void;
}

interface UserBody {
  name: string;
  surname: string;
  address: string;
  city: string;
  postCode: string;
  phoneNumber: string;
  email: string;
  signedPersonalData?: boolean; // This property is optional
}

const Profile: React.FC<ProfilProps> = ({ userId, userData, updateUserData }: ProfilProps) => {
  const [editMode, setEditMode] = useState(false);
  const [updatedUser, setUpdatedUser] = useState(userData);
  const [lectoriId, setLectoriId] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [isChecked, setIsChecked] = useState(false); // Personal terms agreement
  const [userSignedPersonalData, setUserSignedPersonalData] = useState(Boolean); // Personal terms agreement
  const { token } = useAuth();
  const navigate = useNavigate();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  // if userData is empty, return NotLoggedIn component
  useEffect(() => {
    if (userData?.role === "lector") {
      navigate("/lector-profile");
    }
  }, [userData, navigate]);

  // useeffect setUserSignedPersonalData(userData.signedPersonalData);
  useEffect(() => {
    setUserSignedPersonalData(userData?.signedPersonalData);
  }, [userData?.signedPersonalData, updateUserData]);

  // fetch Leckot a by user id and setUdatedUser
  useEffect(() => {
    if (!userData) {
      return;
    }
    if (userData?.role !== "lector") {
      return;
    }
    // fetchLektor
    if (!userId) {
      return;
    }

    const fetchLektor = async () => {
      try {
        const response = await fetch(url + `lector/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setUpdatedUser(data);
        setLectoriId(data._id);
      } catch (error) {
        console.error("Error fetching lektor:", error);
      }
    };
    fetchLektor();
  }, [userId, url, userData?.role, token, updateUserData, userData?.signedPersonalData, userData]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedUser((prevUser: any) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleChildInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedUser((prevUser: { children: any[] }) => {
      const updatedChildren = prevUser.children.map((child: any, childIndex: number) => {
        if (childIndex === index) {
          return {
            ...child,
            [name.split(".")[1]]: value,
          };
        }
        return child;
      });
      return {
        ...prevUser,
        children: updatedChildren,
      };
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const updateUser = async () => {
      if (!userId) {
        return;
      }
      console.log(isChecked + "isChecked");
      try {
        // from updatedUser get only name, surname, address, city, postCode, phoneNumber, email
        let newBody: UserBody = {
          name: updatedUser?.name,
          surname: updatedUser?.surname,
          address: updatedUser?.address,
          city: updatedUser?.city,
          postCode: updatedUser?.postCode,
          phoneNumber: updatedUser?.phoneNumber,
          email: updatedUser?.email,
        };

        if (isChecked) {
          newBody.signedPersonalData = true;
        }

        const response = await fetch(url + `user/${userId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
          body: JSON.stringify(newBody),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const updatedUserData = await response.json();
        setUpdatedUser(updatedUserData.user);
        updateUserData();
        setShowSuccess(true);
        // reload page TODO fix
      } catch (error) {
        console.error("Error updating user:", error);
      }
    };

    // fetch update user put
    const updateUserLector = async () => {
      if (!lectoriId) {
        return;
      }

      try {
        const response = await fetch(url + `lector/${lectoriId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
          body: JSON.stringify(updatedUser),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        await response.json();
        updateUserData();
        setShowSuccess(true);
        // update userData
      } catch (error) {
        console.error("Error updating user:", error);
      }
    };
    if (userData?.role === "lector") {
      updateUserLector();
    }

    if (userData?.role === "user" || userData?.role === "admin") {
      updateUser();
    }

    setEditMode(false);
  };

  useEffect(() => {
    setUpdatedUser(userData);
  }, [userData, updateUserData]);

  // if token is null try token from cookies
  if (!token || userData === null || !userData) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <>
      <div className="profile-container">
        {userData?.role === "user" && <p className="invoice-details">Fakturačné údaje</p>}
        {!userData?.city || !userData?.phoneNumber || !userData?.postCode || !userData?.name || !userData?.surname ? (
          <p className="note">Prosím, vyplňte fakturačné údaje pre potreby generovania faktúry za platby.</p>
        ) : null}

        {editMode ? (
          <form className="profile-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <label className="input-label">
                Meno:
                <input
                  type="text"
                  name="name"
                  value={updatedUser.name ? updatedUser.name : ""}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </label>
              <label className="input-label">
                Priezvisko:
                <input
                  type="text"
                  name="surname"
                  value={updatedUser.surname ? updatedUser.surname : ""}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </label>
              <label className="input-label">
                Adresa:
                <input
                  type="text"
                  name="address"
                  value={updatedUser.address ? updatedUser.address : ""}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </label>
              <label className="input-label">
                Mesto:
                <input
                  type="text"
                  name="city"
                  value={updatedUser.city ? updatedUser.city : ""}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </label>
              <label className="input-label">
                PSČ:
                <input
                  type="text"
                  name="postCode"
                  value={updatedUser.postCode ? updatedUser.postCode : ""}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </label>
              <label className="input-label">
                Tel. číslo:
                <input
                  type="text"
                  name="phoneNumber"
                  value={updatedUser.phoneNumber ? updatedUser.phoneNumber : ""}
                  onChange={handleInputChange}
                  className="input-field"
                />
              </label>
            </div>
            {updatedUser.children &&
              updatedUser.children.map((child: Child, index: number) => (
                <div key={index} className="child-input">
                  <label className="input-label">
                    Meno dieťaťa:
                    <input
                      type="text"
                      name={`children[${index}].name`}
                      value={child.name}
                      onChange={(e) => handleChildInputChange(index, e)}
                      className="input-field"
                    />
                  </label>
                  <label className="input-label">
                    Trieda dieťaťa:
                    <input
                      type="text"
                      name={`children[${index}].class`}
                      value={child.class}
                      onChange={(e) => handleChildInputChange(index, e)}
                      className="input-field"
                    />
                  </label>
                </div>
              ))}
            <button type="submit" className="save-button">
              Uložiť
            </button>
          </form>
        ) : (
          <div className="profile-info">
            <p className="info-label">
              Meno: <span>{updatedUser.name}</span>
            </p>
            <p className="info-label">
              Priezvisko: <span>{updatedUser.surname}</span>
            </p>
            <p className="info-label">
              Adresa: <span>{updatedUser.address}</span>
            </p>
            <p className="info-label">
              Mesto: <span>{updatedUser.city}</span>
            </p>
            <p className="info-label">
              PSČ: <span>{updatedUser.postCode}</span>
            </p>
            <p className="info-label">
              Tel. číslo: <span>{updatedUser.phoneNumber}</span>
            </p>
            <ul>
              {updatedUser.children &&
                updatedUser.children.map((child: Child, index: number) => (
                  <li key={index}>
                    <p className="info-label">
                      Meno dieťaťa: <span>{child.name}</span>
                    </p>
                    <p className="info-label">
                      Trieda dieťaťa: <span>{child.class}</span>
                    </p>
                  </li>
                ))}
            </ul>
            <button onClick={() => setEditMode(true)} className="edit-button">
              Upraviť
            </button>
          </div>
        )}
        {showSuccess && <p className="success-message">Údaje používateľa boli úspešne aktualizované!</p>}
      </div>
      <div className="private-policy-container">
        <PersonalDataAgreement
          isChecked={isChecked}
          onCheckboxChange={setIsChecked}
          userSignedPersonalData={userSignedPersonalData}
          updateUserData={updateUserData}
          userId={userId}
          token={token ? token : ""}
          userData={userData}
        />{" "}
      </div>
    </>
  );
};

export default Profile;
