import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "./ChildrenDetails.css"; // Import the CSS file for styling
import { useAuth } from "../Auth/AuthContext";
import NotLoggedIn from "../Login/NotLoggedIn";
import Cookie from "js-cookie";

interface Child {
  _id: string;
  name: string;
  schoolId: string;
  courseId: string;
  userId: string;
  createdAt: Date;
  className: string;
  isAttending: boolean;
  firstPayment: boolean;
  nextPayment: number;
}

interface ChildrenDetailsProps {
  userId: string;
}

const ChildrenDetails = ({userId} : ChildrenDetailsProps) => {
  const { id } = useParams<{ id: string }>(); // Get the lector ID from the URL
  const [child, setChild] = useState<Child | null>(null);
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchChildDetails = async () => {
      try {
        const response = await fetch(url + `children/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setChild(data.child);
      } catch (error) {
        console.error("Error fetching child details:", error);
      }
    };

    fetchChildDetails();
  }, [id, token, url, userId]);

  if (!child) {
    return <div>Loading...</div>;
  }

    // if token is null try token from cookies
    if (!token) {
      const tokenFromCookie = Cookie.get("token");
      if (!tokenFromCookie) {
        return <NotLoggedIn />;
      }
    }

  return (
    <div className="children-details-container">
      <h3>Child Details</h3>
      <div className="child-info">
        <span>ID: {child._id}</span>
        <span>Name: {child.name}</span>
        <span>Class Name: {child.className}</span>
        <span>Is Attending: {child.isAttending ? "Yes" : "No"}</span>
        <span>First Payment: {child.firstPayment ? "Yes" : "No"}</span>
        <span>Next Payment: {child.nextPayment}</span>
        <span>Created At: {child.createdAt}</span>
        <span>School ID: {child.schoolId}</span>
        <span>Course ID: {child.courseId}
            <Link to={`/course/details/${child.courseId}`}>
            <button className="details-button">Course Details</button>
            </Link>
        </span>
        <span>
          User ID: {child.userId}
          <Link to={`/user/${child.userId}/details`}>
            <button className="details-button">Parent Details</button>
          </Link>
        </span>
        {/* Add other child details as needed */}
      </div>
    </div>
  );
};

export default ChildrenDetails;
