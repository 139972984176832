import { useEffect } from "react";
import "./CookiePolicy.css";

const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); // The empty array ensures this effect runs only once, similar to componentDidMount

  return (
    <div className="cookie-policy">
      <h1>Zásady používania Cookies</h1>
      <p>
        <strong>Dátum aktualizácie:</strong> 11.09.2023
      </p>

      <h2>Čl. I</h2>
      <h2>INFORMAČNÁ POVINNOSŤ PRI POSKYNUTÍ OSOBNÝCH ÚDAJOV A SPRACÚVANIE COOKIES</h2>
      <p>
        1) Dodávateľ v zmysle plnenia svojich informačných povinností v zmysle príslušných ustanovení zákona č. 18/2018
        Z. z. o ochrane osobných údajov poskytuje objednávateľovi nasledujúce informácie. Objednávateľ ako dotknutá
        osoba podľa zákona o ochrane osobných údajov je povinný poskytovať osobné údaje na účel uvedený v nasledujúcich
        ustanoveniach. V prípade neposkytnutia informácií, zmluvu medzi dodávateľom a objednávateľom o ktorú prejavil
        záujem, nie je možné uzavrieť, keďže tieto údaje sú nevyhnutné na plnenie predmetného záväzku.
      </p>
      <p>2) Dodávateľ:</p>
      <p>Obchodné meno: Mladí programátori s. r. o.</p>
      <p>Sídlo: Trieda Andreja Hlinku 606/39</p>
      <p>Nitra 949 01</p>
      <p>IČO: 55 655 203</p>
      <p>Zápis: v Obchodnom registri Okresného súdu Nitra, oddiel: Sro, vložka č. 61121/N</p>
      <p>Tel. č.: +421 940 014 553</p>
      <p>e-mail: mladiprogramatori.info@cz.com</p>
      <p>(ďalej ako „dodávateľ“)</p>
      <p>Zodpovedná osoba:</p>
      <p>Mladí programátori s. r. o., Bc. Daniel Cok, konateľ</p>
      <p>Tel. č.: +421 940 014 553</p>
      <p>e-mail: mladiprogramatori.info@cz.com</p>
      <p></p>
      <h2>Čl. II</h2>
      <h2>ÚČEL SPRACÚVANIA OSOBNÝCH ÚDAJOV:</h2>
      <p>
        1) Účelom a právnym základom spracúvania osobných údajov sú predzmluvné vzťahy a uzatvorenie zmluvy medzi
        objednávateľom a dodávateľom. V zmysle príslušných ustanovení, najmä § 13 ods. 1 písm. b) zákona o ochrane
        osobných údajov je právny základ spracúvania týchto údajov: spracúvanie osobných údajov je nevyhnutné na plnenie
        zmluvy, ktorej zmluvnou stranou je dotknutá osoba, alebo na vykonanie opatrenia pred uzatvorením zmluvy na
        základe žiadosti dotknutej osoby a v zmysle. § 13 ods. 1 písm. c) zákona o ochrane osobných údajov: spracúvanie
        osobných údajov je nevyhnutné podľa osobitného predpisu dodávateľ je v zmysle osobitných predpisov povinný
        poskytnúť osobné údaje dotknutej osoby štátnym inštitúciám.
      </p>
      <p>
        2) Príjemcovia, resp. kategórie príjemcov, ktorí spracúvajú osobné údaje, sú účtovník?, banka?, Google? a štátne
        inštitúcie za účelom plnenia povinností zamestnávateľa: Sociálna poisťovňa, zdravotná poisťovňa, daňový úrad,
        doplnková, orgán štátnej správy a verejnej moci na výkon kontroly a dozoru, úradu práce, sociálnych vecí a
        rodiny, exekútor, súd, orgán činný v trestnom konaní.
      </p>
      <p>
        3) Dodávateľ neuskutočňuje prenos osobných údajov do tretích krajín. K takémuto prenosu môže dôjsť iba vtedy, ak
        objednávateľ udelí súhlas s ukladaním súborov cookies.
      </p>
      <p>
        4) Osobné údaje poskytnuté objednávateľom dodávateľ spracúva a uchováva na zakonne určenú dobu na uplatňovanie
        práv a povinností vyplývajúcich z uzavretej zmluvy.
      </p>
      <p>
        5) Dodávateľ môže dlhšie spracúvať osobné údaje výlučne na účel archivácie, na vedecký účel, na účel
        historického výskumu alebo na štatistický účel na základe osobitného predpisu.
      </p>
      <p>
        6) Dodávateľ nevykonáva spracúvanie osobných údajov založené na automatizovanom individuálnom rozhodovaní ani
        nevykonáva profilovanie.
      </p>
      <p>7) Objednávateľ ako dotknutá osoba má nasledujúci súbor práv v súvislosti so spracúvaním osobných údajov:</p>
      <p>
        a) Požadovať prístup k osobným údajom. Dotknutá osoba má právo na poskytnutie zoznamu osobných údajov a
        informácie ako sú spracúvané jej osobné údaje.
      </p>
      <p>
        b) Dodávateľ uchováva úplné a aktuálne osobné údaje a preto v prípade, ak dotknutá osoba má za to, že spracúvané
        údaje nie sú presné, má právo dodávateľa kontaktovať za účelom nápravy prostredníctvom kontaktných údajov
        uvedených v čl I ods. 2) .
      </p>
      <p>
        c) Dotknutá osoba má právo aby jej osobné údaje, ktoré poskytla dodávateľovi boli vymazané. Toto právo môže
        uplatniť len v rozsahu zákonom uvedených dôvodov. Jedným z takýchto dôvodov môže byť pominutie účelu spracúvania
        osobných údajov.
      </p>
      <p>
        d) Dotknutá osoba môže za splnenia zákonných podmienok požadovať, aby dodávateľ nespracúval a prestal používať
        jej osobné údaje. Jedným z takýchto dôvodov môže byť, že tieto údaje, ktoré dodávateľ uchováva, nie sú presné.
      </p>
      <p>
        e) Dotknutá osoba má právo namietať voči spracúvaniu jej osobných údajov, ktoré je založené na legitímnych
        oprávnených záujmoch prevádzkovateľa. V prípade, pokiaľ dodávateľ spracúva tieto osobné údaje bez legitímneho
        dôvodu a dotknutá osoba toto namietne, dodávateľ ďalej osobné údaje v rozsahu dôvodnej námietky dotknutej osoby
        spracúvať nebude.
      </p>
      <p>
        f) Dotknutá osoba svoj súhlas so spracovaním osobných údajov môže odvolať. Súhlas dotknutej osoby sa v
        podmienkach dodávateľa získava len vtedy, ak sa neuplatňuje iný právny dôvod na spracúvanie osobných údajov bez
        súhlasu. Poskytnutie osobných údajov a udelenie súhlasu je v takomto prípade dobrovoľné. Ak sa osobné údaje
        spracúvajú na základe súhlasu, dotknutá osoba má právo kedykoľvek svoj súhlas so spracúvaním osobných údajov
        odvolať. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov, založeného na súhlase udelenom
        pred jeho odvolaním.
      </p>
      <p>
        g) Dotknutá osoba môže mať právo žiadať o prenos poskytnutých osobných údajov. Toto právo sa vzťahuje len na tie
        osobné údaje, poskytnuté na základe súhlasu resp. na základe zmluvy, v ktorej je dotknutá osoba zmluvnou
        stranou.
      </p>
      <p>
        h) Dotknutá osoba môže iniciovať konanie podaním návrhu na začatie konania alebo sťažnosťou na dozorný orgán.
        Práva dotknutej osoby možno uplatniť písomným doručením žiadosti osobne, poštou, alebo na adresu sídla
        dodávateľa, prostredníctvom e-mailovej adresy, telefonicky, prípadne osobne. S dotknutou osobou spíše záznam o
        výkone práv dotknutej osoby. Uvedené kontaktné a ďalšie údaje sú uvedené v čl. I ods. 2)
      </p>
      <p>
        8) Ak vzniknú oprávnené pochybnosti o totožnosti dotknutej osoby, ktorá si uplatnila svoje práva, dodávateľ je
        oprávnený vykonať overenie jej totožnosti, napríklad vyžiadaním si dodatočných informácií, jej predvolaním, ak
        je to účelné a overením dokladu jej totožnosti alebo iným vhodným spôsobom.
      </p>
      <p>
        9) Dotknutá osoba má tiež právo podať návrh, resp. sťažnosť na Úrad na ochranu osobných údajov Slovenskej
        republiky, https://dataprotection.gov.sk, Hraničná 12, 820 07 Bratislava 27; tel. číslo: +421 /2/ 3231 3214;
        e-mail: statny.dozor@pdp.gov.sk.
      </p>
      <p></p>
      <h2>Čl. III</h2>
      <h2>SPRACÚVANIE COOKIES</h2>
      <p>
        1) Za účelom plnohodnotného fungovania webovej stránky dodávateľa, dochádza k použitiu nevyhnutńych súborov
        cookies (essential cookies) umožňujúce realizovať základnú funkcionalitu tejto webovej stránky. Ide o malé
        textové či iné súbory, prípadne iné technológie s podobným účelom.
      </p>
      <p>
        2) Súbory cookies pri návšteve webovej stránky dodávateľa sú odosielané dodávateľovi, čím je umožnená
        identifikácia a ďalšie informácie o zariadeniach, prostredníctvom ktorých dochádza k pripájaniu na webovú
        stránku dodávateľa. Účelom týchto súborov je rýchlejšie, efektívnejšie, personifikovanejšie a bezpečnejšie
        používanie webovej stránky dodávateľa.
      </p>
      <p>
        3) Tieto súbory cookies možno zakázať zmenou nastavení internetového prehliadača, ktorým je prehliadaná webová
        stránka dodávateľa. Takéto zmeny však môžu vplývať na funkcionalitu webovej stránky dodávateľa.
      </p>
      <p></p>
      <h2>Čl. IV</h2>
      <h2>DRUHY SÚBOROV POUŽÍVANÝCH COOKIES</h2>
      <p>
        1) Súbory cookies možno deliť na nevyhnutné cookies a dobrovoľné cookies. Na účely činnosti dodávateľa sú
        používané nasledovné druhy súborov cookies:
      </p>
      <p>
        a) Nevyhnutné alebo aj essential cookies sa používajú za účelom funkcionality webovej stránky dodávateľa napr.
        použitie nejakej funkcie stránky (prihlasovanie, vypĺňanie formulárov, nastavenie predvolieb a pod.). Na
        použitie týchto súborov sa nevyžaduje súhlas dotknutej osoby. V prípade blokovania určitých súborov cookies
        dotknutou osobou prostredníctvom nastavenia používaného internetového prehliadača nemusia niektoré funkcie web
        stránky dodávateľa fungovať.
      </p>
      <p>
        b) Štatistické cookies, ktorých účelom je analýza návštevnosti a spôsobu použitia webovej stránky dodávateľa
        užívateľmi.
      </p>
      <p>
        c) Reklamné cookies slúžia na vytváranie profilu o záujmoch dotknutej osoby ako aj na zobrazenie zodpovedajúcej
        reklamy. Tieto súbory zaznamenávajú návštevnosť na stránke, použité odkazy, na čo slúžia identifikátory
        jedinečné pre prehliadač a zariadenie. Účelom je zobrazovanie takého obsahu, ktorý najvhodnejšie zodpovedá
        záujmom dotknutých osôb a to aj prostredníctvom zobrazených reklám či reklám, ktoré dodávateľ umiestňuje na iné
        webové stránky.
      </p>
      <p>
        d) Cookies sociálnych sietí sa nastavujú v súlade s pravidlami daných sociálnych sietí. Na webovej stránke
        dodávateľa môžu byť umiestnené odkazy na sociálne siete za účelom umožnenia interakcie aj prostredníctvom týchto
        sietí, napríklad možnosť jednoduchého a rýchleho zdieľania obsahu webovej stránky dodávateľa. Tieto cookies
        umožňujú sledovať prehliadač aj na iných webových stránkach a vytvárať profil o záujmoch dotknutej osoby. Podľa
        toho je zobrazovaný cielený obsah na týchto sieťach prípadne iných webových stránkach. V okamihu kliknutia na
        daný odkaz, zahája stránky pripojenie k danej sociálnej sieti. V prípade, ak je dotknutá osoba už prihlásená na
        stránkach danej sociálnej siete, dochádza k prenosu informácií o návšteve webovej stránky dodávateľa. Dodávateľ
        nedisponuje žiadnym vplyvom na rozsah a účel spracúvania týchto dát zo strany prevádzkovateľov tretích strán. Za
        tieto cookies sú plne zodpovedné tieto tretie strany, a preto v prípade záujmu o bližšie informácie o cookies
        týchto tretích strán je potrebné si naštudovanie ich zásad súkromia alebo pravidiel používania služieb.
      </p>
      <p>
        2) Prehľad používaných cookies možno nájsť v rámci „cookie lišty“, ktorá sa zobrazuje ihneď pri návšteve webovej
        stránky dodávateľa. Dotknutej osobe sa tak zobrazí prehľad používaných cookies a to vrátane ich účelu, doby
        uchovania a poskytovateľov v prípade cookies tretích strán. V rámci cookie lišty možno nastaviť, ktoré voliteľné
        cookies dotknutá osoba povolí, prípadne si zvolí prijať alebo odmietnuť všetky voliteľné cookies. Toto
        nastavenie je možné kedykoľvek zmeniť.
      </p>
      <p>
        3) Inštrukcie na zmenu, zakázanie alebo odstránenie súborov cookies možno nájsť v nastaveniach každého
        prehliadača. Niektoré prehliadače umožňujú vlastné nastavenie či zasielanie oznámení v prípade prijatia.
        Konkrétne informácie a návody možno nájsť v Pomocníkovi konkrétneho prehliadača. V prípade, ak dotknutá osoba
        používa rozličné zariadenia na prístup k stránkam (napr. počítač, smartphone, tablet), môže dotknutá osoba každý
        prehliadač na každom zariadení prispôsobiť vlastným preferenciám cookies.
      </p>
      <p>
        4) Súbory cookies sú uchovávané v zariadení v závislosti od toho, či ide o dočasné alebo trvalé súbory cookies.
        Trvalé súbory cookies zostávajú uložené v prehliadači až do doby uplynutia ich platnosti, ktorá je uvedená pri
        jednotlivých cookies a dočasné súbory cookies sa zmažú okamihom opustenia webovej stránky dodávateľa.
      </p>
      <p>
        5) Dodávateľ si vyhradzuje právo meniť tieto zásady používania súborov cookies. Tieto zásady nadobúdajú platnosť
        a účinnosť dňom 10.09.2023.
      </p>
      <p></p>
    </div>
  );
};

export default CookiePolicy;
