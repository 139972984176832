import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Stack,
  Box,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { FiLogOut } from "react-icons/fi";
// import { AiOutlineFileText } from "react-icons/ai";
import { FaBook } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { FaHistory } from "react-icons/fa";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

interface LectorHeaderProps {
  handleLogout: () => void;
  userId: string;
  userData: any;
}

const LectorHeader: React.FC<LectorHeaderProps> = ({ handleLogout, userId, userData }) => {
  const isLector = userData?.role === "lector";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };
  

  const drawer = (
    <div>
      <List>
        {/* <ListItem button component={Link} to="/lector/dashboard" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <AiOutlineFileText size={20} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem> */}
        <ListItem button component={Link} to="/lector/course" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaBook size={20} />
          </ListItemIcon>
          <ListItemText primary="Moje krúžky" />
        </ListItem>
        <ListItem button component={Link} to="/lector/attendance" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaUserCheck size={20} />
          </ListItemIcon>
          <ListItemText primary="Vytvoriť dochádzku" />
        </ListItem>
        <ListItem button component={Link} to="/lector/attendance-history" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaHistory size={20} />
          </ListItemIcon>
          <ListItemText primary="História dochádzok" />
        </ListItem>
      </List>
    </div>
  );
  

  if (!isLector) {
    return null;
  }

  return (
    <React.Fragment>
      {isLector && (
        <AppBar position="static" sx={{ backgroundColor: "#336699", marginTop: "2px" }}>
          <Toolbar>
            <Stack direction="row" alignItems="center" spacing={2}>
              {isMobile ? (
                <React.Fragment>
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                    <MenuIcon />
                  </IconButton>
                  <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                    {drawer}
                  </Drawer>
                  <IconButton color="inherit" onClick={handleLogout}>
                    <Box sx={{ ml: 22 }}>
                      <FiLogOut size={20} />
                    </Box>
                    <Typography variant="body1" sx={{ ml: 2 }}>
                      Odhlásiť sa
                    </Typography>
                  </IconButton>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {/* <Link to="/lector/dashboard" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <AiOutlineFileText size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Dashboard
                      </Typography>
                    </IconButton>
                  </Link> */}
                  <Link to="/lector/course" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaBook size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Moje krúžky
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/lector/attendance" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaUserCheck size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Vytvoriť dochádzku
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/lector/attendance-history" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaHistory size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        História dochádzok
                      </Typography>
                    </IconButton>
                  </Link>
                  <IconButton color="inherit" onClick={handleLogout}>
                    <Box sx={{ ml: 100 }}>
                      <FiLogOut size={20} />
                    </Box>
                    <Typography variant="body1" sx={{ ml: 2 }}>
                      Odhlásiť sa
                    </Typography>
                  </IconButton>
                </React.Fragment>
              )}
            </Stack>
          </Toolbar>
        </AppBar>
      )}
    </React.Fragment>
  );
};

export default LectorHeader;
