import React from 'react';
import './FileDownload.css';
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface FileItem {
    name: string;
    path: string;
}

const files: FileItem[] = [
    { name: "Scratch.pdf", path: "/subory/Scratch.pdf" },
    { name: "Python.pdf", path: "/subory/Python.pdf" },
    { name: "Všeobecné obchodné podmienk.pdf", path: "/subory/VOP.pdf" },
    { name: "Formulár na odstúpenie od zmluvy.pdf", path: "/subory/FORMULÁR_NA_ODSTÚPENIE_OD_ZMLUVY.pdf" },
    // ... add more files as necessary
];

const FileDownload: React.FC = () => {

    const { token } = useAuth();
    const hasAccess = (file: FileItem): boolean => {
        // TODO: Implement your logic to check user access here
        // For now, this function always returns true
        return true;
    }

    if (!token) {
        const tokenFromCookie = Cookie.get("token");
        if (!tokenFromCookie) {
          return <NotLoggedIn />;
        }
      }

    return (
        <div className="file-download-container">
            <h2>Moje súbory na stiahnutie</h2>
            <ul>
                {files.map((file, index) => (
                    <li key={index}>
                        {hasAccess(file) ? (
                            <a href={file.path} download>
                                {file.name}
                            </a>
                        ) : (
                            <span>{file.name} (No Access)</span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default FileDownload;
