import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import "./style/adminLector.css"; // Import the CSS file for styling

interface Lector {
  _id: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  phoneNumber: string;
  iban: string;
  courseId: string[]; // An array to store the assigned course IDs
  salary: number;
  town: string;
  role: string | null;
  // Add other properties as needed
}

interface AdminLectorProps {
  userId: string;
}

const AdminLector = ({userId}:AdminLectorProps) => {
  const [lectors, setLectors] = useState<Lector[]>([]);
  const [editedLector, setEditedLector] = useState<Lector | null>(null);
  const [availableCourses, setAvailableCourses] = useState<string[]>([]);
  const [newLector, setNewLector] = useState<Partial<Lector>>({}); // For storing the new lector's data
  const navigate = useNavigate();
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchLectors = async () => {
      try {
        const response = await fetch(url + "lector/allLectors",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setLectors(data);
      } catch (error) {
        console.error("Error fetching lectors:", error);
      }
    };

    const fetchAvailableCourses = async () => {
      try {
        const response = await fetch(url + "course/allCourses",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setAvailableCourses(data.courses && data.courses.map((course: any) => course._id));
      } catch (error) {
        console.error("Error fetching available courses:", error);
      }
    };

    fetchLectors();
    fetchAvailableCourses();
  }, [token, url, userId]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (editedLector) {
      setEditedLector({ ...editedLector, [name]: value });
    }
  };

  const handleSaveChanges = async (): Promise<void> => {
    if (editedLector) {
      try {
        const response = await fetch(url + `lector/${editedLector._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
          body: JSON.stringify(editedLector),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        setLectors((prevLectors) =>
        prevLectors && prevLectors.map((lector) => (lector._id === editedLector._id ? editedLector : lector))
        );

        setEditedLector(null);
      } catch (error) {
        console.error("Error updating lector:", error);
      }
    }
  };

  const assignCourseToLector = async (lector_Id: string): Promise<void> => {
    // todo navigeta to ...
    navigate("/assign-course-to-lector/" + lector_Id);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target;
    if (editedLector) {
      setEditedLector({ ...editedLector, [name]: value });
    }
  };

  const handleCourseButtonClick = (courseId: string): void => {
    // todo navigeta to ...
    navigate("/course/details/" + courseId);
  };

  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  const handleNewLectorChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof Lector) => {
    setNewLector({
      ...newLector,
      role: "lector",
      phoneNumber: 'TODO change',
      iban: 'TODO change',
      salary: 0,
      town: 'TODO change',
      [field]: event.target.value,
    });
  };

  const handleRegisterLector = async () => {
    try {
      const response = await fetch(url + "auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
        body: JSON.stringify(newLector),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setLectors((prevLectors) => [...prevLectors, data]); // Add the newly registered lector to the list
    } catch (error) {
      console.error("Error registering lector:", error);
    }
  };

  return (
    <>
      <div className="register-lector-form">
        <h3>Register New Lector</h3>
        <input type="text" placeholder="Name" onChange={(e) => handleNewLectorChange(e, "name")} />
        <input type="text" placeholder="Surname" onChange={(e) => handleNewLectorChange(e, "surname")} />
        <input type="email" placeholder="Email" onChange={(e) => handleNewLectorChange(e, "email")} />
        <input type="password" placeholder="Password" onChange={(e) => handleNewLectorChange(e, "password")} />
        {/* Add other input fields as needed */}
        <button onClick={handleRegisterLector}>Register</button>
      </div>
      <div className="admin-lector-container">
        <h2>Admin Lector</h2>
        {lectors && lectors.map((lector) => (
          <div key={lector._id} className="lector-item">
            {editedLector && editedLector._id === lector._id ? (
              <>
                <input
                  type="text"
                  name="name"
                  value={editedLector.name}
                  onChange={handleInputChange}
                  className="lector-info-edit"
                />
                <input
                  type="text"
                  name="surname"
                  value={editedLector.surname}
                  onChange={handleInputChange}
                  className="lector-info-edit"
                />
                <input
                  type="text"
                  name="email"
                  value={editedLector.email}
                  onChange={handleInputChange}
                  className="lector-info-edit"
                />
                <input
                  type="text"
                  name="phoneNumber"
                  value={editedLector.phoneNumber}
                  onChange={handleInputChange}
                  className="lector-info-edit"
                />
                <input
                  type="text"
                  name="iban"
                  value={editedLector.iban}
                  onChange={handleInputChange}
                  className="lector-info-edit"
                />
                <input
                  type="text"
                  name="salary"
                  value={editedLector.salary}
                  onChange={handleInputChange}
                  className="lector-info-edit"
                />
                <input
                  type="text"
                  name="town"
                  value={editedLector.town}
                  onChange={handleInputChange}
                  className="lector-info-edit"
                />
                <select
                  name="courseId"
                  value={editedLector.courseId}
                  onChange={handleSelectChange}
                  className="lector-info-edit"
                >
                  {availableCourses && availableCourses.map((courseId) => (
                    <option key={courseId} value={courseId}>
                      {courseId}
                    </option>
                  ))}
                </select>
                <button onClick={handleSaveChanges}>Save</button>
              </>
            ) : (
              //   <>
              //     <span className="lector-info">Name: {lector.name}</span>
              //     <span className="lector-info">Surname: {lector.surname}</span>
              //     <span className="lector-info">Email: {lector.email}</span>
              //     <span className="lector-info">Phone Number: {lector.phoneNumber}</span>
              //     <span className="lector-info">IBAN: {lector.iban}</span>
              //     {/* <span className="lector-info">
              //   Assigned Courses: {lector.courseId.map((course) => course).join(", ")}
              // </span> */}
              //     <span className="lector-info">
              //       Assigned Courses:{" "}
              //       {lector.courseId.map((courseId) => (
              //         <React.Fragment key={courseId}>
              //           {courseId}
              //           <button onClick={() => handleCourseButtonClick(courseId)}>Course details</button>
              //         </React.Fragment>
              //       ))}
              //     </span>
              //     <span className="lector-info">Salary: {lector.salary}</span>
              //     <span className="lector-info">Town: {lector.town}</span>
              //     {!editedLector || editedLector._id !== lector._id ? (
              //       <button onClick={() => setEditedLector(lector)}>Edit</button>
              //     ) : null}
              //     <div>
              //       <button onClick={() => assignCourseToLector(lector._id)}>Assign Course</button>
              //     </div>
              //   </>
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Surname</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>IBAN</th>
                      <th>Assigned Courses</th>
                      <th>Salary</th>
                      <th>Town</th>
                      <th>Edit</th>
                      <th>Assign Course</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lectors && lectors.map((lector) => (
                      <tr key={lector._id} className="lector-item">
                        <td>{lector.name}</td>
                        <td>{lector.surname}</td>
                        <td>{lector.email}</td>
                        <td>{lector.phoneNumber}</td>
                        <td>{lector.iban}</td>
                        <td>
                          {lector.courseId && lector.courseId.map((courseId) => (
                            <React.Fragment key={courseId}>
                              {courseId}
                              <button onClick={() => handleCourseButtonClick(courseId)}>Course details</button>
                              <br />
                            </React.Fragment>
                          ))}
                        </td>
                        <td>{lector.salary}</td>
                        <td>{lector.town}</td>
                        <td>
                          {!editedLector || editedLector._id !== lector._id ? (
                            <button onClick={() => setEditedLector(lector)}>Edit</button>
                          ) : null}
                        </td>
                        <td>
                          <button onClick={() => assignCourseToLector(lector._id)}>Assign Course</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default AdminLector;
