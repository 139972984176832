import React, { useState } from "react";
import { TextField, Button, Typography, Box, Snackbar } from "@mui/material";

interface BillingData {
  fullName: string;
  address: string;
  city: string;
  postalCode: string;
}

const UserBoard: React.FC = () => {
  const [billingData, setBillingData] = useState<BillingData>({
    fullName: "",
    address: "",
    city: "",
    postalCode: "",
  });

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setBillingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const isFormValid = Object.values(billingData).every((value) => value.trim() !== "");
    if (isFormValid) {
      // Perform submission logic, e.g., save billing data
      console.log(billingData);
      setIsSnackbarOpen(true);
    } else {
      setIsSnackbarOpen(false);
    }
  };

  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  return (
    <Box sx={{ maxWidth: 400, mx: "auto" }}>
      <Typography variant="h4" component="h2" gutterBottom>
        User Board
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          name="fullName"
          label="Full Name"
          value={billingData.fullName}
          onChange={handleChange}
          fullWidth
          required
          error={billingData.fullName.trim() === ""}
          helperText={billingData.fullName.trim() === "" ? "Field cannot be empty" : ""}
        />
        <TextField
          name="address"
          label="Address"
          value={billingData.address}
          onChange={handleChange}
          fullWidth
          required
          error={billingData.address.trim() === ""}
          helperText={billingData.address.trim() === "" ? "Field cannot be empty" : ""}
        />
        <TextField
          name="city"
          label="City"
          value={billingData.city}
          onChange={handleChange}
          fullWidth
          required
          error={billingData.city.trim() === ""}
          helperText={billingData.city.trim() === "" ? "Field cannot be empty" : ""}
        />
        <TextField
          name="postalCode"
          label="Postal Code"
          value={billingData.postalCode}
          onChange={handleChange}
          fullWidth
          required
          error={billingData.postalCode.trim() === ""}
          helperText={billingData.postalCode.trim() === "" ? "Field cannot be empty" : ""}
        />
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Save
        </Button>
      </form>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Data submitted successfully"
      />
    </Box>
  );
};

export default UserBoard;
