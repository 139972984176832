import React, { useEffect, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./Main3.css";

type BoxData = {
  title: string;
  text: string;
};

const boxes: BoxData[] = [
  {
    title: "Proč naše kurzy?",
    text: "Naše kurzy programování jsou jedinečné tím, že se konají v areálu školy, v učebnách informatiky. Vaše dítě tak nemusí nikam cestovat, my přijedeme za ním.",
  },
  {
    title: "Cena nášho kurzu",
    text: "Cena se odvíjí od města, ve kterém se nachází základní škola žáka, od výše poplatku za pronájem učebny informatiky a od obsazenosti žáků,   která je omezena na 10-12 účastníků.",
  },
  {
    title: "Jak se přihlásit?",
    text: "Proces podání žádosti je velmi jednoduchý. Stačí se přihlásit ke svému účtu na našich webových stránkách a poté vyplnit přihlášku do kurzu.",
  },
  {
    title: "Co potřebujete?",
    text: "K účasti na kurzu není potřeba žádné speciální vybavení. Stačí, aby vaše dítě přišlo na lekci s dobrou náladou a chutí učit se novým věcem. Poskytneme mu všechny potřebné pomůcky a vybavení.",
  },
];

const Main3: React.FC = () => {
  const [activeBoxIndex, setActiveBoxIndex] = useState(0);

  const prevBox = () => {
    setActiveBoxIndex((oldIndex) => (oldIndex > 0 ? oldIndex - 1 : boxes.length - 1));
  };

  const nextBox = () => {
    setActiveBoxIndex((oldIndex) => (oldIndex < boxes.length - 1 ? oldIndex + 1 : 0));
  };

  // each 2 seconds, the next box will be shown
  useEffect(() => {
    const interval = setInterval(() => {
      nextBox();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="container3">
      <NavigateBeforeIcon className="navigation-icon2" onClick={prevBox} />
      <SwitchTransition>
        <CSSTransition key={activeBoxIndex} timeout={500} classNames="box-transition">
          <div className="box">
            <h2>{boxes[activeBoxIndex].title}</h2>
            <p>{boxes[activeBoxIndex].text}</p>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <NavigateNextIcon className="navigation-icon2" onClick={nextBox} />
    </div>
  );
};

export default Main3;
