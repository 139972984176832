import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface Props extends CircularProgressProps {
  message?: string;
}

const CircularIndeterminate = ({
  message,
  size = 100,
  ...rest
}: Props) => {
  return (
    <Box
      sx={{
        position: "fixed",
        width: "103vw",
        height: "100vh",
        top: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          top: "45%",
          zIndex: 2,
        }}
      >
        <CircularProgress size={size} {...rest} />
        <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularIndeterminate;
