import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Snackbar, Alert } from "@mui/material";
import { Link, useLocation } from 'react-router-dom';

const CourseInterest: React.FC = () => {
  const query = useQuery();
  const schoolFromQuery = query.get('schoolName');
  const selectedLanguage = query.get('language');
  const [email, setEmail] = useState("");
  const [childName, setChildName] = useState("");
  const [schoolName, setSchoolName] = useState(schoolFromQuery || "");
  const [childClass, setChildClass] = useState("");
  const [courseLanguage, setCourseLanguage] = useState(selectedLanguage || "");
  const [preferredTime, setPreferredTime] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [warning, setWarning] = useState<string | null>(null);
  const [dataProcessingConsent, setDataProcessingConsent] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  };

  const customAlert = (msg: string, severity: "success" | "error" = "success") => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const url =
      process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

    const response = await fetch(url + "interest/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
      },
      body: JSON.stringify({
        email,
        childName,
        className: childClass,
        schoolName,
        language: courseLanguage,
        preferredDay: selectedDay,
        preferredTime,
      }),
    });

    if (response.ok) {
      customAlert("Žiadosť bola úspešne odoslaná!", "success");
      setEmail("");
      setChildName("");
      setSchoolName("");
      setChildClass("");
      setCourseLanguage("");
      setPreferredTime("");
      setSelectedDay("");
    } else {
      customAlert("Nepodarilo sa odoslať Žiadosť.", "error");
    }
  };

  return (
    <Card sx={{ maxWidth: 800, margin: "20px auto", padding: "20px" }}>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom sx={{ fontSize: "2em", marginBottom: "20px" }}>
          Záujem o kurz programovania
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ marginBottom: "20px", fontStyle: "italic" }}>
          Nevyhovuje vám termín kurzu alebo sa Vám neušlo miesto? Zaregistrujte sa do zoznamu záujemcov a my vás budeme
          informovať o nových termínoch. V prípade uvoľnenia kapacity kurzu ako a v prípade novo otvoreného kurzu vás
          budeme informovať.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="email"
                name="email"
                label="Mail"
                variant="outlined"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="childName"
                name="childName"
                label="Meno dieťaťa"
                variant="outlined"
                fullWidth
                required
                value={childName}
                onChange={(e) => setChildName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="childClass"
                name="childClass"
                label="Trieda dieťaťa"
                variant="outlined"
                fullWidth
                required
                value={childClass}
                onChange={(e) => {
                  setChildClass(e.target.value);
                  if (e.target.value === "1") {
                    setWarning("Your warning message here");
                  } else {
                    setWarning(null);
                  }
                }}
              />
              {warning && <p style={{ color: "red" }}>{warning}</p>}
            </Grid>

            <Grid item xs={12}>
              <TextField
                id="schoolName"
                name="schoolName"
                label="Názov školy"
                variant="outlined"
                fullWidth
                required
                value={schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel htmlFor="courseLanguage">Programovací jazyk</InputLabel>
                <Select
                  id="courseLanguage"
                  name="courseLanguage"
                  value={courseLanguage}
                  onChange={(e) => setCourseLanguage(e.target.value)}
                  label="Programovací jazyk"
                >
                  <MenuItem value={"Scratch"}>Scratch</MenuItem>
                  <MenuItem value={"Python"}>Python</MenuItem>
                  <MenuItem value={"Roblox"}>Roblox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="day-label">Preferovaný deň</InputLabel>
                <Select
                  labelId="day-label"
                  id="selectedDay"
                  value={selectedDay}
                  onChange={(e) => setSelectedDay(e.target.value)}
                  label="Deň"
                >
                  <MenuItem value={"Monday"}>Pondelok</MenuItem>
                  <MenuItem value={"Tuesday"}>Utorok</MenuItem>
                  <MenuItem value={"Wednesday"}>Streda</MenuItem>
                  <MenuItem value={"Thursday"}>Štvrtok</MenuItem>
                  <MenuItem value={"Friday"}>Piatok</MenuItem>
                  <MenuItem value={"Nezalezi"}>Nezáleží</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" required>
                <InputLabel id="time-label">Preferovaný čas</InputLabel>
                <Select
                  labelId="time-label"
                  id="preferredTime"
                  value={preferredTime}
                  onChange={(e) => setPreferredTime(e.target.value)}
                  label="Čas"
                >
                  <MenuItem value={"1:00"}>13:00</MenuItem>
                  <MenuItem value={"1:30"}>13:30</MenuItem>
                  <MenuItem value={"2:00"}>14:00</MenuItem>
                  <MenuItem value={"2:30"}>14:30</MenuItem>
                  <MenuItem value={"3:00"}>15:00</MenuItem>
                  <MenuItem value={"Nezalezi"}>Nezáleží</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={dataProcessingConsent}
                    onChange={(e) => setDataProcessingConsent(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <>
                    {"Súhlasím so spracovaním osobných údajov v súlade so "}
                    <Link to="/privacy-policy" style={{ textDecoration: 'underline' }}>
                      zásadami ochrany osobných údajov
                    </Link>
                  </>
                }              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={
                  !email ||
                  !childName ||
                  !childClass ||
                  !schoolName ||
                  !courseLanguage ||
                  !selectedDay ||
                  !preferredTime ||
                  !dataProcessingConsent
                }
                sx={{
                  fontSize: "1.1em",
                  backgroundColor: "#3f51b5",
                  color: "white",
                  "&.Mui-disabled": {
                    backgroundColor: "#3f51b5",
                    color: "white",
                  },
                }}
              >
                Odoslať
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
      <p
        style={{
          marginTop: "20px",
          fontSize: "16px",
          color: "#ff0000",
          fontWeight: "bold",
          textAlign: "center",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        Táto prihláška slúži na informačné účely. Pre plnohodnotné prihlásenie žiaka na kurz je potrebné prihlásenie sa
        do aplikácie.
      </p>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default CourseInterest;
