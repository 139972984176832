import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import NotLoggedIn from '../Login/NotLoggedIn';
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import "./StopAttending.css";

interface StopAttandingProps {
  userId: string;
}

const StopAttending = ({userId} : StopAttandingProps) => {
  const { childrenId } = useParams<{ childrenId: string }>();
  const [date, setDate] = useState("");
  const [reason, setReason] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const { token } = useAuth();

  const url =
  process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
  
  const handleUnregistration = async () => {
    // PUT fetch request logic goes here
    const requestType = "Odhlásenie z kurzu";
    try {
      const response = await fetch(url + 'request/register', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
        body: JSON.stringify({
          type: requestType,
          description: reason + " od dátumu " + date,
          childrenId,
          userId: userId,
          status: 'vybavuje sa'
        }),
      });
      await response.json();
      setShowModal(false);
      setIsCanceled(true);
    } catch (error) {
      console.error('Failed to unregister:', error);
    }
  };

    // if token is null try token from cookies
    if (!token) {
      const tokenFromCookie = Cookie.get("token");
      if (!tokenFromCookie) {
        return <NotLoggedIn />;
      }
    }

  return (
    <div className="stop-attending-container">
      {!isCanceled ? (
        <>
          <h2>Prečo chcete svoje dieťa odhlásiť?</h2>
          <textarea
            value={reason}
            onChange={e => setReason(e.target.value)}
            placeholder="Dôvod..."
          />
          <h3>Od kedy chcete dieťa odhlásiť?</h3>
          <input 
            type="date" 
            value={date}
            onChange={e => setDate(e.target.value)} 
            placeholder="Od akého dátumu?" 
          />
          <button onClick={() => setShowModal(true)}>Odhlasit</button>

          {showModal && (
            <div className="modal">
              <h2>Určite si prajete odhlásiť vaše dieťa z krúžku?</h2>
              <button onClick={handleUnregistration}>Áno</button>
              <button onClick={() => setShowModal(false)}>Nie</button>
            </div>
          )}
        </>
      ) : (
          <p className="cancel-message">Prijali sme žiadosť na odhlásenie dieťaťa z krúžku.</p>
      )}
    </div>
  );
}

export default StopAttending;
