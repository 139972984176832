// date.ts

// Function to format a date string into the desired format
export const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed, so add 1 to get the correct month number
    const year = date.getFullYear();
  
    // Format the date as desired (e.g., "25 January 2024")
    const formattedDate = `${day} ${getMonthName(month)} ${year}`;
  
    return formattedDate;
  };
  
  // Helper function to get the month name based on the month number (1 to 12)
  export const getMonthName = (month: number): string => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month - 1] || "";
  };
  