import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import "./style/adminUser.css"; // Import the CSS file for styling

interface User {
  _id: string;
  email: string;
  password: string;
  resetToken?: string;
  resetTokenExpiration?: Date;
  role: string;
  createdAt?: Date;
  updatedAt?: Date;
  variableSymbol: string;
  name?: string;
  surname?: string;
  address?: string;
  city?: string;
  postCode?: string;
  phoneNumber?: string;
  childrens?: string[];
  payments?: string[];
}

interface AdminUserProps {
  userId: string;
}

const AdminUser = ({userId}: AdminUserProps) => {
  const [users, setUsers] = useState<User[]>([]);
  const [editedUser, setEditedUser] = useState<User | null>(null);
  const [editedFields, setEditedFields] = useState<Partial<User>>({});
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(url + "user/allUsers", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }let data = await response.json();
        // get onlu users with role user
        data = data.filter((user: User) => user.role === "user");

        setUsers(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [token, url, userId]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setEditedFields({ ...editedFields, [name]: value });
  };

  const handleSaveChanges = async (): Promise<void> => {
    if (editedUser?._id !== undefined) {
      //   try {
      //     console.log('editedFields');
      //     const response = await fetch(url + `user/${editedUser._id}`, {
      //       method: "PUT",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(editedFields),
      //     });
      //     if (!response.ok) {
      //       throw new Error("Network response was not ok.");
      //     }
      //     setUsers(users.map((user) => (user._id === editedUser._id ? { ...user, ...editedFields } : user)));
      //     setIsEditing(false);
      //     setEditedFields({});
      //   } catch (error) {
      //     console.error("Error updating user:", error);
      //   }
    }
  };

    // if token is null try token from cookies
    if (!token) {
      const tokenFromCookie = Cookie.get("token");
      if (!tokenFromCookie) {
        return <NotLoggedIn />;
      }
    }

  return (
    <div className="admin-user-container">
      <h2>Admin User</h2>
      {users.map((user) => (
        <div key={user._id} className="user-item">
          {isEditing && editedUser?._id === user._id ? (
            <>
            <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={editedFields.name || user.name || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "name" ? "edited" : ""}`}
              />
                <label htmlFor="surname">Surname</label>
              <input
                type="text"
                name="surname"
                value={editedFields.surname || user.surname || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "surname" ? "edited" : ""}`}
              />
                <label htmlFor="address">Address</label>
              <input
                type="text"
                name="address"
                value={editedFields.address || user.address || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "address" ? "edited" : ""}`}
              />
                <label htmlFor="city">City</label>
              <input
                type="text"
                name="city"
                value={editedFields.city || user.city || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "city" ? "edited" : ""}`}
              />
                <label htmlFor="postCode">Post Code</label>
              <input
                type="text"
                name="postCode"
                value={editedFields.postCode || user.postCode || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "postCode" ? "edited" : ""}`}
              />
                <label htmlFor="phoneNumber">Phone Number</label>
              <input
                type="text"
                name="phoneNumber"
                value={editedFields.phoneNumber || user.phoneNumber || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "phoneNumber" ? "edited" : ""}`}
              />
                <label htmlFor="childrens">Childrens</label>
              <input
                type="text"
                name="childrens"
                value={editedFields.childrens || user.childrens || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "childrens" ? "edited" : ""}`}
              />
                <label htmlFor="payments">Payments</label>
              <input
                type="text"
                name="payments"
                value={editedFields.payments || user.payments || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "payments" ? "edited" : ""}`}
              />
                <label htmlFor="variableSymbol">Variable Symbol</label>
              <input
                type="text"
                name="variableSymbol"
                value={editedFields.variableSymbol || user.variableSymbol || ""}
                onChange={handleInputChange}
                // className={`user-info-edit ${editedFields === "variableSymbol" ? "edited" : ""}`}
              />

              <button onClick={handleSaveChanges}>Save</button>
                <button onClick={() => setIsEditing(false)}>Cancel</button>
            </>
          ) : (
            <div className="user-info-container">
            <table className="user-info-table">
              <tbody>
                <tr className="user-item">
                  <td>ID:</td>
                  <td>{user._id}</td>
                </tr>
                <tr className="user-item">
                  <td>Email:</td>
                  <td>{user.email}</td>
                </tr>
                <tr className="user-item">
                  <td>Role:</td>
                  <td>{user.role}</td>
                </tr>
                <tr className="user-item">
                  <td>Variable Symbol:</td>
                  <td>{user.variableSymbol}</td>
                </tr>
                <tr className="user-item">
                  <td>Name:</td>
                  <td>{user.name || ""}</td>
                </tr>
                <tr className="user-item">
                  <td>Surname:</td>
                  <td>{user.surname || ""}</td>
                </tr>
                <tr className="user-item">
                  <td>Address:</td>
                  <td>{user.address || ""}</td>
                </tr>
                <tr className="user-item">
                  <td>City:</td>
                  <td>{user.city || ""}</td>
                </tr>
                <tr className="user-item">
                  <td>Post Code:</td>
                  <td>{user.postCode || ""}</td>
                </tr>
                <tr className="user-item">
                  <td>Phone Number:</td>
                  <td>{user.phoneNumber || ""}</td>
                </tr>
                {/* Display childrens and payments */}
                <tr className="user-item">
                  <td>Childrens:</td>
                  <td>{user.childrens?.join(", ") || "None"}</td>
                </tr>
                <tr className="user-item">
                  <td>Payments:</td>
                  {/* <td>{user.payments?.join(", ") || "None"}</td> TODO fix */}
                </tr>
                <tr className="user-item">
                  <td>
                    <button onClick={() => {
                      setIsEditing(true);
                      setEditedUser(user);
                      setEditedFields({}); // Clear any previously edited fields
                    }}>
                      Edit
                    </button>
                  </td>
                  <td>
                    <Link to={`/user/${user._id}/details`} className="link-button">
                      <button>User Details</button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          )}
        </div>
      ))}
    </div>
  );
};

export default AdminUser;
