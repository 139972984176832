import { useState, useEffect } from "react";
import "./style/adminSchool.css"; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';
import { fetchSchools } from "../../utils/fetchSchools";
import { formatDate } from '../../utils/date';
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface School {
  _id: string; // Use _id as the unique identifier
  name: string;
  location: string;
  town: string;
  address: string; // Add address property
  imgURL: string; // Add imgURL property
  // Add other properties as needed
}

interface AdminSchoolProps {
  userId: string;
}

const AdminSchool = ({userId}: AdminSchoolProps) => {
  const [schools, setSchools] = useState<School[]>([]);
  const [showButtonSet, setShowButtonSet] = useState<boolean>(false);
  const navigate = useNavigate();
  const [newSchool, setNewSchool] = useState<School>({
    _id: "",
    name: "",
    location: "",
    town: "",
    address: "", // Add address property
    imgURL: "", // Add imgURL property
  });
  const [editingSchoolId, setEditingSchoolId] = useState<string | null>(null);
  const [editedSchool, setEditedSchool] = useState<School>({
    _id: "",
    name: "",
    location: "",
    town: "",
    address: "", // Add address property
    imgURL: "", // Add imgURL property
  });

  const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null);
  const [courses, setCourses] = useState<string[]>([]); // Array of course names
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    // Fetch schools data using the fetchSchools function
    fetchSchools('', false)
      .then((data) => setSchools(data))
      .catch((error) => console.log(error));
  }, [url]);

  const createSchool = async () => {
    try {
      const response = await fetch(url + "school/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
        body: JSON.stringify({
          name: newSchool.name,
          town: newSchool.town,
          address: newSchool.location, // Add address property
          imgURL: newSchool.imgURL, // Add imgURL property
        }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      // Assuming the response contains the newly created school object with an updated _id
      const createdSchool = await response.json();
      setSchools([...schools, createdSchool]);
      setNewSchool({ _id: "", name: "", location: "", town: "", address: "", imgURL: "" });
    } catch (error) {
      console.error("Error creating school:", error);
    }
  };

  const editSchool = async (id: string) => {
    try {
      const schoolToEdit = schools.find((school) => school._id === id);
      if (!schoolToEdit) return;

      setEditingSchoolId(id);
      setEditedSchool({ ...schoolToEdit });
    } catch (error) {
      console.error("Error editing school:", error);
    }
  };

  const saveEditedSchool = async () => {
    console.log("saveEditedSchool", editedSchool);
    try {
      const response = await fetch(url + `school/${editingSchoolId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
        body: JSON.stringify(editedSchool),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      setSchools(schools.map((school) => (school._id === editingSchoolId ? editedSchool : school)));
      setEditingSchoolId(null);
    } catch (error) {
      console.error("Error saving edited school:", error);
    }
  };

  const cancelEdit = () => {
    setEditingSchoolId(null);
  };

  const showCourses = async (schoolId: string) => {
    try {
      const response = await fetch(url + `school/${schoolId}/courses`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setCourses(data.courses);
      setSelectedSchoolId(schoolId);
      setShowButtonSet(true);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const hideCourses = (schoolId: string) => {
    setSelectedSchoolId(null);
    setShowButtonSet(false);
  };

  const showCourseDetaild = (courseId: string) => {
    // navigate to course details page
    navigate('/course/details/' + courseId);
  };

    // if token is null try token from cookies
    if (!token) {
      const tokenFromCookie = Cookie.get("token");
      if (!tokenFromCookie) {
        return <NotLoggedIn />;
      }
    }

  return (
    <>
    <div className="admin-school-container">
      {/* Render the list of schools */}
      <ul className="school-list">
        {schools.map((school) => (
          <li key={school._id} className="school-item">
            {editingSchoolId === school._id ? (
              <>
              <div className="school-item-container">
                <input
                  type="text"
                  value={editedSchool.name}
                  onChange={(e) => setEditedSchool({ ...editedSchool, name: e.target.value })}
                  placeholder="School Name"
                  className="input-field"
                />
                <input
                  type="text"
                  value={editedSchool.address}
                  onChange={(e) => setEditedSchool({ ...editedSchool, address: e.target.value })}
                  placeholder="Location"
                  className="input-field"
                />
                <input
                  type="text"
                  value={editedSchool.town}
                  onChange={(e) => setEditedSchool({ ...editedSchool, town: e.target.value })}
                  placeholder="Town"
                  className="input-field"
                />
                {/* <input
                  type="text"
                  value={editedSchool.address}
                  onChange={(e) => setEditedSchool({ ...editedSchool, address: e.target.value })}
                  placeholder="Address"
                  className="input-field"
                /> */}
                <input
                  type="text"
                  value={editedSchool.imgURL}
                  onChange={(e) => setEditedSchool({ ...editedSchool, imgURL: e.target.value })}
                  placeholder="Image URL"
                  className="input-field"
                />
                </div>
                <br />
                <div className="create-button">
                <button className="save-button" onClick={saveEditedSchool}>
                  Save
                </button>
                <button className="cancel-button" onClick={cancelEdit}>
                  Cancel
                </button>
                </div>
              </>
            ) : (
              <>
                <span className="school-name">{school.name}</span>
                <span className="school-location">{school.location}</span>
                <span className="school-town">{school.town}</span>
                <span className="school-address">{school.address}</span>
                <div className="button-group">
                  <button className="edit-button" onClick={() => editSchool(school._id)}>
                    Edit
                  </button>
                  {showButtonSet ? (
                    <button className="hide-courses-button" onClick={() => hideCourses(school._id)}>
                      Hide Courses
                    </button>
                  ) : (
                    <button className="show-courses-button" onClick={() => showCourses(school._id)}>
                      Show Courses
                    </button>
                  )}
                </div>
              </>
            )}
          </li>
        ))}
      </ul>

      {/* Show courses for the selected school */}
      {selectedSchoolId && (
        <div className="course-list">
          <h3>Courses for {schools.find((school) => school._id === selectedSchoolId)?.name}</h3>
          <ul>
            {courses.map(
              (
                course: any // Use 'any' type if the course object doesn't have an interface
              ) => (
                <li key={course._id}>
                  <div className="course-item">
                    <span className="course-language">Time: {course.time}</span>
                    <span className="course-date">Language: {course.language}</span>
                    <span className="course-date">Start date: {formatDate(course.startDate)}</span>
                    <span className="course-date">End date: {formatDate(course.endDate)}</span>
                    <span className="course-lector">Lector Id: {course.lectorId}</span>
                    <span className="course-lector">Lector Name: {course.lectorName}</span>
                    <span className="course-lector">Month Payment: {course.monthPayment} $</span>
                    <span className="course-lector">Capacity: {course.capacity}</span>
                    <span className="course-lector">Free places: {course.capacity - course.childrens.length}</span>
                    <button className="edit-button" onClick={() => showCourseDetaild(course._id)}>
                      Show details
                    </button>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      )}

      {/* Form to create a new school */}
      </div>
      <div className="new-school-form">
        <input
          type="text"
          value={newSchool.name}
          onChange={(e) => setNewSchool({ ...newSchool, name: e.target.value })}
          placeholder="School Name"
          className="input-field"
        />
        <input
          type="text"
          value={newSchool.location}
          onChange={(e) => setNewSchool({ ...newSchool, location: e.target.value })}
          placeholder="Location"
          className="input-field"
        />
        <input
          type="text"
          value={newSchool.town}
          onChange={(e) => setNewSchool({ ...newSchool, town: e.target.value })}
          placeholder="Town"
          className="input-field"
        />
        {/* <input
          type="text"
          value={newSchool.address}
          onChange={(e) => setNewSchool({ ...newSchool, address: e.target.value })}
          placeholder="Address"
          className="input-field"
        /> */}
        <input
          type="text"
          value={newSchool.imgURL}
          onChange={(e) => setNewSchool({ ...newSchool, imgURL: e.target.value })}
          placeholder="Image URL"
          className="input-field"
        />
      </div>
      <br />
      <button className="create-button" onClick={createSchool}>
          Create School
        </button>
    </>
  );
};

export default AdminSchool;
