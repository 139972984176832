import React from "react";
import "./AboutUs.css";

const AboutUs: React.FC = () => {
  return (
    <div className="about-us">
     <h1>Vítejte u mladých programátorů</h1>
      <p>
        Jsme skupina nadšených pedagogů a studentů, kteří se snaží vychovávat novou generaci vývojářů. Ze zkušenosti víme, že programování je skvělý způsob, jak rozvíjet kreativitu, logické myšlení a řešení problémů. Během
        naší existence jsme se setkali s mnoha mladými studenty, kteří jsou pro programování stejně zapálení jako my.
      </p>
      <p>
        Naše filozofie je jednoduchá: programování by mělo být zábavné, přístupné a inspirativní. Věříme, že díky
        technologií máme moc změnit svět k lepšímu a chceme, aby naši studenti stáli v čele této změny.
      </p>
      <p>
        V posledním desetiletí se svět technologií neustále vyvíjí rychlostí blesku. Uprostřed tohoto technologického
        revoluce jsme se zrodili jako Mladí programátoři. Naše vášeň pro programování a odhodlání podělit se o ně s ostatními.
        s mladou generací nás přivedlo k tomu, že jsme se stali jedním z předních vzdělávacích center v České republice.
        IT vzdělávání v zemi.
      </p>
      <p>
        Týmy našich pedagogů tvoří profesionální programátoři, studenti informatiky, kteří pracovali na
        globálních technologických projektech. Každý z nich přinese do výuky bohaté zkušenosti a osobní příběhy, které
        inspirují a motivují studenty.
      </p>
      <p>
        Taktiež sme hrdí na našu komunitu bývalých študentov, ktorí teraz pracujú v popredných technologických firmách
        po celom svete. Naša alumni sieť je aktívna a angažovaná, často sa vracia, aby prednášala, mentovala a pomáhala
        smerovať našu víziu do budúcnosti.
      </p>
      <p>
        V Mladých Programátoroch veríme, že vzdelávanie je neustály proces. Naše platformy poskytujú študentom
        prístup k množstvu zdrojov, tutoriálov a podporných komunít, kde môžu pokračovať vo svojom vzdelávaní mimo
        triedy. Snažíme sa byť nielen miestom výučby, ale aj komunitou, kde sa študenti môžu cítiť podporovaní a
        inšpirovaní.
      </p>
      <p>
        Na záver by sme radi povedali, že naša cesta ešte len začína. V dobe, keď sa technológia stáva stredobodom
        našich životov, je naším cieľom prispieť k tomu, aby mladá generácia bola pripravená na výzvy budúcnosti.
      </p>
      <p>
        Používaním našej stránky súhlasíte s našimi <a href="#/cookiePolicy">zásadami používania súborov cookie</a>.
        Používateľov, ktorí sa rozhodnú obísť podmienky, upozorňujeme, že niektoré funkcie našej stránky môžu byť
        obmedzené. Viac informácií o <a href="#/terms-and-conditions">všeobecných obchodných podmienkach</a>.
      </p>
    </div>
  );
};

export default AboutUs;
