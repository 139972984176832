import React, { useState, useEffect } from "react";
import { Box, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface Course {
  course: any;
  _id: string;
  time: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolName: string;
  lectorName: string;
  monthPayment: number;
  capacity: number;
  schoolId: string;
  childrens: string[];
  attendIds: string[];
  // Add other properties as needed
}

interface LectorCoursesProps {
  lectorId: string;
}

const LectorCourses: React.FC<LectorCoursesProps> = ({ lectorId }) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchLectorCourses = async () => {
      if (!lectorId) {
        return;
      }
      if (!token) {
        return;
      }
      try {
        const response = await fetch(url + `lector/user/${lectorId}`, {
          // TODO
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": lectorId, // custom header for user ID
            "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();

        const fetchCoursesData = async (courseId: string) => {
          const courseResponse = await fetch(url + `course/${courseId}`, {
            headers: {
              "Content-Type": "application/json",
              "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
            },
          });
          if (!courseResponse.ok) {
            throw new Error("Network response was not ok.");
          }
          const courseData = await courseResponse.json();
          return courseData;
        };

        const coursesData = await Promise.all(data.courseId.map(fetchCoursesData));

        setCourses(coursesData);
      } catch (error) {
        console.error("Error fetching lector courses:", error);
      }
    };

    fetchLectorCourses();
  }, [lectorId, token, url]);

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  function formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "auto" }}>
      <TableContainer component={Paper}>
        <Table stickyHeader sx={{ minWidth: 700 }}>
          {" "}
          {/* Adjust minWidth as needed */}
          <TableHead>
            <TableRow>
              <TableCell>Čas krúžku</TableCell>
              <TableCell>Programovací jazyk</TableCell>
              <TableCell>Začiatok krúžku</TableCell>
              <TableCell>Koniec krúžku</TableCell>
              <TableCell>Škola</TableCell>
              <TableCell>Kapacita krúžku</TableCell>
              <TableCell>Počet príhlásených detí</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses &&
              courses.map((course) => (
                <TableRow key={course.course._id}>
                  <TableCell>{course.course.time}</TableCell>
                  <TableCell>{course.course.language}</TableCell>
                  <TableCell>{formatDate(course.course.startDate)}</TableCell>
                  <TableCell>{formatDate(course.course.endDate)}</TableCell>
                  <TableCell>{course.course.schoolName}</TableCell>
                  <TableCell>{course.course.capacity > 12 ? '12' : course.course.capacity}</TableCell>
                  <TableCell>{course.course.childrens === null ? "zatial nikto" :  (course.course.childrens.length > 12 ? '12' : course.course.childrens.length)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LectorCourses;
