// AdminRequests.tsx

import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import { Select, MenuItem } from "@mui/material";

interface Request {
  _id: string;
  userId: string;
  childrenId: string;
  email: string;
  childName?: string;
  status: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  type: string;
}
interface AdminRequestsProps {
  userId: string;
}

const AdminRequests = ({ userId }: AdminRequestsProps) => {
  const [requests, setRequests] = useState<Request[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<Request | null>(null);
  const [reason, setReason] = useState<string>("");
  const { token } = useAuth();
  const [updatedStatus, setUpdatedStatus] = useState<string>("");

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    if (!token) return;
    if (!userId) return;
    fetch(url + "request/get-all-requests", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "X-User-Id": userId, // custom header for user ID
      },
    })
      .then((response) => {
        if (!response.ok) throw new Error(response.statusText);
        return response.json();
      })
      .then((data) => {
        const sortedData = data.sort((a: Request, b: Request) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        setRequests(sortedData);
      })
      .catch((error) => {
        console.error("There was an error fetching the requests:", error);
      });
  }, [token, url, userId]);

  const handleEdit = (request: Request) => {
    setSelectedRequest(request);
  };

  const handleSave = async (id: string) => {
    if (!token) return;
    if (!userId) return;
    if (!id) return;
    try {
      const response = await fetch(url + `request/edit-interest-by-admin/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",  
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
        body: JSON.stringify({status: updatedStatus, reason}),
      });

      if (!response.ok) throw new Error(response.statusText);

      const updatedRequest = await response.json();
      const updatedRequests = requests.map((req) => (req._id === id ? updatedRequest : req));
      setRequests(updatedRequests);
      setSelectedRequest(null);
    } catch (error) {
      console.error("There was an error updating the request:", error);
    }
  };

  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <Box p={3}>
      <Typography variant="h4">Admin Requests</Typography>
      <Paper elevation={3}>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>Date</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Child Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests.map((request) => (
              <TableRow key={request._id}>
                <TableCell>{new Date(request.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{request.email}</TableCell>
                <TableCell>{request.childName}</TableCell>
                <TableCell>
                  {selectedRequest?._id === request._id ? (
                    <Select
                    value={selectedRequest ? selectedRequest.status : ""}
                    onChange={(e) => {
                        const newStatus = e.target.value as "vybavuje sa" | "vybavené" | "zamietnutá";
                        setSelectedRequest((prev) => ({
                          ...prev!,
                          status: newStatus,
                        }));
                        setUpdatedStatus(newStatus);
                      }}                      
                      fullWidth
                    >
                      <MenuItem value="vybavuje sa">vybavuje sa</MenuItem>
                      <MenuItem value="vybavené">vybavené</MenuItem>
                      <MenuItem value="zamietnutá">zamietnutá</MenuItem>
                    </Select>
                  ) : (
                    request.status
                  )}
                </TableCell>
                <TableCell>
                  {selectedRequest?._id === request._id ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={selectedRequest ? selectedRequest.description : ""}
                      onChange={(e) => setSelectedRequest((prev) => ({ ...prev!, description: e.target.value }))}
                    />
                  ) : (
                    request.description
                  )}
                </TableCell>
                <TableCell>
                  {selectedRequest?._id === request._id ? (
                    <TextField
                    label="Reason"
                    fullWidth
                      variant="outlined"
                      value={reason ? reason : ""}
                      onChange={(e) => setReason(e.target.value)}
                    />
                  ) : (
                    request.description
                  )}
                </TableCell>
                <TableCell>{request.type}</TableCell>
                <TableCell>
                  {selectedRequest?._id === request._id ? (
                    <>
                      <Button color="primary" onClick={() => handleSave(request._id)}>
                        Save
                      </Button>
                      <Button color="secondary" onClick={() => setSelectedRequest(null)}>
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button color="primary" onClick={() => handleEdit(request)}>
                      Edit
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default AdminRequests;
