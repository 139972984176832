import { useState, useEffect, FormEvent } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Login.css";

function SetNewPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const navigate = useNavigate();
  const { token } = useParams();

  let url = process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;


  const handleSetNewPassword = async (e: FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      // Passwords don't match, display an error
      setPasswordMatchError(true);
      setShowPopup(true);
      return;
    }

    try {
      const response = await fetch(url + `auth/reset-password/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }

      setShowPopup(true);
    } catch (error) {
      console.error("Error:", error);
      setIsSuccess(false);
      setShowPopup(true);
    }

    // Reset the form
    setPassword("");
    setConfirmPassword("");
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
        setIsSuccess(false);
        if (isSuccess){
          navigate("/login");
        }
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showPopup, navigate, isSuccess]);

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSetNewPassword}>
        <h2>Nastaviť nové heslo</h2>
        <input
          className="login-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Nové heslo"
          type="password"
        />
        <input
          className="login-input"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Potvrdiť heslo"
          type="password"
        />
        <button className="login-button" type="submit">
          Nastaviť heslo
        </button>
      </form>
      {showPopup && (
        <div className={`login-popup ${isSuccess ? "success" : "error"}`}>
          {isSuccess ? (
            <p>Heslo úspešne zmenené!</p>
          ) : (
            <>
              <p>Chyba pri zmene hesla. Skúste ešte raz!</p>
              {passwordMatchError && <p>Heslá sa nezhodujú.</p>}
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default SetNewPassword;
