import React, { useState, useEffect } from "react";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface Interest {
  _id?: string; // Assuming there's an ID from MongoDB
  email: string;
  childName: string;
  className: string;
  schoolName: string;
  language: string;
  preferredDay: string;
  preferredTime: string;
  createdAt: Date;
}

interface AdminRequestsProps {
  userId: string;
}

interface Interest {
  _id?: string; // Assuming there's an ID from MongoDB
  email: string;
  childName: string;
  className: string;
  schoolName: string;
  language: string;
  preferredDay: string;
  preferredTime: string;
  createdAt: Date;
  [key: string]: string | Date | undefined;
}


const AdminInterest = ({ userId }: AdminRequestsProps) => {
  const [interests, setInterests] = useState<Interest[]>([]);
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    if (!token) return;
    if (!userId) return;
    fetch(url + "interest/get-all-interests", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "X-User-Id": userId, // custom header for user ID
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Sort interests based on 'createdAt' from newest to oldest
        const sortedInterests = data.sort(
          (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setInterests(sortedInterests);
      })
      .catch((error) => console.error("Error fetching interests:", error));
  }, [token, url, userId]);

  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  function convertToCSV(data: Interest[]) {
    const replacer = (key: any, value: any) => value === null ? '' : value;
    const header = Object.keys(data[0]);
    const csv = [
        header.join(','), 
        ...data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');
    return csv;
}

function downloadCSV(data: Interest[], filename = 'data.csv') {
  const csv = convertToCSV(data);
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}



  return (
    <div>
      <button onClick={() => downloadCSV(interests)}>Download CSV</button>
    <table className="interest-table">
      <thead>
        <tr>
          <th>Email</th>
          <th>Child Name</th>
          <th>Class Name</th>
          <th>School Name</th>
          <th>Language</th>
          <th>Preferred Day</th>
          <th>Preferred Time</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        {interests &&
          interests.map((interest) => (
            <tr key={interest._id}>
              <td>{interest.email}</td>
              <td>{interest.childName}</td>
              <td>{interest.className}</td>
              <td>{interest.schoolName}</td>
              <td>{interest.language}</td>
              <td>{interest.preferredDay}</td>
              <td>{interest.preferredTime}</td>
              <td>{new Date(interest.createdAt).toLocaleDateString()}</td>
            </tr>
          ))}
      </tbody>
    </table>
    </div>
  );
};

export default AdminInterest;
