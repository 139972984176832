import React from "react";
import "./ProgrammingCircleInfo.css";

interface ProgrammingCircleInfoProps {
  infoEmail: string;
}

const ProgrammingCircleInfo: React.FC<ProgrammingCircleInfoProps> = ({ infoEmail }) => {
  return (
    <div className="programming-circle-info">
      <div className="advantages">
        <h2>Výhody našich kurzov</h2>
      </div>
      <div className="info-content2">
        <div className="icon-content">
          <i className="fas fa-users icon"></i>
          <p>Maximálny počet žiakov, ktorí sa môžu zúčastniť kurzu, je 12 z dôvodu individuálneho prístupu lektora</p>
        </div>
        <div className="icon-content">
          <i className="fas fa-school icon"></i>
          <p>Krúžky sú vhodné pre žiakov 1. aj 2. stupňa základných škôl. Po dokončení kurzu je možnosť pokračovať v nadväzujúcich kurzoch</p>
        </div>
        <div className="icon-content">
          <i className="fas fa-euro-sign icon"></i>
          <p>Cena: 7 - 10 eur/hodinu, v závislosti od mesta, podmienok školy a počtu žiakov v kurze. V prípade neprítomnosti žiaka rodič za daný týždeň neplatí</p>
        </div>
        <div className="icon-content">
          <i className="fas fa-laptop-code icon"></i>
          <p>
          Obsah kurzov sa zameriava na základy programovania, vývoj hier a je vedený hravou formou, aby sa žiaci čo najlepšie zabavili a zároveň sa naučili základy programovania
          </p>
        </div>
      </div>
      <div className="advantages">
        <h2>Cena kurzu zahŕňa</h2>
      </div>
      <div className="info-content3">
        <div className="icon-content">
          <i className="fas fa-book icon"></i>
          <p>
            Rodič dostane prístup k materiálom a vypracovaným úlohám, ktoré si žiaci vytvorili počas
            kurzu. Taktiež zbeirku úloh, ktoré si žiaci môžu vypracovať doma.
          </p>
        </div>
        <div className="icon-content">
          <i className="fas fa-certificate icon"></i>
          <p>Osvedčenie o úspešnom absolvovaní kurzu. 
            V prípade pokračovania v ďalšom kurze, žiak má prednostné miesto v kurze a zľavu na cenu kurzu.
          </p>
        </div>
        <div className="icon-content">
          <i className="fas fa-file-pdf icon"></i>
          <p>
            Prístup k materiálom, ktoré sú vytvorené tak, aby žiak mohol ľahko a rýchlo získať
            potrebné znalosti za aktuálny ale aj nasledujúci týždeň.
          </p>
        </div>
        <div className="icon-content">
          <i className="fas fa-comments icon"></i>
          <p>
            V prípade neprítomnosti možnosť náhradného termínu. V prípade, že
            žiakovi niečo nie je jasné, môže sa kedykoľvek opýtať našich lektorov.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProgrammingCircleInfo;
