import React, { CSSProperties } from 'react';

interface ComingSoonProps {
    text?: string;
}

const ComingSoon= ({text}: ComingSoonProps) => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>{text} používateľov</h1>
            <p style={styles.text}>Monentálne prebieha úprava. Čoskoro bude sprístupnená aj užívateľská časť.</p>
            <p>Ďakujeme za pochopenie.</p>
        </div>
    );
};

const styles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80vh',
        backgroundColor: '#F7F9FB',
        borderRadius: '10px',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
        padding: '30px'
    },
    heading: {
        fontSize: '2em',
        color: '#0077c2',
        marginBottom: '20px'
    },
    text: {
        fontSize: '1.2em',
        color: '#555',
        textAlign: 'center'
    }
};

export default ComingSoon;
