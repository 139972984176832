import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CircularIndeterminate from "../ProgressBar/ProgressBar";
import "./style.css"; // Import the CSS file for styling
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface CancelPageProps {
  userId: string;
}

const CancelPage = ({ userId }: CancelPageProps) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get("session_id");
  const [paymentStatus, setPaymentStatus] = useState<null | "success" | "failure">(null);
  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
  const { token } = useAuth();

  useEffect(() => {
    const confirmPayment = async () => {
      let search = window.location.href.split("?");
      let params = new URLSearchParams(search[1]);
      let combinedPaymentIdAndSessionId = params.get("paymentId");

      // split combinedPaymentIdAndSessionId into paymentId and sessionId
      let paymentId = combinedPaymentIdAndSessionId && combinedPaymentIdAndSessionId.split("?session_id=")[0];
      let sessionId = search[2].split("=")[1];
      if (!sessionId) {
        return;
      }
      if(!token){
        return;
      }
      if(!userId){
        return;
      }
      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        const response = await fetch(url + "stripe/confirm", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
          body: JSON.stringify({ sessionId, paymentId }),
        });

        const data = await response.json();

        if (data.status === "failure") {
          setPaymentStatus("success");
        } else {
          setPaymentStatus("failure");
        }
      } catch (error) {
        console.error("Error confirming payment:", error);
        setPaymentStatus("failure");
      }
    };

    confirmPayment();
  }, [sessionId, token, url, userId]);

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="success-page-container">
      {paymentStatus === "success" ? (
        <div className="failure-message">
          <h3>Platba nebola úspešne dokončená.</h3>
          <p>Zlyhanie platby</p>
          <Link to="/payments" className="retry-link">
            Opakovat platba
          </Link>
        </div>
      ) : (
        <CircularIndeterminate message="Kontrola platby!" />
      )}
    </div>
  );
};

export default CancelPage;
