import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-container">
      <h1>Ochrana osobných údajov</h1>
      <h3>Kto spracúva Vašej osobné údaje?</h3>
      <p>
        Vaše osobné údaje spracúvame my – spoločnosť Mladí programatori s.r.o. so sídlom Trieda Andreja Hlinku 606/39,
        Nitra, 949 01, zapísaná u Okresného súdu Nitra , email: mladiprogramatori.info@gmail.com, IČO: 55655203, tel.
        č.: +421 940 014 553.
      </p>
      <h3>U koho sa môžete informovať, čo a ako o Vás spracúvame?</h3>
      <p>
        V prípade akýchkoľvek otázok týkajúcich sa spracúvania Vašich osobných údajov na našej stránke nás môžete
        kontaktovať na e-mailovej adrese: kurzy@volis.sk
      </p>
      <h3>Aké údaje o Vás spracúvame?</h3>
      <p>
        Spracúvame len také osobné údaje, aby sme vám poskytovali profesionálne služby. V prípade, ak vaše osobné údaje
        spracúvame na základe súhlasu, zoznam, resp. rozsah údajov je presne uvedený priamo v súhlase, a to v miere
        potrebnej na splnenie účelu, na ktorý sú osobné údaje spracúvané.
      </p>
      <h3>Spracúvame najmä tieto osobné údaje:</h3>
      <p>
        Kontaktné údaje: Najmä meno, priezvisko, kontaktné telefónne číslo a adresa elektronickej pošty a korešpondenčná
        adresa..
      </p>
      <h3>Ako môžete Váš súhlas odvolať?</h3>
      <p>
        V prípade, ak sa domnievate, že sme porušili Vaše práva v oblasti ochrany osobných údajov, môžete nás
        kontaktovať na e-mailovej adrese: kurzy@volis.sk Alebo môžete kontaktovať Úrad na ochranu osobných údajov
        Slovenskej republiky, Hraničná 12, 820 07 Bratislava.
      </p>
      <h3>Ako dlho u nás budú uložené Vaše osobné údaje?</h3>
      <p>
        Vaše osobné údaje uchovávame pre čas nevyhnutný na splnenie účelu, pre ktorý boli spracované, najdlhšie však do
        zániku našej spoločnosti alebo nášho posledného právneho nástupcu ako subjektu práva v prípade, ak dôjde k
        nášmu/jeho zániku bez ďalšieho právneho nástupcu.
      </p>
      <h3>Aké máte práva?</h3>
      <p>
        V súvislosti s ochranou osobných údajov máte nasledovné práva: právo na prístup k Vašim osobným údajom, máte
        právo žiadať od nás potvrdenie, či o Vás spracúvame osobné údaje, ktoré sa Vás týkajú a v prípade, ak Vaše údaje
        spracúvame, máte právo získať prístup k týmto osobným údajom a podrobné informácie o všetkým skutočnostiach,
        ktoré súvisia so spracúvaním týchto osobných údajov (Vaše právo na prístup k osobným údajom upravuje § 21 zákona
        č. 18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov a čl. 15 nariadenia Európskeho
        parlamentu a Rady (EÚ) č. 2016/679). Na Vaše požiadanie sme povinní poskytnúť Vám Vaše osobné údaje, ktoré o Vás
        spracúvame, pričom za opakované poskytnutie týchto údajov sme oprávnení od Vás požadovať zaplatenie primeraného
        poplatku zodpovedajúceho administratívnym nákladom; právo na opravu osobných údajov, a to na opravu nesprávnych
        údajov, ktoré sa Vás týkajú, prípadne doplnenie neúplných osobných údajov (Vaše právo na opravu osobných údajov
        upravuje § 22 zákona č. 18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov a čl. 16
        nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679); právo na výmaz osobných údajov (Vaše právo na výmaz
        osobných údajov upravuje § 23 zákona č. 18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých
        zákonov a čl. 17 nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679), ak: (a) osobné údaje už nie sú
        potrebné na účel, na ktorý sme ich získali alebo inak spracúvali, (b) osobné údaje spracúvame nezákonne, (c)
        odvoláte nám súhlas so spracúvaním osobných údajov a neexistuje iný právny základ pre spracúvanie osobných
        údajov, (d) namietate spracúvanie osobných údajov a neprevažujú žiadne oprávnené dôvody na spracúvanie osobných
        údajov alebo namietate spracúvanie osobných údajov podľa § 27 ods. 2 zákona č. 18/2018 Z.z. o ochrane osobných
        údajov a o zmene a doplnení niektorých zákonov, (e) je dôvodom pre výmaz splnenie povinnosti podľa zákona č.
        18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov, osobitného predpisu alebo
        medzinárodnej zmluvy, ktorou je Slovenská republika viazaná alebo, (f) sa osobné údaje získavali v súvislosti s
        ponukou služieb informačnej spoločnosti podľa § 15 ods. 1 zákona č. 18/2018 Z.z. o ochrane osobných údajov a o
        zmene a doplnení niektorých zákonov. Právo na výmaz Vašich osobných údajov nemôžete uplatniť v prípade, ak: (a)
        je spracúvanie osobných údajov potrebné na uplatnenie právneho nároku, (b) na uplatnenie práva na slobodu
        prejavu alebo práva na informácie, (c) na splnenie povinnosti podľa zákona č. 18/2018 Z.z. o ochrane osobných
        údajov a o zmene a doplnení niektorých zákonov, nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679,
        osobitného predpisu alebo medzinárodnej zmluvy, ktorou je Slovenská republika viazaná, alebo na splnenie úlohy
        realizovanej vo verejnom záujme alebo pri výkone verejnej moci zverenej tomu, kto osobné údaje spracúva, (d) z
        dôvodov verejného záujmu v oblasti verejného zdravia v súlade s § 16 ods. 2 písm. h) až j) zákona č. 18/2018
        Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov, (e) na účel archivácie, na vedecký účel,
        na účel historického výskumu alebo na štatistický účel podľa § 78 ods. 8 zákona č. 18/2018 Z.z. o ochrane
        osobných údajov a o zmene a doplnení niektorých zákonov, ak je pravdepodobné, že by Vaše právo znemožnilo alebo
        závažným spôsobom sťažilo dosiahnutie cieľov takého spracúvania; právo na obmedzenie spracúvania osobných údajov
        (Vaše právo na obmedzenie spracúvania osobných údajov upravuje § 24 zákona č. 18/2018 Z.z. o ochrane osobných
        údajov a o zmene a doplnení niektorých zákonov a čl. 16 nariadenia Európskeho parlamentu a Rady (EÚ) č.
        2016/679), ak (a) namietate správnosť osobných údajov, a to počas obdobia umožňujúceho nám overiť správnosť
        osobných údajov, (b) spracúvanie osobných údajov je nezákonné a Vy namietate vymazanie osobných údajov a žiadate
        namiesto toho obmedzenie ich použitia, (c) už nepotrebujeme osobné údaje na účel spracúvania osobných údajov,
        ale potrebujete ich Vy na uplatnenie právneho nároku, alebo, (d) namietate spracúvanie osobných údajov podľa §
        27 ods. 1 zákona č. 18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov a čl. 21 ods.
        1 nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679. Ak došlo k obmedzeniu spracúvania osobných údajov,
        môžeme okrem uchovávania osobných údajov osobné údaje spracúvať len s Vašim súhlasom alebo na účely uplatnenia
        právneho nároku, na ochranu osôb alebo z dôvodov verejného záujmu; právo namietať spracúvanie osobných údajov
        (Vaše právo namietať spracúvanie osobných údajov upravuje § 27 zákona č. 18/2018 Z.z. o ochrane osobných údajov
        a o zmene a doplnení niektorých zákonov a čl. 21 nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679), ak
        dochádza k spracúvaniu osobných údajov na základe právneho základu podľa § 13 ods. 1 písm. e) alebo písm. f)
        zákona č. 18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov, resp. čl. 6 ods. 1
        písm. e) alebo f) nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679. V takom prípade prevádzkovateľ
        nesmieme spracúvať osobné údaje, ak nepreukážeme nevyhnutné oprávnené záujmy na spracúvanie osobných údajov,
        ktoré prevažujú nad Vašimi právami alebo záujmami, alebo dôvody na uplatnenie právneho nároku; právo na
        prenosnosť osobných údajov (Vaše právo na prenosnosť osobných údajov upravuje § 26 zákona č. 18/2018 Z.z. o
        ochrane osobných údajov a o zmene a doplnení niektorých zákonov a čl. 20 nariadenia Európskeho parlamentu a Rady
        (EÚ) č. 2016/679), a to v štruktúrovanom, bežne používanom a strojovo čitateľnom formáte, pričom máte právo
        preniesť tieto osobné údaje inému prevádzkovateľovi, ak je to technicky možné a ak sa spracúvanie vykonáva
        automatizovanými prostriedkami a prebieha na základe právneho základu v zmysle § 13 ods. 1 písm. a) alebo písm.
        b) zákona č. 18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov, resp. čl. 6 ods. 1
        písm. a) alebo písm. b) nariadenia Európskeho parlamentu a Rady (EÚ) č. 2016/679, t.j. ak spracúvame osobné
        údaje na základe Vášho súhlasu alebo z dôvodu plnenia zmluvy, prípadne zmluvnej povinnosti.
      </p>
      <h3>Musíte nám Vaše osobné údaje poskytnúť?</h3>
      <p>
        Vaše osobné údaje ste nám poskytli dobrovoľne na základe Vášho slobodného súhlasu, pričom ste neboli povinní nám
        takýto súhlas so spracúvaním osobných údajov udeliť.
      </p>
      <h3>Budeme Vaše osobné údaje prenášať do tretej krajiny?</h3>
      <p>
        Realizácia nami poskytovaných služieb vyžaduje spoluprácu s už spomenutými spoločnosťami. To znamená, že Vaše
        osobné údaje môžu byť prenesené do tretích krajín. K predávaniu však vždy dochádza v súlade s legislatívnymi
        požiadavkami, ktoré na tento prenos kladie zákon č. 18/2018 Z.z. o ochrane osobných údajov a o zmene a doplnení
        niektorých zákonov a nariadenie Európskeho parlamentu a Rady (EÚ) č. 2016/679, pričom je v každom prípade
        zabezpečená ochrana Vašich osobných údajov. Konkrétne sa jedná o servery, cloudové riešenia príslušných
        spoločností Google LLC, Hotjar Limited, Facebook Inc., Cloudflare, Inc.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
