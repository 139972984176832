import React, { useState, useEffect } from "react";
import "./style/adminPayment.css"; // Import the CSS file for styling
import { TextField, Button } from "@mui/material"; // New import statement
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface Payment {
  _id: string;
  userId: string;
  courseId: string;
  createdAt: Date;
  childrenId: string;
  amount: number;
  total: number;
  variableSymbol: string;
  paid: boolean;
  paidAt?: Date;
  dueDate: string;
  paymentMethod: string;
  note: string;
  paymentStatus: string;
  month: number;
  mailSent: boolean;
  invoiceCreated: boolean;
  sessionId: string;
}

interface AdminPaymentProps {
  userId: string;
}


const AdminPayment = ({userId}: AdminPaymentProps) => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [paymentStatusFilter, setPaymentStatusFilter] = useState<string>("all");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editedPayment, setEditedPayment] = useState<Payment | null>(null);
  const [variableSymbol, setVariableSymbol] = useState<string>(""); // New code
  const [variableSymbol2, setVariableSymbol2] = useState<string>(""); // New code
  const[ price,setPrice] = useState<string>(""); // New code
  const [paidStatus, setPaidStatus] = useState<boolean>(false); // New code
  const [paymentMethod, setPaymentMethod] = useState<string>(""); // New code
  const { token } = useAuth();

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPaymentStatusFilter(event.target.value);
  };

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchPayments = async () => {
      if (!token) {
        return;
      }
      if (!userId) {
        return;
      }
      
      try {
        const response = await fetch(url + "payment/allPayments",   {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        const sortedPayments = data.payments.sort((a: Payment, b: Payment) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        });
        setPayments(sortedPayments);      } catch (error) {
        console.error("Error fetching payments:", error);
      }
    };

    fetchPayments();
  }, [token, url, userId]);

  const filteredPayments = payments.filter((payment) => {
    if (paymentStatusFilter === "all") {
      return true;
    } else if (paymentStatusFilter === "paid") {
      return payment.paid;
    } else {
      return !payment.paid;
    }
  });

  const editPayment = (paymentId: string) => {
    const payment = payments.find((payment) => payment._id === paymentId);
    setIsEditing(true);
    setEditedPayment(payment || null);
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setEditedPayment(null);
  };

  const saveChanges = async () => {
    console.log("editedPayment", editedPayment);
    if (editedPayment) {
      try {
        const response = await fetch(url + `payment/${editedPayment._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
          body: JSON.stringify(editedPayment),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        setIsEditing(false);
      } catch (error) {
        console.error("Error updating payment:", error);
      }
    }
  };

  const deletePayment = async (paymentId: string) => {
    try {
      const response = await fetch(url + `payment/${paymentId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      console.log("data", data);
      setPayments(payments.filter((payment) => payment._id !== paymentId));
    } catch (error) {
      console.error("Error deleting payment:", error);
    }
  };

  const editPaymentByVariableSymbol = async () => {
    // New function
    console.log(paidStatus)
    try {
      const response = await fetch(`${url}payment/variableSymbol/${variableSymbol}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
        body: JSON.stringify({ variableSymbol, paid: paidStatus, paymentMethod: paymentMethod }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      console.log("Updated payment data:", data);

      // Refresh the payments list
      const updatedPayments = payments.map((payment) =>
        payment.variableSymbol === variableSymbol ? { ...payment, paid: paidStatus, paymentMethod } : payment
      );
      setPayments(updatedPayments);
    } catch (error) {
      console.error("Error updating payment:", error);
    }
  };

  const editPaymentPriceByVariableSymbol = async () => {
    try {
      const response = await fetch(`${url}payment/variableSymbol/editPrice/${variableSymbol2}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
        body: JSON.stringify({ total:price }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      console.log("Updated payment data:", data);

      // Refresh the payments list
      const updatedPayments = payments.map((payment) =>
        payment.variableSymbol === variableSymbol ? { ...payment, paid: paidStatus, paymentMethod } : payment
      );
      setPayments(updatedPayments);
    } catch (error) {
      console.error("Error updating payment:", error);
    }
  };

  const downloadInvoices = async () => {
    const url2 =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
    try {
      const response = await fetch(url2 + "invoice/download-all-invoices",
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
      }); 
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'invoices.zip';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();    
      a.remove();  //afterwards we remove the element again         
    } catch (error) {
      console.error("Error downloading invoices:", error);
    }
  };  

  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <>
      <div className="update-payment-section">
        <h3>Update Payment Status</h3>
        <TextField
          label="Variable Symbol"
          value={variableSymbol}
          onChange={(e) => setVariableSymbol(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        <TextField
          select
          label="Paid"
          value={paidStatus}
          onChange={(e) => setPaidStatus(JSON.parse(e.target.value))}
          SelectProps={{ native: true }}
          style={{ marginRight: "10px" }}
        >
          <option value={"true"}>Yes</option>
          <option value={"false"}>No</option>
        </TextField>
        <TextField
          select
          label="Payment Method"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          SelectProps={{ native: true }}
          style={{ marginRight: "10px" }}
          defaultValue={""}
        >
          <option value={"cash"}>Cash</option>
          <option value={"bank"}>Bank</option>
          <option value={"card"}>Card</option>
          <option value={"crypto"}>Crypto</option>
        </TextField>
        <Button variant="contained" color="primary" onClick={editPaymentByVariableSymbol}>
          Update Payment
        </Button>
      </div>

      <div className="update-payment-section">
        <h3>Update Price of payment by VariableSymbol</h3>
        <TextField
          label="Variable Symbol"
          value={variableSymbol2}
          onChange={(e) => setVariableSymbol2(e.target.value)}
          style={{ marginRight: "10px" }}
        />
         <TextField
          label="Price"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          style={{ marginRight: "10px" }}
        />

        <Button variant="contained" color="primary" onClick={editPaymentPriceByVariableSymbol}>
          Update Payment Price
        </Button>
      </div>

      <div className="download-invoices-section">
        <Button variant="contained" color="primary" onClick={downloadInvoices}>
          Download all invoices
        </Button>
      </div>
      <div className="admin-payment-container">
        <h2>Admin Payment</h2>
        <div className="filter-section">
          <label htmlFor="filterSelect">Filter by Payment Status: </label>
          <select id="filterSelect" value={paymentStatusFilter} onChange={handleFilterChange}>
            <option value="all">All Payments</option>
            <option value="paid">Paid Payments</option>
            <option value="unpaid">Unpaid Payments</option>
          </select>
        </div>
        {isEditing && editedPayment ? (
          <div>
            <label htmlFor="userId">amount of attendance: </label>
            <input
              type="text"
              value={editedPayment.amount}
              onChange={(e) => setEditedPayment({ ...editedPayment, amount: parseFloat(e.target.value) })}
            />
            <label htmlFor="courseId">total payment: </label>
            <input
              type="text"
              value={editedPayment.total}
              onChange={(e) => setEditedPayment({ ...editedPayment, total: parseFloat(e.target.value) })}
            />
            <label htmlFor="DueDate">Due Date: </label>
            <input
              type="date"
              value={editedPayment.dueDate}
              onChange={(e) => setEditedPayment({ ...editedPayment, dueDate: e.target.value })}
            />
            {/* Add other fields here */}
            <button onClick={saveChanges}>Save Changes</button>
            <button onClick={cancelEdit}>Cancel</button>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Course ID</th>
                <th>Children ID</th>
                <th>Amount</th>
                <th>Total</th>
                <th>Variable Symbol</th>
                <th>Paid</th>
                <th>Due Date</th>
                <th>Payment Method</th>
                <th>Payment Status</th>
                <th>Month</th>
                <th>Mail Sent</th>
                <th>Invoice Created</th>
                <th>Session ID</th>
                <th>Edit Payment</th>
                <th>Delete Payment</th>
              </tr>
            </thead>
            <tbody>
              {filteredPayments.map((payment) => (
                <tr key={payment._id} className={payment.paid ? "paid-payment" : "unpaid-payment"}>
                  <td>{payment.userId}</td>
                  <td>{payment.courseId}</td>
                  <td>{payment.childrenId}</td>
                  <td>{payment.amount}</td>
                  <td>{payment.total}</td>
                  <td>{payment.variableSymbol}</td>
                  <td>{payment.paid ? "Yes" : "No"}</td>
                  <td>{payment.dueDate}</td>
                  <td>{payment.paymentMethod}</td>
                  <td>{payment.paymentStatus}</td>
                  <td>{payment.month}</td>
                  <td>{payment.mailSent ? "Yes" : "No"}</td>
                  <td>{payment.invoiceCreated ? "Yes" : "No"}</td>
                  <td>{payment.sessionId}</td>
                  <td>
                    <button className="edit-button" onClick={() => editPayment(payment._id)}>
                      Edit
                    </button>
                  </td>
                  <td>
                    <button className="delete-button" onClick={() => deletePayment(payment._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default AdminPayment;
