import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./ChildUserDetails.css";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";

interface Child {
  _id: string;
  name: string;
  schoolId: string;
  courseId: string;
  userId: string;
  createdAt: Date;
  className: string;
  isAttending: boolean;
  firstPayment: boolean;
  nextPayment: number;
  stopPayment: boolean;
  stopPaymentDate: Date;
  stopPaymentReason: string;
}
interface ChildUserDetailsProps {
  userId: string;
}

const ChildUserDetails = ({userId}: ChildUserDetailsProps) => {
  const { childrenId } = useParams();
  const [childDetails, setChildDetails] = useState<Child | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { token } = useAuth();

  let url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    fetch(url + `children/${childrenId}`,  {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-User-Id": userId // custom header for user ID
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setChildDetails(data.child);
      });
  }, [childrenId, token, url, userId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    await fetch(url + `children/${childrenId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "X-User-Id": userId, // custom header for user ID
      },
      body: JSON.stringify(childDetails),
    });
    setIsEditing(false);
    setShowSuccess(true);
  };

  if (!childDetails) return null;

  const handlCancel = () => {
    setIsEditing(false);
  }

  if(!userId) {
    return <NotLoggedIn />;
  }

  return (
    <div className="child-user-details">
      <h1>Detaily dieťata</h1>
      {isEditing ? (
        <>
          <label>Meno</label>
          <input
            value={childDetails.name}
            onChange={(e) => setChildDetails({ ...childDetails, name: e.target.value })}
          />
          <label>Trieda</label>
          <input
            value={childDetails.className}
            onChange={(e) => setChildDetails({ ...childDetails, className: e.target.value })}
          />
        </>
      ) : (
        <>
          <p>
            {" "}
            <label>Meno: </label>
            {childDetails.name}
          </p>
          <p>
            <label>Trieda: </label>
            {childDetails.className}
            {childDetails.stopPaymentReason && childDetails.stopPaymentReason !== "" ? (
                <p>
                    <label>Dovod prerusenia: </label>
                    {childDetails.stopPaymentReason}
                </p>
            ) : null}
          </p>
        </>
      )}
      <button onClick={isEditing ? handleSave : handleEdit}>{isEditing ? "Uložiť" : "Upraviť"}</button>
      {isEditing ? <button onClick={handlCancel}>Späť</button> : null}
      {showSuccess && <p className="success-message">Údaje o deťati boli úspešne aktualizované!</p>}
    </div>
  );
};

export default ChildUserDetails;
