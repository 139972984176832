import { useState, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";

function Register(): JSX.Element {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const handleRegister = async (e: FormEvent) => {
    e.preventDefault();

    // verify email
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      setMessage("Please enter a valid email.");
      setShowPopup(true);
      return;
    }

    // verify password
    if (password.length < 6) {
      setMessage("Password must be at least 6 characters.");
      setShowPopup(true);
      return;
    }

    console.log('test')
    let url2 = process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
    console.log(url2)
    console.log('env',  process.env.REACT_APP_ENVIRONMENT)
    console.log('prod', process.env.REACT_APP_PROD_URL)
    console.log('dev', process.env.REACT_APP_DEV_URL)


    try {
      let url = process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
      url = url + "auth/register"
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const res = await response.json();
      if (response.ok) {
        setIsSuccess(true);
        setShowPopup(true);
        setEmail("");
        setPassword("");
        setMessage("");
      } else {
        setIsSuccess(false);
        setShowPopup(true);
        setMessage(res.msg);
      }
    } catch (error) {
      setMessage("Something went wrong. Please try again.");
      console.error(error);
    }
  };

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();

    // Navigate to the Login component
    navigate("/login");
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
        setIsSuccess(false);
        if (isSuccess) {
          navigate("/login");
        }
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [showPopup, navigate, isSuccess]);

  // after 2 second empty the message
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [message]);

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleRegister}>
        <h2>Registrácia</h2>
        <input
          className="login-input"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Mail"
          type="text"
        />
        <input
          className="login-input"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Heslo"
          type="password"
        />
        <button className="login-button" type="submit">
          Registrovať sa
        </button>
      </form>
      {message && (
        <div className="login-popup error">
          <p>{message}</p>
        </div>
      )}
      <div className="login-options">
        <p>
          Máte už účet?{" "}
          <button className="login-link" onClick={handleLogin}>
            Prihlásiť sa
          </button>
        </p>
      </div>
      {showPopup && (
        <div className={`login-popup ${isSuccess ? "success" : "error"}`}>
          {message ? <p>{message}</p> : <p></p>}
          {!message ? 
          <p>{isSuccess ? "Registráciaá!" : "Registrácia neúspešná. Skúste znova."}</p>
          : <p></p>}
        </div>
      )}
    </div>
  );
}

export default Register;
