import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
// import { FiCreditCard } from "react-icons/fi";
import NotLoggedIn from "../Login/NotLoggedIn";
import { getMonthName, formatDate } from "../../utils/date";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import QRCode from "qrcode.react";
import { useAuth } from "../Auth/AuthContext";
import { Box } from "@material-ui/core";

interface PaymentData {
  _id: string;
  userId: string;
  courseId: string;
  createdAt: Date;
  childrenId: string;
  amount: number;
  total: number;
  variableSymbol: string;
  paid: boolean;
  paidAt: string;
  dueDate: string;
  paymentMethod: string;
  note: string;
  paymentStatus: string;
  month: number;
  mailSent: boolean;
  invoiceCreated: boolean;
  sessionId: string;
  url: string;
  childrenName: string;
}

interface PaymentsProps {
  userId: string;
}

// interface ChildrenData {
//   childName: string;
//   className: string;
//   courseTime: string;
//   schoolName: string;
// }

const Payments = ({ userId }: PaymentsProps) => {
  const [paymentData, setPaymentData] = useState<PaymentData[]>([]);
  const [stripeUrl, setStripeUrl] = useState<string>("");

  const [open, setOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<PaymentData | null>(null);
  const { token } = useAuth();
  const [username, setUsername] = useState<string>("");
  const [isDisabledPayment, setIsDisabledPayment] = useState<boolean>(false);

  let url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
  let redirectUrl =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL_STRIPE : "http://localhost:3000";

  useEffect(() => {
    if (userId !== "") {
      fetch(url + `payment/user/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPaymentData(data.payments);
        })
        .catch((error) => console.error(`There was an error retrieving the payment data: ${error}`));
    }
  }, [token, url, userId]);

  const handlePayment = async (_id: string) => {
    // redirect to the payment url
    if (!_id) {
      return;
    }
    //get total amount of payment by _id from paymentData
    let totalPayment = paymentData.find((payment) => payment._id === _id)?.total;
    totalPayment = totalPayment && totalPayment * 100;
    let variableSymbol = paymentData.find((payment) => payment._id === _id)?.variableSymbol;
    let userIdLocal = paymentData.find((payment) => payment._id === _id)?.userId;
    let courseId = paymentData.find((payment) => payment._id === _id)?.courseId;
    let childrenId = paymentData.find((payment) => payment._id === _id)?.childrenId;

    try {
      let body = {
        amount: totalPayment,
        currency: "EUR",
        description: "description",
        successUrl: `${redirectUrl}/#/success?paymentId=${_id}&session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${redirectUrl}/#/cancel?paymentId=${_id}&session_id={CHECKOUT_SESSION_ID}`,
        metadata: {
          paymentId: _id,
          variableSymbol: variableSymbol,
          userId: userIdLocal,
          courseId: courseId,
          childrenId: childrenId,
        },
      };

      if (!token) {
        return;
      }

      if (!userId) {
        return;
      }

      const stripePayment = await fetch(url + `stripe/stripePay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
        body: JSON.stringify({
          ...body,
        }),
      });
      const stripePaymentJson = await stripePayment.json();
      setStripeUrl(stripePaymentJson.url);
    } catch (error) {
      console.error(`There was an error retrieving the payment data: ${error}`);
    }
  };

  const handlePaymentDownload = async (variableSymbol: string, courseId: string) => {
    if (!token) {
      return;
    }

    if (!userId) {
      return;
    }

    let url3 =
      process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
    // get course by courseId and find oyth wheter paymentActive is true or false
    const course = await fetch(url3 + `course/${courseId}`, {
      method: "GET",
      headers: {
        "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
      },
    });
    const courseJson = await course.json();
    const paymentActive = courseJson.course.paymentActive;

    // if paymentActive is false, download invoice else disable download button
    if (paymentActive === false) {
      setIsDisabledPayment(true);
    } else {
      setIsDisabledPayment(false);
    }

    let url2 =
      process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
    const response = await fetch(url2 + `invoice/download-invoice?variableSymbol=${variableSymbol}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "X-User-Id": userId, // custom header for user ID
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Assuming the server returns the invoice as a blob
    const blob = await response.blob();

    // Create a link element, and trigger a download
    let url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = `${variableSymbol}-faktura.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handlePaymentByBankTransfer = async (_id: string) => {
    if (!_id) {
      return;
    }
    const selectedPayment = paymentData.find((payment) => payment._id === _id);
    setSelectedPayment(selectedPayment ?? null);
    setOpen(true);
  };

  const fetchPaymentData = useCallback(() => {
    if (userId !== "") {
      fetch(url + `payment/user/${userId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPaymentData(data.payments);
        })
        .catch((error) => console.error(`There was an error retrieving the payment data: ${error}`));
    }
  }, [userId, url, token]);

  const editPaymentStatus = async (paymentId: string | undefined, status: string) => {
    try {
      const response = await fetch(url + `payment/${paymentId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
        body: JSON.stringify({
          paymentStatus: status,
        }),
      });
      const responseJson = await response.json();
      if (responseJson.success) {
        // Fetch payment data again
        fetchPaymentData();
      }
      // TODO send email to admin
    } catch (error) {
      console.error(`There was an error retrieving the payment data: ${error}`);
    }
  };

  useEffect(() => {
    fetchPaymentData();
  }, [fetchPaymentData]);

  useEffect(() => {
    if (stripeUrl !== "") {
      window.location.href = stripeUrl;
    }
  }, [stripeUrl]);

  // get userName by userId
  const getUserName = (userId: string) => {
    if (userId !== "") {
      fetch(url + `user/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setUsername(data.name + " " + data.surname);
        })
        .catch((error) => console.error(`There was an error retrieving the user data: ${error}`));
    }
  };

  const generateBankTransferData = (payment: PaymentData) => {
    const IBAN = "SK4611000000002947153877";
    const variableSymbol = payment.variableSymbol ? payment.variableSymbol : "";

    const total = payment.total ? payment.total.toFixed(2) : ""; // Assuming payment.total is a number

    getUserName(payment.userId);
    const message = " Mladi programatori " + (username ? username : "");

    return `SPD*1.0*ACC:${IBAN}*AM:${total}*CC:EUR*X-VS:${variableSymbol}*MSG:${message}`;
  };

  return (
    <>
      {userId !== "" ? (
        <Card>
          <CardContent>
            {/* <FiCreditCard size={64} color="#A53F29" /> */}
            <Typography variant="h5" component="h2" sx={{ mt: 2, textAlign: "center" }}>
              Zoznam všetkých platieb a vystavených faktúr.
            </Typography>
            <Typography variant="body2" sx={{ mt: 2, color: "orange" }}>
              Upozornenie:{" "}
              <p>
                Prvá platba obsahuje platbu za aktuálny a nasledujúci mesiac s predpokladom 100% účasti dieťaťa na
                krúžkoch. Jeho absencia bude zohľadnená v ďalšej platbe.
              </p>
              <p>Po ukončení krúžku budú vzniknuté preplatky vrátené ako Dobropis.</p>
            </Typography>
            <TableContainer component={Paper} sx={{ mt: 3 }}>
              <Table>
                <TableHead>
                  <TableRow>
                  <TableCell>Akcia</TableCell>
                    <TableCell>Dieťa</TableCell>
                    <TableCell>Mesiac</TableCell>
                    <TableCell>Variabilný symbol</TableCell>
                    <TableCell>Zaplatiť do </TableCell>
                    <TableCell>Suma </TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Spôsob</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!paymentData || paymentData.length === 0 ? <p>Žiadne platby</p> : null}
                  {paymentData &&
                    paymentData.map((payment) => (
                      <TableRow key={payment.month}>
                        <TableCell>
                          {payment.paid ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => handlePaymentDownload(payment.variableSymbol, payment.courseId)}
                            >
                              Sťiahnuť faktúru
                            </Button>
                          ) : payment.paymentStatus && payment.paymentStatus === "prevod" && payment.paid === false ? (
                            <p>Čaká sa na potvrdenie platby prevodom</p>
                          ) : (
                            <>
                              {/* TODO add for each payments isDisabled */}
                              {isDisabledPayment ? (
                                <p style={{ fontSize: "12px" }}>
                                  Z dôvodu neúplnej obsadenosti kurzy platba momentálne nie je možná.
                                  <p style={{ fontSize: "12px" }}>O zmenách Vás budeme informovať e-mailom.</p>
                                </p>
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    color="warning"
                                    sx={{ mr: 1 }}
                                    disabled={isDisabledPayment}
                                    onClick={() => handlePayment(payment._id)}
                                  >
                                    Platba kartou
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="info"
                                    disabled={isDisabledPayment}
                                    onClick={() => handlePaymentByBankTransfer(payment._id)}
                                  >
                                    Platba prevodom
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell>{payment.childrenName} </TableCell>
                        <TableCell>{getMonthName(payment.month)}</TableCell>
                        <TableCell>{payment.variableSymbol}</TableCell>
                        <TableCell>{formatDate(payment.dueDate)}</TableCell>
                        <TableCell>{payment.total} €</TableCell>
                        <TableCell>
                          {payment.paymentStatus && payment.paymentStatus === "prevod" && payment.paid === false
                            ? payment.paymentStatus
                            : payment.paid
                            ? "Zaplatené - " + (payment?.paidAt ? formatDate(payment?.paidAt) : null)
                            : "Nezaplatené"}
                        </TableCell>
                        <TableCell>{payment.paymentMethod ? payment.paymentMethod : " - "}</TableCell>
                        
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Podrobnosti o bankovom prevode"}</DialogTitle>
              <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                  <Typography>IBAN: SK46 1100 0000 0029 4715 3877</Typography>
                  <Typography>Variabilný symbol: {selectedPayment?.variableSymbol}</Typography>
                  <Typography>
                    Dátum splatnosti: (najneskôr) {selectedPayment && formatDate(selectedPayment.dueDate)}
                  </Typography>
                  <Typography>Zaplatiť: {selectedPayment?.total} €</Typography>
                  {selectedPayment !== null && selectedPayment !== undefined && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "1em",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      <QRCode value={generateBankTransferData(selectedPayment)} size={150} />
                    </div>
                  )}
                  <Typography color="textSecondary" style={{ marginTop: "1em" }}>
                    Poznámka: Platba prevodom môže trvať 1-3 pracovných dní.
                  </Typography>
                  <Typography style={{ marginTop: "1em", color: "#FF9800" }}>
                    Prosim, kliknite na tlačítko "Zaplatil som prevodom" pre jednoduchšie sledovanie platby.
                  </Typography>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                  Zavrieť
                </Button>
                <Button
                  onClick={() => {
                    setOpen(false);
                    editPaymentStatus(selectedPayment?._id, "prevod");
                  }}
                  color="primary"
                  autoFocus
                >
                  Zaplatil som prevodom
                </Button>
              </DialogActions>
            </Dialog>
          </CardContent>
        </Card>
      ) : (
        <NotLoggedIn />
      )}
    </>
  );
};

export default Payments;
