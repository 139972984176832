import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  TextField,
  Checkbox,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import "./LectorAttendance.css";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import { Grid } from "@mui/material";

interface Student {
  _id: string;
  name: string;
  status: string; // "attended", "missed", "ill"
  className: string;
}

interface Course {
  _id: string;
  time: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolName: string;
  lectorName: string;
  monthPayment: number;
  capacity: number;
  schoolId: string;
  childrens: Student[];
  attendIds: string[];
}

interface LectorAttendanceProps {
  lectorId: string;
}

const LectorAttendance: React.FC<LectorAttendanceProps> = ({ lectorId }) => {
  const [openCreateAttendance, setOpenCreateAttendance] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [lectorCourses, setLectorCourses] = useState<Course[]>([]);
  const [childrensInCourse, setChildrensInCourse] = useState<Student[]>([]);
  const [fetchedLectorId, setFetchedLectorId] = useState<string>("");
  const [selectedCourse, setSelectedCourse] = useState<Course>();
  const [note, setNote] = useState<string>("");
  const [courseHeld, setCourseHeld] = useState<boolean>(true);
  const { token } = useAuth();

  interface Temp {
    childreId: string;
    status: string;
  }
  let temp: Temp[] = [];

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    // get lectorId from userId

    const fetchLectorId = async () => {
      if (!lectorId) {
        return;
      }
      try {
        const response = await fetch(url + `lector/user/${lectorId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": lectorId, // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setFetchedLectorId(data._id);
      } catch (error) {
        console.error("Error fetching lector id:", error);
      }
    };

    fetchLectorId();
    // fetch lector courses
    const fetchLectorCourses = async () => {
      if (!lectorId) {
        return;
      }
      try {
        const response = await fetch(url + `course/getLectorsCourses/${lectorId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": lectorId, // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setLectorCourses(data.lectorCourses);
      } catch (error) {
        console.error("Error fetching lector courses:", error);
      }
    };

    fetchLectorCourses();
  }, [lectorId, token, url]);

  const handleCreateAttendance = async (students: Student[]) => {
    setOpenCreateAttendance(true);
  };

  const handleSaveAttendance = async () => {
    // attended is array of studentIds that attended
    let attended: string[] = temp.reduce((accumulator: string[], student) => {
      if (student.status === "attended") {
        accumulator.push(student.childreId);
      }
      return accumulator;
    }, []);

    let absent: string[] = temp.reduce((accumulator: string[], student) => {
      if (student.status === "missed") {
        accumulator.push(student.childreId);
      }
      return accumulator;
    }, []);

    let apologies: string[] = temp.reduce((accumulator: string[], student) => {
      if (student.status === "ill") {
        accumulator.push(student.childreId);
      }
      return accumulator;
    }, []);

    const updatedNote = note + ". týždeň";

    if (!courseHeld) {
      apologies = childrensInCourse.reduce((accumulator: string[], student) => {
        accumulator.push(student._id);
        return accumulator;
      }, []);
    }

    const body = {
      courseId: selectedCourse?._id,
      lectorId: fetchedLectorId,
      attended: attended,
      absent: absent,
      apologies: apologies,
      note: updatedNote,
      held: courseHeld,
    };

    try {
      const response = await fetch(url + "attend/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": lectorId, // custom header for user ID
        },
        body: JSON.stringify(body),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      await response.json();
      setSnackbarOpen(false);
    } catch (error) {
      console.error("Error fetching lector courses:", error);
    }

    // empty temp array
    temp = [];
    absent = [];
    apologies = [];
    attended = [];
    setOpenCreateAttendance(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleStatusChange = (studentId: string, status: string) => {
    // push to temp array
    temp.push({
      childreId: studentId,
      status: status,
    });
  };

  const setFilteredCourse = async (course: Course) => {
    setSelectedCourse(course);
    try {
      const response = await fetch(url + `course/getChildrensInCourse/${course._id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": lectorId, // custom header for user ID
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setChildrensInCourse(data.childrensInCourse);
      handleCreateAttendance(data.childrensInCourse);
      // when childrensInCourse className is for example II.B than move to 2.B
      // order childrensInCourse by className from A to Z
      setChildrensInCourse((prev) => {
        return prev.sort((a, b) => (a.className > b.className ? 1 : -1));
      });
    } catch (error) {
      console.error("Error fetching course childrens:", error);
    }
  };

  const handleSnackbarOpen = () => {
    setOpenCreateAttendance(false);
    setCourseHeld(true);
  };

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="lector-attendance-container">
      <h3>Kliknutím na kurz vytvoríte dochádzku</h3>
      <Grid container spacing={2} sx={{ padding: '0 2rem' }}>
        {lectorCourses.map((course) => (
          <Grid key={course._id} item xs={12} sm={6} md={4} lg={3}>
            <Button variant="contained" onClick={() => setFilteredCourse(course)} fullWidth>
              {course.schoolName} - {course.language} - {course.time}
            </Button>
          </Grid>
        ))}
      </Grid>

      <Dialog open={openCreateAttendance} onClose={() => handleSnackbarOpen}>
        <DialogTitle>Vytvorenie dochádzky</DialogTitle>
        <DialogContent>
          <label htmlFor="courseHeld">Kruzok sa konal</label>
          <Checkbox aria-label="Course held" onChange={(e) => setCourseHeld(e.target.checked)} defaultChecked />
          <TextField label="Týždeň (číslo)" variant="outlined" onChange={(e) => setNote(e.target.value)} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Meno študenta</TableCell>
                <TableCell>Trieda študenta</TableCell>
                <TableCell>Stav dochádzky</TableCell>
              </TableRow>
            </TableHead>
            {courseHeld && courseHeld ? (
              <TableBody>
                {childrensInCourse.map((student) => (
                  <TableRow key={student._id}>
                    <TableCell>{student.name}</TableCell>
                    <TableCell>{student.className}</TableCell>
                    <TableCell>
                      <select onChange={(e) => handleStatusChange(student._id, e.target.value)}>
                        <option value="Select Attendance">Vyberte dochádzku</option>
                        <option value="attended">Zúčastnil sa</option>
                        <option value="missed">Nezúčastnil sa</option>
                        <option value="ill">Ospravedlnené</option>
                      </select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : null}
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCreateAttendance(false)}>Spať</Button>
          <Button onClick={handleSaveAttendance}>Uložiť</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="success">
        Účasť bola úspešne aktualizovaná!
                </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default LectorAttendance;
