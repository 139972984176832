import React, { useState, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";
import "./ForgotPassword.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  let url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  const handleForgotPassword = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch(url + "auth/forgot-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        await response.json();
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }

      setShowPopup(true);
    } catch (error) {
      console.error("Error:", error);
      setIsSuccess(false);
      setShowPopup(true);
    }

    // Reset the form
    setEmail("");
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
        setIsSuccess(false);
        navigate("/login");
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [navigate, showPopup]);

  return (
    <>
      <div className="login-container">
        <form className="login-form" onSubmit={handleForgotPassword}>
          <h2>Zabudnuté heslo</h2>
          <input
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Zadajte mail"
            type="text"
          />
          <button className="login-button" type="submit">
            Resetovať heslo
          </button>
        </form>
        {/* {showPopup && (
          <div className={`login-popup ${isSuccess ? "success" : "error"}`}>
            <p>{isSuccess ? "Password reset email sent!" : "Failed to reset password. Please try again."}</p>
          </div>
        )} */}
      </div>
      {showPopup && (
        <div className={`login-popup ${isSuccess ? "success" : "error"}`}>
          <p>
            {isSuccess
              ? "Navštívte svoj email, odoslaný je odkaz na resetovanie hesla. Ak odkaz nevidíte, prosím skontrolujte si aj SPAMAkcia!"
              : "Nepodarilo sa resetovať heslo. Skúste to prosím znova."}
          </p>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
