import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import "./LectorDetails.css"; // Import the CSS file for styling
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface Lector {
  _id: string;
  userId: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  phoneNumber: string;
  iban: string;
  courseId: string[];
  salary: number;
  town: string;
}

const LectorDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [lector, setLector] = useState<Lector | null>(null);
  const [editedLector, setEditedLector] = useState<Lector | null>(null);
  const navigate = useNavigate();
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchLector = async () => {
      try {
        const response = await fetch(url + `lector/${id}`);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setLector(data);
      } catch (error) {
        console.error("Error fetching lector details:", error);
      }
    };
    fetchLector();
  }, [url, id]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (lector) {
      setEditedLector({ ...lector, [name]: value });
    }
  };

  const handleSaveChanges = async (): Promise<void> => {
    if (editedLector) {
      try {
        if (!lector || !editedLector) {
          return;
        }
        const response = await fetch(url + `lector/${lector._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editedLector),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        // If the PUT request was successful, update the lector state
        setLector(editedLector);
        setEditedLector(null);
      } catch (error) {
        console.error("Error updating lector:", error);
      }
    }
  };

  const handleAddLector = async (): Promise<void> => {
    // try {
    //   const response = await fetch(url + "lector", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(editedLector), // Assuming editedLector contains the new lector data
    //   });
    //   if (!response.ok) {
    //     throw new Error("Network response was not ok.");
    //   }
    //   const data = await response.json();
    //   setLector(data);
    // } catch (error) {
    //   console.error("Error adding lector:", error);
    // }
    console.log('akoze zmažem lektora')
  };

  const handleDeleteLector = async (): Promise<void> => {
    if (lector) {
      try {
        const response = await fetch(url + `lector/${lector._id}`, {
          method: "DELETE",
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        navigate("/"); // Navigate back to the home page or any other desired page after deletion
      } catch (error) {
        console.error("Error deleting lector:", error);
      }
    }
  };

  const courseDetails = (courseId: string) => {
    // alert(`Course details for course with id ${courseId}`);
    navigate("/course/details/" + courseId);
  };

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="lector-details-container">
      {lector ? (
        <>
          {editedLector ? (
            <div className="lector-info-edit">
              <div className="lector-row">
                <label className="label">Name:</label>
                <input type="text" name="name" value={editedLector.name} onChange={handleInputChange} />
              </div>
              <div className="lector-row">
                <label className="label">Surname:</label>
                <input type="text" name="surname" value={editedLector.surname} onChange={handleInputChange} />
              </div>
              <div className="lector-row">
                <label className="label">Email:</label>
                <input type="text" name="email" value={editedLector.email} onChange={handleInputChange} />
              </div>
              <div className="lector-row">
                <label className="label">Phone number:</label>
                <input type="text" name="phoneNumber" value={editedLector.phoneNumber} onChange={handleInputChange} />
              </div>
              <div className="lector-row">
                <label className="label">Iban:</label>
                <input type="text" name="iban" value={editedLector.iban} onChange={handleInputChange} />
              </div>
              <div className="lector-row">
                <label className="label">Salary:</label>
                <input type="text" name="salary" value={editedLector.salary} onChange={handleInputChange} />
              </div>
              <div className="lector-row">
                <label className="label">Town:</label>
                <input type="text" name="town" value={editedLector.town} onChange={handleInputChange} />
              </div>
              <div className="lector-row">
                <label className="label">CourseId:</label>
                <input type="text" name="courseId" value={editedLector.courseId} onChange={handleInputChange} />
              </div>
              <button onClick={handleSaveChanges}>Save</button>
            </div>
          ) : (
            <>
              <div className="lector-info">
                <div className="lector-row">
                  <span className="label">Name:</span>
                  <span>{lector.name}</span>
                </div>
                <div className="lector-row">
                  <span className="label">Surname:</span>
                  <span>{lector.surname}</span>
                </div>
                <div className="lector-row">
                  <span className="label">Email:</span>
                  <span>{lector.email}</span>
                </div>
                <div className="lector-row">
                  <span className="label">Phone number:</span>
                  <span>{lector.phoneNumber}</span>
                </div>
                <div className="lector-row">
                  <span className="label">Iban:</span>
                  <span>{lector.iban}</span>
                </div>
                <div className="lector-row">
                  <span className="label">Salary:</span>
                  <span>{lector.salary}</span>
                </div>
                <div className="lector-row">
                  <span className="label">Town:</span>
                  <span>{lector.town}</span>
                </div>

                {/* Add more non-editable fields here */}
                <button onClick={() => setEditedLector(lector)}>Edit Lector</button>
              </div>
              <div
                className="lector-courses"
                style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "20px" }}
              >
                <h3>Courses</h3>
                {lector.courseId.map((courseId) => (
                  <div key={courseId} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <span className="label">CourseId:</span>
                    <span>{courseId}</span>
                    <button onClick={() => courseDetails(courseId)}>Course details</button>
                  </div>
                ))}
              </div>
            </>
          )}
          <button onClick={handleAddLector}>Add New Lector</button>
          <button onClick={handleDeleteLector}>Delete Lector</button>
          <Link to="/">Go Back</Link>
        </>
      ) : (
        <div>Loading lector details...</div>
      )}
    </div>
  );
};

export default LectorDetails;
