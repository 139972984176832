// AttendCourse.tsx

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
// import { MdCheckCircle, MdCancel } from "react-icons/md";
import { useEffect, useState } from "react";
import "./AttendCourse.css"; // Import the CSS file
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";

interface CourseDataProps {
  userId: string;
  userData: any;
}

interface Child {
  courseName: string;
  schoolName: string;
  courseLanguage: string;
  _id: string;
  name: string;
  schoolId: string;
  courseId: string;
  userId: string;
  className: string;
  isAttending: boolean;
  firstPayment: boolean;
  nextPayment: number;
  createdAt: string;
  __v: number;
}

interface Course {
  _id: string;
  date: Date;
  courseId: string;
  childId: string;
  childName: string;
  attendStatus: string;
  note: string;
  held: boolean;
}

const AttendCourse = ({ userId, userData }: CourseDataProps) => {
  const [childrens, setChildrens] = useState<Child[]>([]);
  const [showDetails, setShowDetails] = useState<boolean>(false); // Fixed the variable name here
  const [fetchedCourseData, setFetchedCourseData] = useState<Course[]>([]); // State to store the attendance details
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchChildrenData = async () => {
      if (userId === "" || userId === undefined) {
        return;
      }
      try {
        const response = await fetch(url + "children/user/" + userId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setChildrens(data.childrens);
      } catch (error) {
        console.error("Error fetching children data:", error);
      }
    };

    fetchChildrenData();
  }, [token, url, userId]);

  const handleChildrenAttendanceDetails = async (childId: string) => {
    setShowDetails(true);
    try {
      const response = await fetch(url + "attend/child/" + childId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setFetchedCourseData(data.attendances); // Set the fetched attendance data to the state
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  return (
    <>
      {userId !== "" ? (
        <div className="attend-course-container">
          <div className="user-childrens-container">
            <Typography variant="h5" gutterBottom>
              Dochádzka detí
            </Typography>{" "}
            {childrens.length === 0 ? <p>Žiadne záznamy dochádzky</p> : null}
            <table className="children-table">
              <thead>
                <tr>
                  <th>Meno</th>
                  <th>Škola</th>
                  <th>Kurz</th>
                  <th>Programovací jayzk</th>
                  <th>Trieda</th>
                  {showDetails ? <th>Skryť detaily</th> : <th>Detaily docházky</th>}
                </tr>
              </thead>
              <tbody>
                {childrens &&
                  childrens.map((child) => (
                    <tr key={child._id}>
                      <td>{child.name}</td>
                      <td>{child.schoolName}</td>
                      <td>{child.courseName}</td>
                      <td>{child.courseLanguage}</td>
                      <td>{child.className}</td>
                      {showDetails ? (
                        <td>
                          <button className="details-button" onClick={() => setShowDetails(false)}>
                            Skriť detaily
                          </button>
                        </td>
                      ) : (
                        <td>
                          <button className="details-button" onClick={() => handleChildrenAttendanceDetails(child._id)}>
                            Detaily
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {showDetails ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Dátum</TableCell>
                    <TableCell>Týždeň</TableCell>
                    <TableCell>Stav dochádzky</TableCell>
                  </TableRow>
                </TableHead>
                {!fetchedCourseData || fetchedCourseData.length === 0 ? <p>Žiadne záznamy dochádzky</p> : null}
                {fetchedCourseData &&
                  fetchedCourseData.map((course) => (
                    <TableBody>
                      <TableRow key={course._id}>
                        <TableCell>{new Date(course.date).toLocaleDateString()}</TableCell>
                        <TableCell>{course.note}</TableCell>
                        <TableCell>
                          {course.held ? (
                            course.attendStatus ? (
                              <p>
                                {course.attendStatus === "attended"
                                  ? "Zúčastnený"
                                  : course.attendStatus === "apologies"
                                  ? "Ospravedlnený"
                                  : "Chýbal"}
                              </p>
                            ) : null
                          ) : (
                            <p>Kurz sa nekonal</p>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          ) : null}
        </div>
      ) : (
        <NotLoggedIn />
      )}
    </>
  );
};

export default AttendCourse;
