import React, { useEffect } from "react";
import "./TermsAndConditions.css";

const TermsAndConditions: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms-and-conditions">
      <h1>Všeobecné obchodné podmienky</h1>
      <h2>Čl. I.</h2>
      <h2>VŠEOBECNÉ USTANOVENIA</h2>
      <p>
        <strong>Dátum aktualizácie:</strong>  15.01.2024
      </p>
      <p>
        1) Identifikačné údaje: Obchodné meno: Mladí programátori s. r. o. Sídlo: Trieda Andreja Hlinku 606/39 Nitra 949
        01 IČO: 55 655 203 Zápis: v Obchodnom registri Okresného súdu Nitra, oddiel: Sro, vložka č. 61121/N Tel. č.:
        +421 940 014 553 e-mail: mladiprogramatori.cz@gmail.com DIČ: 2322051602 (ďalej ako „dodávateľ“)
      </p>
      <p>
        2) Osoba, ktorá má záujem alebo si objedná poskytovanie kurzu je primerane označovaná ako „záujemca o kurz“,
        „objednávateľ“, „spotrebiteľ“.
      </p>
      <p>
        3) Dodávateľ, ktorý je prevádzkovateľom webovej stránky www.mladiprogramatori.sk, prostredníctvom ktorej
        dochádza k prihlasovaniu na kurzy informatiky určené pre žiakov základných škôl navštevujúcich od 2. do 9.
        ročníka, vydáva nasledujúce Všeobecné obchodné podmienky (ďalej ako „VOP“) Tieto VOP sa vzťahujú na právne
        vzťahy uzatvárané medzi dodávateľom na jednej strane a spotrebiteľom na druhej strane (ďalej ako „spotrebiteľské
        zmluvy“). VOP sú súčasťou každej spotrebiteľskej zmluvy a súhlas s nimi je podmienkou jej uzavretia.
      </p>
      <p>4) Tieto VOP nadobúdajú účinnosť dňa 15.01.2024.</p>
      <p>
        5) Všetky zmluvné vzťahy sú v súlade s právnym poriadkom Slovenskej republiky v platnom znení a to najmä a nie
        výlučne zákonom č. 102/2014 Z. z. ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní služieb na základe
        zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho v znení neskorších
        predpisov, zákonom č. 250/2007 Z. z. o ochrane spotrebiteľa v znení neskorších predpisov, zákonom č. 40/1964 Zb.
        Občiansky zákonník v znení neskorších predpisov a ďalšími predpismi.
      </p>

      <h2>Čl. II.</h2>
      <h2>ZÁKLADNÉ INFORMÁCIE O NÁS</h2>
      <p>
        Projekt Mladí programátori s. r. o. poskytujú mimoškolskú vzdelávaciu činnosť, ktorá sa zameriava na kurzy
        programovania. Tie prebiehajú priamo na vybraných základných školách vo viacerých mestách po skončení riadneho
        vyučovania. Kurzy sú spoplatnené a sú určené pre tých žiakov, ktorí navštevujú danú základnú školu, na ktorej sa
        kurz uskutočňuje. Cena kurzu závisí od konkrétnych podmienok danej školy, konečná cena konkrétneho kurzu je
        uvedená priamo na webovej stránke dodávateľa www.mladiprogramatori.sk. Dodávateľ poskytuje kurzy programovania
        na základných školách určené pre žiakov základných škôl navštevujúcich od 2. do 9. ročníka, vo veku od 6 - 16
        rokov. Kurzy sa poskytujú polročne a toto obdobie sa primerane prekrýva s obdobím polrokov školského roka. Kurzy
        nie sú poskytované jednotlivo.
      </p>
      <p>2) Základný priebeh prihlasovania na kurz:</p>
      <p>
        a) Za účelom prihlásenia žiaka na kurz je potrebné zaregistrovať sa na webovej stránke dodávateľa
        www.mladiprogramatori.sk (ďalej ako „webová stránka dodávateľa“). Po úspešnej registrácií bude záujemcovi o kurz
        vytvorený používateľský účet. Za účelom registrácie účtu záujemca o kurz poskytne základné údaje, akými sú
        e-mail a vytvorí si používateľské heslo.
      </p>
      <p>
        b) Záujemca o kurz môže vyplniť doplňujúce údaje o svojom dieťati, ktoré má záujem prihlásiť na kurz za účelom
        prispôsobenia výberu vhodného kurzu vzhľadom na vek, záujmy a schopnosti dieťaťa.
      </p>
      <p>
        Vzhľadom na to, že výučba prebieha v niekoľkých mestách a školách, záujemca o kurz má možnosť zvoliť si vhodnú
        alternatívu. Kurzy sú však určené len pre tých žiakov, ktorí navštevujú danú základnú školu, na ktorej sa kurz
        vykonáva. Záujemca o kurz preto môže prihlásiť žiaka na len taký kurz, ktorý sa vykonáva na základnej škole,
        ktorú žiak navštevuje. Z uvedeného dôvodu záujemca o kurz pri prihlasovaní žiaka vyplní špecifický kód, ktorý mu
        bude poskytnutý danou základnou školou, ktorú žiak navštevuje. Tento kód bude poskytnutý priamo školou
        prostredníctvom e-mailu s ďalšími doplňujúcimi informáciami o prebiehajúcom kurze.
      </p>
      <p>
        d) V ďalšom kroku si záujemca o kurz vyberie druh programovacieho jazyka, v ktorom bude výučba vybraného kurzu
        prebiehať a to na škále od základných až po pokročilé programovacie jazyky. Je tak poskytnuté portfólio kurzov
        pre všetky vekové kategórie žiakov základnej školy.
      </p>

      <h2>Čl. III.</h2>
      <h2>SPÔSOB UZATVÁRANIA KÚPNEJ ZMLUVY</h2>
      <p>1) Kúpna zmluva, na základe ktorej dôjde k plneniu zo strany dodávateľa je uzatvorená nasledovným spôsobom:</p>
      <p>
        a) Prihlásenie na kurz je zabezpečené a možné výhradne formou objednávkového formulára, nachádzajúceho sa na
        webovej stránke dodávateľa.
      </p>
      <p>
        b) Záujemca o kurz vyberie z ponuky dostupných kurzov, s uvedeným predmetom služby – obsah výučby kurzu,
        uvedenou cenou, dĺžkou trvania kurzu a mieste, kde sa bude vykonávať. Záujemca o kurz v ďalšom kroku vyplní
        príslušné údaje podľa pokynov na webovej stránke dodávateľa prostredníctvom objednávkového formulára, v ktorom
        vyplní požadované údaje a zašle objednávku Táto objednávka zároveň predstavuje návrh na uzavretie zmluvy,
        predmetom ktorej je poskytovanie vybraného kurzu. Osobné údaje poskytované záujemcom o kurz budú spracúvané v
        súlade s čl. VIII týchto VOP.
      </p>
      <p>
        c) Záujemcom o kurz vyplnený a dodávateľovi doručený objednávkový formulár nie je pre dodávateľa a ani pre
        záujemcu o kurz v tejto fáze právne záväzný. Doručením vyplneného objednávkového formulára dodávateľovi sa
        začína proces uzavretia zmluvy uzavretej medzi dodávateľom a záujemcom o kurz.
      </p>
      <p>
        d) Dodávateľ po doručení návrhu zašle potvrdzujúci e-mail záujemcovi o kurz, ktorým dodávateľ potvrdí doručenie
        objednávky záujemcu. Doručením tohto e-mailu zároveň dôjde k rezervovaniu miesta v rámci zvoleného kurzu až do
        vybavenia objednávky dodávateľom. Vybavenie objednávky prebieha tak, že po tom, čo dodávateľ preverí dostupnosť
        vybraného kurzu, najneskôr v lehote 5 (slovom päť) pracovných dní zašle záujemcovi o kurz ďalší e-mail s
        potvrdením dostupnosti zvoleného kurzu a to buď prijatie objednávky v prípade dostupnosti daného kurzu alebo
        odmietnutím objednávky v prípade, že je zvolený kurz už kapacitne vyčerpaný alebo kurz nebol otvorený pre
        nedostatočný záujem. Do potvrdzujúceho e-mailu dodávateľ zašle záujemcovi o kurz v prílohe úplné znenie VOP ako
        aj formulár na odstúpenie od zmluvy v zmysle čl. VII, časti Odstúpenie od zmluvy týchto VOP.
      </p>
      <p>
        e) V prípade ak zvolený kurz nie je kapacitne vyčerpaný, momentom doručenia e-mailu o prijatí objednávky
        (záväzná akceptácia objednávky) záujemcu o kurz dôjde k uzavretiu kúpnej zmluvy. Od tohto momentu sú zmluvné
        strany takouto zmluvou viazané. Od zmluvy možno odstúpiť len za podmienok uvedených v týchto VOP.
      </p>
      <p>
        f) Prílohou potvrdzujúceho e-mailu dodávateľa sú tieto celé všeobecné obchodné podmienky v aktuálne platnom
        znení ako aj formulár na odstúpenie od zmluvy.
      </p>
      <p>
        g) V prípade ak zvolený kurz už bude kapacitne vyčerpaný, prípadne nebol otvorený, zašle dodávateľ záujemcovi o
        kurz e-mail, ktorým ho o tejto skutočnosti informuje a zároveň dôjde k stornovaniu vytvorenej objednávky.
        Súčasťou e-mailu o odmietnutí objednávky môže byť informácia o ďalších voľných kurzoch, ktoré si potom rovnakým
        spôsobom záujemca o kurz môže prostredníctvom formulára na webovej stránke dodávateľa objednať.
      </p>
      <p>2) Podrobnosti o spôsobe platby sa nachádzajú v Čl. VI. Platobné podmienky, ods. 3 Priebeh úhrady.</p>
      <p></p>

      <h2>Čl. IV.</h2>
      <h2>PRÁVA A POVINNOSTI DODÁVATEĽA</h2>
      <p>1) Dodávateľ má právo na úplné zaplatenie ceny za poskytnutú službu od objednávateľa.</p>
      <p>2) Dodávateľ si vyhradzuje právo na zmenu lektora, ak to povaha, charakter alebo iné okolnosti vyžadujú.</p>
      <p>
        3) Dodávateľ ma právo stornovať objednávku, ak z dôvodu nedostupnosti alebo nefunkčnosti služby nie je schopný
        zabezpečiť prevádzkovanie služby objednávateľovi v lehote určenej týmito VOP alebo v cene, ktorá je uvedená na
        webovej stránke dodávateľa, ak sa s objednávateľom nedohodne na náhradnom plnení.
      </p>
      <p>
        4) Dodávateľ má právo stornovať objednávku aj vtedy, ak nedôjde k otvoreniu kurzu z dôvodu nízkeho záujmu.
        Dodávateľ je oprávnený zrušiť konkrétny kurz aj v prípade, ak až po začatí kurzu dôjde k zníženiu počtu
        prihlásených žiakov.
      </p>
      <p>
        5) Dodávateľ má právo stornovať objednávku v prípade, ak objednávateľ neuvedie správne údaje alebo informácie
        alebo neprihlási žiaka na kurz v súlade s týmito VOP.
      </p>
      <p>
        6) O stornovaní objednávky bude objednávateľ informovaný prostredníctvom e-mailu, prípadne telefonicky a v
        prípade už poskytnutej úhrady za službu alebo jej časti budú finančné prostriedky objednávateľovi vrátené v
        lehote do 14 dní na ním určený účet, ak sa s dodávateľom nedohodne inak.
      </p>
      <p>
        7) Dodávateľ kurzy poskytuje prioritne osobnou formou. Dodávateľ má právo v prípade rozhodnutia príslušného
        orgánu Slovenskej republiky o uzavretí učebných priestorov školy, na ktorej dochádza k poskytovaniu zvoleného
        kurzu, dodávateľ si vyhradzuje právo zmeniť osobnú výučbu na online formu. Dodávateľ má právo zmeniť výučbu z
        osobnej na online formu aj v prípade, ak nebude možné výučbu zabezpečiť štandardnou osobnou formou z dôvodov na
        strane dodávateľa.
      </p>
      <p>
        8) Dodávateľ sa zaväzuje zabezpečiť na základe vykonanej a dodávateľom potvrdenej objednávky poskytnúť službu v
        dohodnutej cene a kvalite.
      </p>
      <p>9) Dodávateľ vyvinie úsilie o maximálnu funkčnosť a dostupnosť poskytovanej služby.</p>
      <p>
        10)Dodávateľ nezaručuje, že služba splní špecifické požiadavky objednávateľa a nezodpovedá za škody tým
        spôsobené.{" "}
      </p>
      <p>
        11)Dodávateľ nezodpovedá za škodu spôsobenú stratou alebo poškodením uložených dát či za dočasné prerušenie
        služby.{" "}
      </p>
      <p>
        12)Zmluvné strany berú na vedomie, že ak z týchto VOP vyplývajú ďalšie práva a povinnosti dodávateľa, obe sú
        nimi viazané.{" "}
      </p>
      <p></p>

      <h2>Čl. V.</h2>
      <h2>PRÁVA A POVINNOSTI OBJEDNÁVATEĽA</h2>
      <p>
        1) Objednávateľ má právo na poskytnutie služby v kvalite a termíne dohodnutom zmluvnými stranami.<p></p>
        2) Objednávateľ sa zaväzuje poskytnutú službu prevziať, zaplatiť dodávateľovi dohodnutú úhradu za službu v
        určenej lehote splatnosti a nepoškodzovať dobré meno prevádzkovateľa služby.<p></p>
        3) Objednávateľ sa ďalej zaväzuje neprejedávať s lektorom nijaké záležitosti týkajúce sa VOP, prípadne podmienok
        a pravidiel poskytovania kurzov. V prípade potreby môže objednávateľ v tejto súvislosti kontaktovať dodávateľa.
        Kontaktné údaje dodávateľa sú uvedené v čl. 1 ods. 1) týchto VOP.<p></p>
        4) Objednávateľ nie je oprávnený úmyselne zneužívať systém alebo inak preťažovať server dodávateľa.<p></p>
        5) Objednávateľ nie je oprávnený poskytovať službu tretím osobám.<p></p>
        6) Objednávateľ sa zaväzuje, že nebude nijakým spôsobom kontaktovať konkrétneho lektora, ktorý realizoval výučbu
        zvoleného alebo iného kurzu.<p></p>
        7) Objednávateľ sa zaväzuje informovať dodávateľa o všetkých zmenách v osobných údajoch.<p></p>
        8) Objednávateľ, ako aj dodávateľ prostredníctvom webovej stránky dodávateľa jej lokálnych variácií a subdomén
        sú povinní a vo svojom profile vždy poskytnúť pravé a najaktuálnejšie informácie o svojej osobe. Tieto
        vyhlásenia sú záväzné a budú slúžiť na posudzovanie prípadných sporov medzi zákazníkmi a poskytovateľmi.<p></p>
        9) Zmluvné strany berú na vedomie, že ak z týchto VOP vyplývajú ďalšie práva a povinnosti objednávateľa, obe sú
        nimi viazané.
      </p>

      <p></p>

      <h2>Čl. VI.</h2>
      <h2>PLATOBNÉ PODMIENKY, CENA A PRIEBEH ÚHRADY KURZU</h2>
      <p>
        1) Objednávateľ má možnosť výberu z dvoch spôsobov úhrady za kurz a to nasledujúcimi formami:<p></p>
        a) Jednorazová polročná platba - polročnou platbou dôjde k úhrade kurzu za celé polročné obdobie jednorazovo.
        Výška fakturovanej sumy je zložená z ceny za jednotlivý kurz, vynásobenej počtom kurzov v danom polroku podľa
        zvoleného spôsobu platby.<p></p>
        b) Platba v mesačných splátkach - pravidelnou mesačnou úhradou za obdobie jedného polroka poskytovania kurzu v
        zmysle čl. II ods. 1). Výška fakturovanej mesačnej sumy je zložená z ceny za jednotlivý kurz, vynásobenej počtom
        kurzov v danom kalendárnom mesiaci podľa zvoleného spôsobu platby. Objednávateľ sa zaväzuje uhradiť v takto
        určených splátkach cenu za celý kurz. Pri prvej platbe bude objednávateľovi fakturovaná suma za prvý mesiac
        kurzu a aj nasledujúci mesiac kurzu.<p></p>
      </p>
      <p>
        2) V prípade výberu úhrady kurzu na pravidelnej mesačnej báze podľa čl. VI, ods. 1) písm. b), platba sa bude
        vždy účtovať vopred v mesiaci predchádzajúcom mesiacu, za ktorý sa uhrádza kurz (okrem prvej platby v zmysle cl.
        VI. ods. 1 písm. b), poslednej vety). Dochádzka žiaka na kurz sa v cene za kurz nezohľadňuje.
      </p>

      <p>3) Priebeh úhrady:</p>
      <p>
        a) V nadväznosti na uvedené, objednávateľ má možnosť vykonať úhradu za kurz prostredníctvom platobnej brány
        nachádzajúcej sa priamo na webovej stránke dodávateľa po tom ako vytvorí objednávku.
      </p>
      <p>
        b) V prípade platby bezhotovostným spôsobom prostredníctvom platobnej brány zabezpečovanej službou Stripe,
        objednávateľ po uskutočnení objednávky a po prihlásení na webovej stránke dodávateľa v sekcií moje platby
        vyberie túto možnosť - platba kartou (stripe). Táto možnosť objednávateľa automaticky presmeruje na webové
        stránky poskytovateľa tejto služby, kde sú jasne uvedené údaje ako meno žiaka, zvolená škola, obdobie kurzu a
        cena. Následne objednávateľ zadá údaje podľa predvolených požiadaviek a uskutoční platbu ceny za kurz. Platba
        prebehne úspešne alebo neúspešne, v oboch prípadoch je objednávateľ automaticky presmerovaný späť na webovú
        stránku dodávateľa, kde bude oboznámený s výsledkom platby. Následne bude objednávateľovi tiež zaslaný e-mail s
        potvrdením o zaplatení.
      </p>
      <p>
        c) Podmienkou pre využitie tohto spôsobu je, aby objednávateľ disponoval platobnou kartou, ktorá umožňuje prevod
        peňažných prostriedkov v rámci tohto spôsobu platby.
      </p>
      <p>
        d) Druhým spôsobom úhrady kúpnej ceny je aj platba prostredníctvom bankového prevodu na základe vystavenej
        faktúry dodávateľom objednávateľovi po potvrdení dodávateľom v zmysle čl. III ods. 1) písm. e) týchto VOP.
        Objendávateľ sa zaväzuje uhradiť cenu za kurz uvedenú na faktúre podľa predchádzajúcej vety do 7 (slovom
        siedmych) dní (ďalej ako „lehota splatnosti“) odo dňa vystavenia faktúry.
      </p>
      <p>e) Pripísaním finančných prostriedkov na účet dodávateľa sa platba za kurz považuje za uhradenú.</p>
      <p>
        f) V prípade ak nedôjde k úhrade faktúry v lehote splatnosti, objednávka záujemcu o kurz bude automaticky
        stornovaná. Počas doby od potvrdenia objednávky dodávateľom až po uplynutie lehoty splatnosti je miesto na
        zvolenom kurze záujemcom o kurz rezervované.
      </p>
      <p>
        g) Platba realizovaná prostredníctvom bezhotovostného prevodu na účet dodávateľa v zmysle fakturačných údajov,
        ktoré sú súčasťou faktúry, ktorá je zasielaná ako súčasť potvrdzujúceho e-mailu.
      </p>
      <p>
        h) Platobná operácia je identifikovaná variabilným symbolom, unikátnym pre každú platbu. Formát variabilného
        symbolu sa skladá z dvojčísla mesiaca v ktorom sa platba zúčtuje a unikátne šesťčíselné číslo platby.
      </p>
      <p>
        i) Po vykonaní úhrady za kurz a jej pripísaní na účet dodávateľa, dodávateľvydá záujemcovi o kurz
        príslušnýdaňovýdoklad, v ktorom platbu zúčtuje.
      </p>
      <p>j) Platbu za kurz je možné vykonať výlučne bezhotovostne v zmysle tohto čl. VI týchto VOP.</p>
      <p>k) Platba ceny za kurz sa uskutočňuje v mene EUR.</p>
      <p></p>

      <p>4) Zľavy a benefity:</p>
      <p>
        a) V prípade výberu jednorazovej polročnej platby podľa čl. VI, ods. 1 písm. a), objednávateľ má právo na zľavu
        z celkovej ceny kurzu vo výške 10 %.
      </p>
      <p></p>

      <p>
        5) Záväzné a aktuálne ceny za kurzy (cenníky) garantované dodávateľom sú uvedené na webovej stránke dodávateľa.
        Pre objednávateľa je záväzná cena uvedená na web stránke dodávateľa po vyplnení objednávkového formulára.
      </p>
      <p>
        6) Dodávateľ si vyhradzuje právo jednostranne zmeniť ceny za kurzy uvedené na webovej stránke dodávateľa. Nové
        ceny kurzov nadobúdajú platnosť dňom ich zverejnenia na web stránke dodávateľa, pričom sa zmenené ceny netýkajú
        kurzov už potvrdených v zmysle čl. III, ods. 1, písm. e) týchto VOP.
      </p>
      <p>7) Cena kurzu je konečná, uvedená s DPH.</p>
      <p>8) Dodávateľ nie je platcom DPH.</p>
      <p></p>

      <h2>Čl. VII.</h2>
      <h2>REKLAMAČNÝ PORIADOK ODSTÚPENIE OD ZMLUVY</h2>
      <p>Reklamácie:</p>
      <p>
        1) Dodávateľ zodpovedá za chyby a nedostatky poskytovanej služby – kurzu. Objednávateľ je povinný reklamáciu u
        dodávateľa bezodkladne uplatniť v zmysle tohto reklamačného poriadku.<p></p>
        2) Tento reklamačný poriadok sa vzťahuje na reklamácie služieb poskytovaných dodávateľom, zakúpených
        objednávateľom prostredníctvom elektronického obchodu nachádzajúceho sa na webovej stránke dodávateľa a vzťahuje
        sa na všetky obchodné prípady, ibaže by boli inak individuálne dojednané.<p></p>
        3) Právo objednávateľa uplatniť si reklamáciu služby prichádza do úvahy len pri službe, ktorá vykazuje chyby,
        ktoré zavinil dodávateľ, na službu sa vzťahuje záruka a u dodávateľa bola riadne zakúpená.<p></p>
        4) Objednávateľ môže skontrolovať prevádzku služby po jej poskytnutí dodávateľom. Ak tak neurobí, môže uplatniť
        nároky z chýb zistených pri tejto kontrole len keď preukáže, že tieto chyby mala služba už v čase jej
        poskytnutia.<p></p>
        5) Objednávateľ má právo na odstránenie chýb poskytnutej služby bezplatne po tom, ako to dodávateľovi oznámi a
        preukáže zakúpenie služby u prevádzkovateľa.<p></p>
        6) Objednávateľ sa zaväzuje, že chyby či nedostatky poskytnutej služby bude u dodávateľa reklamovať bez
        zbytočného odkladu. V opačnom prípade právo uplatniť si voči dodávateľovi bezplatné odstránenie chýb či
        nedostatkov podľa čl. VII, časti Reklamácie, ods. 5) zaniká.<p></p>
        7) Reklamácie sú vybavované dodávateľom počas pracovných dní. Reklamácie možno uplatniť formou formuláru na web
        stránke, prostredníctvom e-mailovej adresy dodávateľa, prípadne telefonicky alebo písomne. V prípade nejasností
        má objednávateľ právo za týmto účelom dodávateľa kontaktovať<p></p>
        8) Uplatnenie reklamácie prostredníctvom webovej stránky dodávateľa prebieha tak, že po úspešnom prihlásení sa
        do používateľského účtu objednávateľ v sekcií žiadosti vyberie možnosť reklamácie/iné. Do určeného textového
        poľa uvedie základné údaje dodávateľa v rozsahu čl. I ods. 1) týchto VOP. Ďalej objednávateľ uvedie svoje meno,
        priezvisko, titul, adresu bydliska, telefónny kontakt alebo e-mailovú adresu a dátum. Čo sa týka reklamovaného
        plnenia objednávateľ uvedie, kedy s predávajúcim uzavrel zmluvu, popíše chyby a nedostatky poskytnutého kurzu,
        uvedie číslo objednávky a faktúry, dátum objednania služby, dátum dodania služby (čas kedy bol kurz vykonaný),
        názov kurzu tak ako je uvedený vo faktúre, požiadavku ako chce aby bola reklamácia dodávateľom vybavená. V
        prípade požiadavky vrátenia peňazí uvedie IBAN účtu, na ktoré majú byť prostriedky vrátené. Ak sa k reklamácií
        prikladajú ďalšie listiny, objednávateľ uvedie ich počet a označenie. V prípade zasielania reklamácie
        prostredníctvom poštového podniku alebo iným podobným spôsobom, objednávateľ takýto dokument vlastnoručne
        podpíše.<p></p>
        9) Kontaktné údaje dodávateľa sú uvedené v čl. I ods. 1) týchto VOP.<p></p>
        10) Dodávateľ sa zaväzuje prijať reklamáciu. Osoba poverená na prijímanie reklamácií je Bc. Daniel Cok, konateľ
        dodávateľa, a to prostredníctvom kontaktných údajov podľa čl. I ods. 1) týchto VOP.<p></p>
        11) Dodávateľ je povinný prijať reklamáciu v ktorejkoľvek svojej prevádzkarni, v ktorej je prijatie reklamácie
        možné.<p></p>
        12) Po tom ako objednávateľ uplatní reklamáciu, vydá mu dodávateľ potvrdenie o uplatnení reklamácie formou
        e-mailu ihneď, ak to je možné, avšak bez zbytočného odkladu. Súčasťou e-mailu budú vymienené nedostatky a chyby
        služby uvedené objednávateľom v reklamácií a poučenie objednávateľa o jeho právach.<p></p>
        13) Potvrdenie o uplatnení reklamácie sa nemusí doručovať, ak objednávateľ má možnosť preukázať uplatnenie
        reklamácie iným spôsobom.<p></p>
        14) Objednávateľ sa môže rozhodnúť, ktoré z práv prislúchajúcich mu v zmysle § 622 a 623 zákona č. 40/1964 Zb.
        Občiansky zákonník si primerane uplatňuje. Dodávateľ určí spôsob vybavenia reklamácie ihneď, v zložitejších
        prípadoch do 3 (slovom troch) dní odo dňa uplatnenia reklamácie. V odôvodnených prípadoch najneskôr do 30
        (slovom tridsať) dní odo dňa uplatnenia reklamácie. Následne dodávateľ vybaví reklamáciu ihneď resp. v
        odôvodnených prípadoch neskôr pri súčasnom zachovaní reklamačnej doby 30 (slovom tridsať) dní odo dňa uplatnenia
        reklamácie (ďalej ako „lehota na uplatnenie reklamácie“).<p></p>
        15) Potom, ako uplynie lehota na vybavenie reklamácie má spotrebiteľ právo od zmluvy odstúpiť alebo má právo na
        nové poskytnutie služby.<p></p>
        16) O ukončení reklamačného konania a výsledku reklamácie informuje dodávateľ objednávateľa prostredníctvom
        e-mailu a súčasne bude objednávateľovi doručený reklamačný protokol.<p></p>
        17) Objednávateľ nemá právo na uplatnenie záruky za chyby a nedostatky služby, na ktoré ho dodávateľ upozornil.
        Ak s prihliadnutím na všetky okolnosti, za ktorých bola zmluva uzatvorená vyplýva, že objednávateľ musel vedieť
        o chybách a nedostatkoch služby, rovnako nemá právo na uplatnenie záruky.<p></p>
        18) Dodávateľ môže namiesto odstránenia chýb a nedostatkov služby poskytnúť objednávateľovi novú službu za
        predpokladu, že to objednávateľovi nespôsobí závažné ťažkosti.<p></p>
        19) Objednávateľ berie na vedomie, že nárok na uplatnenie záruky u dodávateľa mu zaniká v nasledujúcich
        prípadoch:<p></p>
        a) ak objednávateľ služby nepreukáže, že službu zakúpil u dodávateľa,<p></p>
        b) neoznámením zjavných chýb pri poskytnutí služby,<p></p>
        c) uplynutím záručnej doby služby,<p></p>
        d) poškodením služby spôsobeným používateľom služby,<p></p>
        e) poškodením služby neodvrátiteľnými a/alebo nepredvídateľnými udalosťami,<p></p>
        f) poškodením služby náhodnou skazou a náhodným zhoršením,<p></p>
        g) neodborným zásahom alebo iným zásahom vyššej moci,<p></p>
        h) zásahom do služby k tomu neoprávnenej osoby<p></p>
        20) Vybavenie reklamácie a ukončenie reklamačného konania dodávateľom prebieha nasledovným spôsobom:<p></p>
        a) odstránením chyby služby,<p></p>
        b) poskytnutím novej služby,<p></p>
        c) vrátením úhrady za službu,<p></p>
        d) vyplatením primeranej zľavy z úhrady za službu,<p></p>
        e) písomnou výzvou na prevzatie dodávateľom určeného plnenia,<p></p>
        f) odôvodneným zamietnutím reklamácie služby.<p></p>
        21) Ak ide o odstrániteľnú chybu, bude reklamácia vybavená v závislosti od rozhodnutia dodávateľa v zmysle čl.
        VII ods. 20) týchto VOP nasledujúcim spôsobom:<p></p>
        a) prevádzkovateľ služby zabezpečí odstránenie chyby, alebo<p></p>
        b) prevádzkovateľ služby poskytne novú službu.<p></p>
        22) Ak ide o chybu, ktorú nemožno odstrániť, alebo o jednu viackrát opakovanú odstrániteľnú chybu, alebo o väčší
        počet rôznych odstrániteľných chýb, ktoré bránia tomu, aby mohla byť služba riadne užívaná ako bez chyby,
        dodávateľ vybaví v závislosti od rozhodnutia objednávateľa podľa čl. VII ods. 20) týchto VOP reklamáciu
        nasledujúcim spôsobom:<p></p>
        a) poskytnutím inej novej služby, funkčnej, s rovnakými alebo lepšími technickými parametrami, alebo<p></p>
        b) v prípade, že nemôže prevádzkovateľ služby poskytnúť inú novú službu, vybaví reklamáciu vystavením dobropisu
        na chybnú službu.<p></p>
        23) O spôsobe vybavenia reklamácie a o vybavení reklamácie dodávateľ objednávateľa informuje tak, že mu vydá
        najneskôr do 30 dní odo dňa uplatnenia reklamácie potvrdenie/doklad prostredníctvom e-mailu.<p></p>
        24) Záručná doba je 24 mesiacov a začína plynúť odo dňa poskytnutia služby objednávateľovi. Záručná doba sa
        predlžuje o dobu, počas ktorej objednávateľ služby nemohol užívať službu z dôvodu záručnej opravy služby.<p></p>
        25) V prípade poskytnutia novej služby dostane objednávateľ doklad, na ktorom bude uvedená nová služba. Ďalšie
        prípadné reklamácie sa uplatňujú na základe pôvodnej faktúry a dokladu o úhrade za službu a tohto reklamačného
        dokladu.<p></p>
        26) V prípade poskytnutia novej služby začne plynúť záručná doba znovu od poskytnutia novej služby, ale iba na
        novú službu.<p></p>
        27) Vybavenie reklamácie sa vzťahuje len na chyby uvedené vo formulári na uplatnenie reklamácie.<p></p>
        28) Pre účely reklamácie sa za viackrát opakovanú odstrániteľnú chybu považuje výskyt jednej odstrániteľnej
        chyby viac ako dvakrát.<p></p>
        29) Pre účely reklamácie sa za väčší počet rôznych odstrániteľných chýb považuje výskyt viac ako troch rôznych
        odstrániteľných chýb súčasne.<p></p>
        30) V prípade, že dodávateľ ukončí reklamačné konanie ako odôvodnené zamietnutie reklamácie, ale chyba služby
        objektívne jestvuje a nebola odstránená, môže si objednávateľ uplatniť svoje právo na odstránenie chyby služby
        prostredníctvom súdu.<p></p>
        Odstúpenie od zmluvy
        <p></p>
        1) Dodávateľ a objednávateľ má právo odstúpiť od zmluvy v súlade s podmienkami uvedenými v týchto VOP a
        príslušných právnych predpisoch.<p></p>
        2) Dodávateľ a objednalvateľ je oprávnený odstúpiť od zmluvy bez uvedenia dôvodu alebo z akéhokoľvek dôvodu, kým
        nedošlo k začatiu poskytovania služby.<p></p>
        3) Dodávateľ može ďalej odstúpiť od zmluvy ak objednávateľuvedie nepravdivé alebo neúplné údaje v zmluve,
        formulári, emaile o konkrétnej službe, ktorý odoslal dodávateľovi alebo sa ukážu ako nepravdivéalebo
        neúplnéinéúdaje, ktoréposkytol objednávateľdodávateľovi.<p></p>
        4) Pokiaľ objednávateľneposkytol bezodkladne dodávateľovi dostatočnú súčinnosťpri poskytovaní služby, alebo
        dodávateľovi budú doručené akýmkoľvek spôsobom pozmenenédodávateľom poskytnutédokumenty v súvislosti s
        poskytnutim služby.<p></p>
        5) Objednávateľ počas poskytovania služby nedodržuje podmienky v zmysle týchto VOP.<p></p>
        6) Objednávateľ môže od zmluvy uzavretej s dodávateľom v zmysle týchto VOP odstúpiť v lehote 14 dní odo dňa
        uzavretia zmluvy podľa čl. III ods. 1), písm. e)<p></p>
        7) Vzhľadom na špecifiká a povahu poskytovaných služieb, objednávateľ nemôže odstúpiť od uzavretej zmluvy v
        takom prípade, ak sa už začalo s poskytovaním služby, s ktorou objednávateľ – spotrebiteľ vyslovil výslovný
        súhlas. Vyjadrením tohto súhlasu stráca spotrebiteľ právo na odstúpenie od zmluvy. V zmysle ustanovenia § 7 ods.
        6 zákona písm. a) zákona č. 102/2014 Z. z. o ochrane spotrebiteľa pri predaji tovaru alebo poskytovaní služieb
        na základe zmluvy uzavretej na diaľku alebo zmluvy uzavretej mimo prevádzkových priestorov predávajúceho Podľa
        predmetného ustanovenia „poskytnutie služby, ak sa jej poskytovanie začalo s výslovným súhlasom spotrebiteľa a
        spotrebiteľ vyhlásil, že bol riadne poučený o tom, že vyjadrením tohto súhlasu stráca právo na odstúpenie od
        zmluvy po úplnom poskytnutí služby, a ak došlo k úplnému poskytnutiu služby“.<p></p>
        8) Objednávateľ je povinný uhradiť predávajúcemu iba cenu za skutočne poskytnuté plnenie do dňa doručenia
        oznámenia o odstúpení od zmluvy. Cena za skutočne poskytnuté plnenie sa vypočíta pomerne na základe celkovej
        ceny dohodnutej v zmluve. Ak sa objednávateľ v súvislosti s vyjadrením tohto súhlasu podľa čl. VII, časti
        Odstúpenie od zmluvy, ods. 7 týchto VOP rozhodne pristúpiť k odstúpeniu od zmluvy, je povinný uhradiť
        dodávateľovi cenu len za skutočne poskytnuté plnenie.<p></p>
        9) Dodávateľ má právo požadovať ceny za služby, ktoré spotrebiteľovi boli dodané.<p></p>
        10) Spôsob, akým môže spotrebiteľ uplatniť odstúpenie od zmluvy je formulár na odstúpenie od zmluvy, ktorý sa
        nachádza na web stránke dodávateľa, v sekcií žiadosti vyberie možnosť odstúpenie od zmluvy. Spotrebiteľ zadá
        údaje dodávateľa v zmysle čl. I ods. 1) týchto VOP a svoje osobné údaje v rozsahu mena priezviska, adresy,
        telefónne číslo prípadne e-mailovú adresu a dátum. Spotrebiteľ vyhlási, že odstupuje od zmluvy o poskytnutí
        konkrétneho kurzu, ktorý si zvolil, uvedie dátum objednania alebo prijatia služby. V prípade zasielania
        odstúpenia od zmluvy prostredníctvom poštového podniku alebo iným podobným spôsobom, objednávateľ takýto
        dokument vlastnoručne podpíše a zašle ho na adresu sídla dodávateľa.<p></p>
        11) Formulár na odstúpenie od zmluvy je účasťou potvrdzujúceho e-mailu a nachádza sa na stiahnutie aj na webovej
        stránke dodávateľa po prihlásení do užívateľského prostredia : www.mladiprogramatori.sk/#/request<p></p>
        12) Lehota na odstúpenie od zmluvy je zachovaná ak oznámenie o odstúpení od zmluvy bolo odoslané dodávateľovi
        najneskôr v deň lehoty.<p></p>
        13) Dôkazné bremeno o uplatnení práva na odstúpenie od zmluvy znáša spotrebiteľ.<p></p>
        14) Po tom, ako dôjde k doručeniu oznámenia o odstúpení od zmluvy zo strany spotrebiteľa v súlade s týmito VOP,
        dodávateľ sa zaväzuje vrátiť všetky platby a úhrady poskytnuté spotrebiteľom z výnimkou služieb, ktoré už boli
        spotrebiteľovi poskytnuté v zmysle čl. VII, ods. 7) týchto VOP<p></p>
        15) Dodávateľ sa zaväzuje vrátiť spotrebiteľovi platby rovnakým spôsobom, aký použil spotrebiteľ pri svojej
        platbe. Spotrebiteľ má, samozrejme, právo dohodnúť sa s predávajúcim na inom spôsobe platby, ak v súvislosti s
        tým spotrebiteľovi nebudú účtované žiadne ďalšie poplatky.<p></p>
        16) V prípade, že sa pri niektorej Službe vyskytne cena, ktoráje evidentne chybná(napr. Cena 0,- eur, 1,- eur
        alebo inázjavne ekonomicky nízka cena a pod.), Dodávateľnemá povinnosťposkytnúť Službu za chybnú cenu. V takomto
        prípade Dodávateľmôže Objednávateľovi ponúknuť poskytnutie Služby za riadnu cenu. Ak Objednávateľ s riadnou
        cenou nesúhlasí, môže Dodávateľodstúpiťod zmluvy.<p></p>
        17) Dodávateľ si vyhradzuje právo odstúpiť od zmluvy z dôvodu naplenie kapacít kurzu, pričom poradie v ktorom
        dôjde k plneniu je závislé od dátumu zúčtovania platby objednávateľa.<p></p>
        18) Dodávateľ má právo odstúpiť od zmluvy v prípade, ak študent závažným spôsobom narušuje preibeh kurzu, ohrozí
        zdravie ďalších účastníkov kurzu, lektorov a podobne.<p></p>
        <p>
          19) Dodávateľ je oprávnený odstúpiť od zmluvy a zrušiť konkrétny kurz v prípade, ak po začatí kurzu dôjde k
          zníženiu počtu prihlásených žiakov na 5, dodávateľ sa v takom prípade zaväzuje poskytnúť obdobné plnenie v
          súlade s týmito VOP, prípadne vrátenie zaplatenej ceny kurzu zníženej o už poskytnuté plnenie zo strany
          dodávateľa.
        </p>
        <p>
          20) Dodávateľ má právo požadovať platbu za služby, ktoré spotrebiteľovi boli dodané a to aj v prípade, ak
          neboli objednávateľom prevzaté (žiak sa kurzu nezúčastní).
        </p>
        <p></p>
        <h2>Čl. VIII.</h2>
        <h2>OSOBNÉ ÚDAJE A ICH OCHRANA</h2>
        <p>
          1) Dodávateľ prijal primerané opatrenia k spracúvaniu osobných údajov, v zmysle zákona č. 18/2018 Z. z. o
          ochrane osobných údajov a podľa nariadenia GDPR. Objednávateľ potvrdzuje, že bol oboznámený so spracúvaním
          jeho osobných údajov v zmysle pravidiel nachádzaúcich sa na webovej stránke dodávateľa, konkrétne:
          https://www.mladiprogramatori.sk/#/privacy-policy
        </p>
        <p></p>
        <h2>Čl. IX.</h2>
        <h2>ALTERNATÍVNE RIEŠENIE SPOROV</h2>
        <p>
          1) Dozor nad dodržiavaním povinností dodávateľa voči spotrebiteľovi je Slovenská obchodná inšpekcia, konkrétne
          pracovisko Inšpektorát SOI pre Bratislavský kraj, Prievozská 32, P. O. Box 5, 820 07 Bratislava 27, odbor
          technickej kontroly výrobkov a ochrany spotrebiteľa a právny odbor, tel. č.: 02/ 58272 172, 02/58272 104 fax
          č.: 02/ 58272 170, email: ba@soi.sk.<p></p>
          2) Spotrebiteľ je oprávnený obrátiť sa na dodávateľa so žiadosťou o nápravu v prípade, že nebol spokojný so
          spôsobom, ktorým dodávateľ vybavil jeho reklamáciu alebo ak sa domnieva, že dodávateľ porušil jeho práva. Ak
          dodávateľ na takúto žiadosť odpovie zamietavo, alebo na ňu neodpovie do 30 dní odo dňa jej odoslania, má
          spotrebiteľ právo podať návrh na začatie alternatívneho riešenia sporu subjektu alternatívneho riešenia
          sporov. Subjektmi alternatívneho riešenia sporov sú orgány a oprávnené právnické osoby v zmysle § 3 zákona č.
          391/2015 Z. z. o alternatívnom riešení spotrebiteľských sporov, a to napr. Slovenská obchodná inšpekcia,
          pričom používateľ spotrebiteľ je oprávnený si vybrať subjekt alternatívneho riešenia spotrebiteľských sporov,
          na ktorý sa obráti. E-mailový kontakt na dodávateľa je uvedený v čl. I ods. 1) týchto VOP. Spotrebiteľ pri
          podaní návrhu postupuje v zmysle § 12 zákona č. 391/2015 Z. z. o alternatívnom riešení spotrebiteľských
          sporov. Alternatívnym riešením sporov možno urovnať spor medzi spotrebiteľom a dodávateľom vyplývajúci zo
          spotrebiteľskej zmluvy, resp. súvisiaci so spotrebiteľskou zmluvou. Hodnota sporu, ktorý sa urovnáva
          alternatívnym riešením sporov, musí presahovať sumu 20 eur. Subjekt alternatívneho riešenia sporov môže
          požadovať od spotrebiteľa poplatok za začatie alternatívneho riešenia sporu najviac sumu päť eur vrátane dane
          z pridanej hodnoty, a to najskôr súčasne so zaslaním oznámenia o začatí alternatívneho riešenia sporu.<p></p>
          3) Spotrebitelia sú oprávnení použiť platformu riešenia sporov online (ďalej len ako „RSO“) na riešenie
          svojich sporov v jazyku, ktorý si zvolia. Spotrebiteľ môže na alternatívne riešenie svojho sporu využiť
          platformu RSO, ktorá je dostupná na webovej stránke http://ec.europa.eu/consumers/odr/. Spotrebiteľ pri
          predkladaní podania platforme RSO vyplní elektronický formulár sťažnosti. Informácie, ktoré predloží, musia
          byť dostatočné na určenie príslušného subjektu alternatívneho riešenia sporov online. Spotrebiteľ môže
          priložiť dokumenty na podporu svojej sťažnosti.
        </p>
        <p></p>
        <h2>Čl. X.</h2>
        <h2>ZÁVEREČNÉ USTANOVENIA</h2>
        <p>
          1) Dodávateľ si vyhradzuje právo na zmenu týchto VOP. Povinnosť písomného oznámenia zmeny v týchto všeobecných
          obchodných podmienkach je splnená umiestnením na webovej stránke dodávateľa.<p></p>
          2) V prípade, ak je zmluva uzatvorená v písomnej forme, akákoľvek jej zmena musí mať písomnú formu.<p></p>
          3) Zmluvné strany sa dohodli, že komunikácia medzi nimi bude uskutočňovaná primárne vo formou e-mailu<p></p>
          4) Na vzťahy neupravené týmito všeobecnými obchodnými podmienkami sa vzťahujú príslušné ustanovenia zákona č.
          513/1991 Zb. Obchodný zákonník.<p></p>
          <br></br>
          <p></p>V Nitre, dňa 15.01.2024
        </p>
      </p>
    </div>
  );
};

export default TermsAndConditions;
