import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface Lector {
  _id: string;
  name: string;
  surname: string;
  // Add other properties as needed
}

interface AssignLectorToCourseProps {
  userId: string;
}

const AssignLectorToCourse = ({userId}: AssignLectorToCourseProps) => {
  const { id } = useParams<{ id: string }>(); // Get the course ID from the URL
  const [allLectors, setAllLectors] = useState<Lector[]>([]);
  const [selectedLectorId, setSelectedLectorId] = useState<string>(""); // Initialize as an empty string
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod"
      ? process.env.REACT_APP_PROD_URL
      : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchAllLectors = async () => {
      try {
        const response = await fetch(url + "lector/allLectors",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setAllLectors(data);
      } catch (error) {
        console.error("Error fetching lectors:", error);
      }
    };

    fetchAllLectors();
  }, [token, url, userId]);

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  const handleAssignLector = async (): Promise<void> => {
    const selectedLector = allLectors.find((lector) => lector._id === selectedLectorId);
    const lectorName = selectedLector && selectedLector.name + " " + selectedLector.surname;
    if (selectedLectorId && id) {
      try {
        const response = await fetch(url + `course/assignLector/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
          },
          body: JSON.stringify({ lectorId: selectedLectorId, lectorName }),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        // Lector successfully assigned to the course
        // You can perform any other actions here if needed
      } catch (error) {
        console.error("Error assigning lector to course:", error);
      }
    }
  };

  return (
    <div>
      <h2>Assign Lector to Course</h2>
      <label>Select a Lector:</label>
      <select value={selectedLectorId} onChange={(e) => setSelectedLectorId(e.target.value)}>
        <option value="" disabled>
          Select Lector
        </option>
        {allLectors.map((lector) => (
          <option key={lector._id} value={lector._id}>
            {lector.name + " " + lector.surname}
          </option>
        ))}
      </select>
      <button onClick={handleAssignLector}>Assign Lector</button>
    </div>
  );
};

export default AssignLectorToCourse;
