import React, { useEffect } from 'react';
import './PersonalDataAgreement.css';

interface PersonalDataAgreementProps {
  isChecked: boolean;
  onCheckboxChange: (checked: boolean) => void;
  userSignedPersonalData: boolean;
  updateUserData: () => void;
  userId: string;
  token: string;
  userData?: any;
}

const GeneralTermsAndConditions: React.FC<PersonalDataAgreementProps> = ({ isChecked, onCheckboxChange, userSignedPersonalData, updateUserData, userId, token, userData }) => {

  // Ensure the checkbox is not editable and checked if userSignedPersonalData is true
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!userSignedPersonalData) {
      onCheckboxChange(e.target.checked);
    }
  };

  // if isChecked than put fetch request to update user data
  useEffect(() => {
    if (isChecked) {
      const url =
        process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;
      const fetchUser = async () => {
        if(userData.signedVOP === false) { 
        try {
          const response = await fetch(url + `user/${userId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "X-User-Id": userId, // custom header for user ID
            },
            body: JSON.stringify({ signedVOP: true }),
          });

          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          updateUserData();
        } catch (error) {
          console.error("Error fetching user:", error);
        }
      };
    }
      
      fetchUser();
    }
  }, [isChecked, token, userId, updateUserData, userData.signedVOP]);

  return (
    <div className="agreement-container">
      <label className="checkbox-container">
        <input
          type="checkbox"
          checked={userSignedPersonalData ? true : isChecked}
          onChange={handleCheckboxChange}
          disabled={userSignedPersonalData} // Disable the checkbox if userSignedPersonalData is true
        />
        <span className="custom-checkbox"></span>
        {userSignedPersonalData ? "Súhlasil som s uvedenymi " : "Súhlasím s uvedenymi "}
        <a href="/#/terms-and-conditions">obchodnymi podmienkami</a> a reklamačným poriadkom
      </label>
    </div>
  );
};

export default GeneralTermsAndConditions;
