import { IconButton, AppBar, Toolbar, Typography, Stack, Box } from "@mui/material";
import { FiLogOut } from "react-icons/fi";
// import { AiOutlineFileText } from "react-icons/ai";
import { MdPeople } from "react-icons/md";
import { FaSchool } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FaUserAlt } from "react-icons/fa";
// icon for attendance
import { FaUserCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Drawer, useMediaQuery, useTheme, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import React from "react";

interface AdminHeaderProps {
  handleLogout: () => void;
  userId: string;
  userData: any;
}

const AdminHeader = ({ handleLogout, userId, userData }: AdminHeaderProps) => {
  const isAdmin = userData?.role === "admin";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <div>
      <List>
        <ListItem button component={Link} to="/admin/school" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaSchool size={20} />
          </ListItemIcon>
          <ListItemText primary="School" />
        </ListItem>
        <ListItem button component={Link} to="/admin/user" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <MdPeople size={20} />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem button component={Link} to="/admin/course" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaBook size={20} />
          </ListItemIcon>
          <ListItemText primary="Course" />
        </ListItem>
        <ListItem button component={Link} to="/admin/lector" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaUserGraduate size={20} />
          </ListItemIcon>
          <ListItemText primary="Lector" />
        </ListItem>
        <ListItem button component={Link} to="/admin/payment" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaMoneyBillAlt size={20} />
          </ListItemIcon>
          <ListItemText primary="Payment" />
        </ListItem>
        <ListItem button component={Link} to="/admin/children" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaUserAlt size={20} />
          </ListItemIcon>
          <ListItemText primary="Children" />
        </ListItem>
        <ListItem button component={Link} to="/admin/attendance" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaUserCheck size={20} />
          </ListItemIcon>
          <ListItemText primary="Attendance" />
        </ListItem>
        <ListItem button component={Link} to="/admin/requests" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaUserCheck size={20} />
          </ListItemIcon>
          <ListItemText primary="Request" />
        </ListItem>
        <ListItem button component={Link} to="/admin/interest" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaUserCheck size={20} />
          </ListItemIcon>
          <ListItemText primary="Interest" />
        </ListItem>
      </List>
    </div>
  );

  if (!isAdmin) {
    return null;
  }

  return (
    <>
      {isAdmin && (
        <AppBar position="static" sx={{ backgroundColor: "#336699", marginTop: "2px" }}>
          <Toolbar>
            <Stack direction="row" alignItems="center" spacing={2}>
              {isMobile ? (
                <React.Fragment>
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                    <MenuIcon />
                  </IconButton>
                  <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
                    {drawer}
                  </Drawer>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Link to="/admin/school" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaSchool size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        School
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/admin/user" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <MdPeople size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Users
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/admin/course" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaBook size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Course
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/admin/lector" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaUserGraduate size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Lector
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/admin/payment" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaMoneyBillAlt size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Payment
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/admin/children" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaUserAlt size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Children
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/admin/attendance" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaUserCheck size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Attendance
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/admin/requests" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaUserCheck size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Request
                      </Typography>
                    </IconButton>
                  </Link>
                  <Link to="/admin/interest" style={{ textDecoration: "none", color: "inherit" }}>
                    <IconButton color="inherit">
                      <FaUserCheck size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Interest
                      </Typography>
                    </IconButton>
                  </Link>
                </React.Fragment>
              )}
            </Stack>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton color="inherit" onClick={handleLogout}>
              <FiLogOut size={20} />
              <Typography variant="body1" sx={{ ml: 2 }}>
                Log out
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default AdminHeader;
