import { Button, Container } from "@mui/material";
import { useState } from "react";
import CourseCard, { DifficultyLevel, Language } from "../CourseCard/CourseCard";

interface CoursesProps {
  isDarkTheme: boolean;
  userId: string;
}

enum gradeLevel {
  first = "first",
  second = "second",
}

const courseCardData = [
    {
      "language": Language.SCRATCH,
      "title": "Pokročilé programování v jazyce Scratch",
      "description": "Pokročilé programování v jazyce Scratch je určeno pro děti, které už mají s programováním zkušenosti a chtějí se naučit něco nového. V tomto kurzu se naučíte, jak vytvořit vlastní program, který bude obsahovat všechny základní prvky programování.",
      "difficultyLevel": DifficultyLevel.MEDIUM || DifficultyLevel.EASY,
      "id": 6,
      "price": "170-250 CZK / hod",
      "courseDuration": "1 hodina (1x týdně, celý pololetí)",
      "capacity": 10,
      "suitableFor": "Děti od 5. - 9. ročníku",
      "gradeLevel": "first",
      "conditions": "Scratch I - základy programování absolvovány"
    },
    {
      "language": Language.ROBLOX,
      "title": "Úvod do programování v jazyce Roblox",
      "description": "Přiblížte se k světu digitální tvorby a herního designu prostřednictvím platformy Roblox. Roblox není jen oblíbenou herní platformou, ale také nástrojem, který umožňuje uživatelům vytvářet vlastní hry a virtuální prostředí. Díky své integraci s jazykem Lua, Roblox nabízí začátečníkům přátelský úvod do programování, zatímco zkušenějším vývojářům poskytuje nástroje potřebné k vytváření složitějších projektů.",
      "difficultyLevel": DifficultyLevel.MEDIUM,
      "id": 5,
      "price": "170-250 CZK / hod",
      "courseDuration": "1 hodina (1x týdně, celý pololetí)",
      "capacity": 10,
      "suitableFor": "Děti od 5. - 9. ročníku",
      "gradeLevel": "second",
      "conditions": "Scratch I - základy programování absolvovány nebo Python I - základy programování absolvovány"
    },
    {
      "language": Language.SCRATCH,
      "title": "Úvod do programování v jazyce Scratch",
      "description": "Programování v jazyce Scratch je vhodné už i pro ty nejmenší. Společně se hravě seznámíme s tím, co vlastně programování je a ukážeme, že děti mohou skutečně začít vytvářet jednoduché hry už ve druhém ročníku. A při tom budou skutečně programovat!",
      "difficultyLevel": DifficultyLevel.EASY,
      "id": 1,
      "price": "170-250 CZK / hod",
      "courseDuration": "1 hodina (1x týdně, celý pololetí)",
      "capacity": 10,
      "suitableFor": "Děti od 2. - 4. ročníku",
      "gradeLevel": "first"
    },
    {
      "language": Language.PYTHON,
      "title": "Úvod do programování v jazyce Python",
      "description": "Naučte se základy programování vytvářením zábavných interaktivních příběhů, her a animací pomocí Pythonu. Python je vynikajícím výběrem pro začátečníky díky jeho čitelnosti a jednoduchosti, ale i pro zkušenější programátory, kteří chtějí rozšířit své znalosti.",
      "difficultyLevel": DifficultyLevel.EASY,
      "id": 2,
      "price": "170-250 CZK / hod",
      "courseDuration": "1 hodina (1x týdně, celý pololetí)",
      "capacity": 10,
      "suitableFor": "Děti od 5. - 9. ročníku",
      "gradeLevel": "second"
    },
    {
      "language": Language.JAVASCRIPT,
      "title": "Úvod do programování v jazyce JavaScript",
      "description": "Naučte se základy programování prostřednictvím vytváření dynamických webových stránek a interaktivních aplikací pomocí JavaScriptu. JavaScript je ideální pro ty, kteří chtějí vstoupit do světa webového vývoje s jeho všestranností a flexibilitou, a je skvělým jazykem pro nováčky i pro odborníky, kteří se snaží prohlubovat své dovednosti v oblasti webové technologie.",
      "difficultyLevel": DifficultyLevel.HARD,
      "id": 3,
      "price": "170-250 CZK / hod",
      "courseDuration": "1 hodina (1x týdně, celý pololetí)",
      "capacity": 10,
      "suitableFor": "Děti od 5. - 9. ročníku",
      "gradeLevel": "second"
    },
    {
      "language": Language.HTML,
      "title": "Úvod do programování v jazyce HTML",
      "description": "Naučte se základy programování prostřednictvím vytváření dynamických webových stránek a interaktivních aplikací pomocí HTML. HTML je ideální pro ty, kteří chtějí vstoupit do světa webového vývoje s jeho všestranností a flexibilitou, a je skvělým jazykem pro nováčky i pro odborníky, kteří se snaží prohlubovat své dovednosti v oblasti webové technologie.",
      "difficultyLevel": DifficultyLevel.HARD,
      "id": 7,
      "price": "170-250 CZK / hod",
      "courseDuration": "1 hodina (1x týdně, celý pololetí)",
      "capacity": 10,
      "suitableFor": "Děti od 5. - 9. ročníku",
      "gradeLevel": "second"
    },
    {
      "language": Language.TYPESCRIPT,
      "title": "Pokročilé programování v jazyce TypeScript",
      "description": "Vstupte hlouběji do světa moderního webového a aplikačního vývoje s TypeScriptem. TypeScript, rozšíření jazyka JavaScript, přidává statické typy a mnoho dalších vlastností, čímž umožňuje vytvářet robustní, efektivní a bezpečné aplikace pro různé platformy. Tento kurz je určen pro ty, kteří jsou již seznámeni se základy TypeScriptu a chtějí rozšířit své znalosti a dovednosti na vyšší úroveň.",
      "difficultyLevel": DifficultyLevel.HARD,
      "id": 4,
      "price": "170-250 CZK / hod",
      "courseDuration": "1 hodina (1x týdně, celý pololetí)",
      "capacity": 10,
      "suitableFor": "Děti od 5. - 9. ročníku",
      "gradeLevel": "second",
      "conditions": "JavaScript I - základy programování absolvovány"
    }
    
];

function Courses({ isDarkTheme, userId }: CoursesProps) {
  //Newly added state for selected difficulty
  const [selectedDifficulty, setSelectedDifficulty] = useState<DifficultyLevel | "">("");
  const [selectedGradeLevel, setSelectedGradeLevel] = useState<gradeLevel | "">("");

  return (
    <Container maxWidth="md" sx={{ py: 5, px: 2 }}>
      <div>
        <label>ZŠ stupeň:   </label>
        <Button
          variant="outlined"
          color={selectedGradeLevel === "first" ? "success" : "inherit"}
          style={{ marginRight: "5px" }}
          onClick={() => setSelectedGradeLevel(selectedGradeLevel === "first" ? "" : gradeLevel.first)}
        >
          1.Stupeň
        </Button>
        <Button
          variant="outlined"
          color={selectedGradeLevel === "second" ? "success" : "inherit"}
          onClick={() => setSelectedGradeLevel(selectedGradeLevel === "second" ? "" : gradeLevel.second)}
        >
          2.Stupeň
        </Button>
      </div>
      <br />
      <div>
        <label>Obtížnost: </label>
        <Button
          variant="outlined"
          color={selectedDifficulty === DifficultyLevel.EASY ? "success" : "inherit"}
          style={{ marginRight: "2.5px" }}
          onClick={() => setSelectedDifficulty(selectedDifficulty === DifficultyLevel.EASY ? "" : DifficultyLevel.EASY)}
        >
          {DifficultyLevel.EASY}
        </Button>
        <Button
          variant="outlined"
          color={selectedDifficulty === DifficultyLevel.MEDIUM ? "success" : "inherit"}
          style={{ marginRight: "2.5px", marginLeft: "2.5px" }}
          onClick={() =>
            setSelectedDifficulty(selectedDifficulty === DifficultyLevel.MEDIUM ? "" : DifficultyLevel.MEDIUM)
          }
        >
          {DifficultyLevel.MEDIUM}
        </Button>
        <Button
          variant="outlined"
          color={selectedDifficulty === DifficultyLevel.HARD ? "success" : "inherit"}
          style={{ marginRight: "2.5px", marginLeft: "2.5px" }}
          onClick={() => setSelectedDifficulty(selectedDifficulty === DifficultyLevel.HARD ? "" : DifficultyLevel.HARD)}
        >
          {DifficultyLevel.HARD}
        </Button>
      </div>
      <br />
      {courseCardData
        .filter(
          (course) =>
            (selectedDifficulty ? course.difficultyLevel === selectedDifficulty : true) &&
            (selectedGradeLevel ? course.gradeLevel === selectedGradeLevel : true)
        )
        .map((courseCard, index) => (
          <div key={index}>
            <CourseCard
              price={courseCard.price}
              courseDuration={courseCard.courseDuration}
              capacity={courseCard.capacity}
              suitableFor={courseCard.suitableFor}
              language={courseCard.language}
              title={courseCard.title}
              description={courseCard.description}
              difficultyLevel={courseCard.difficultyLevel}
              conditions={courseCard.conditions ? courseCard.conditions : ""}
              gradeLevel={courseCard.gradeLevel}
              isDarkTheme={false} // isDarkTheme={isDarkTheme}
            />
            <br />
          </div>
        ))}
    </Container>
  );
}

export default Courses;
