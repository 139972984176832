// Main.tsx
import React from "react";
import Main2 from "./Main2";
import Main3 from "./Main3";
// import Contact from './MainContact';
import { FaUsers, FaFileAlt, FaLaptop } from "react-icons/fa"; // Importing the required icons
import "./Main.css";
import { Link } from "react-router-dom";

interface MainProps {
  userId: string;
}

const Main = ({userId}: MainProps) => {
  return (
    <>
      <Main2 userId={userId}/>
      <br />
      <div className="container">
        <div className="header">
          <h1>Kurzy programování</h1>
          <p>Získejte nebo rozšiřte své programátorské dovednosti díky naší široké nabídce kurzů programování!</p>
        </div>
        <div className="courseContainer">
          <div className="courseBox">
            <FaUsers className="icon" />
            <h2>Pro koho jsou kurzy určeny?</h2>
            <p>
            Kurzy jsou určeny pro <strong>žáky základních škol</strong>, kteří si chtějí při výuce rozvíjet logické myšlení.
              základy programování.
            </p>
            <a href="#/courses">Prohlédněte si nabídku kurzů</a>
          </div>
          <div className="courseBox">
            <FaFileAlt className="icon" />
            <h2>Kde se kurzy konají?</h2>
            <p>
            Kurzy programování probíhají <strong>přímo na vybraných základních školách</strong> v učebně informatiky. Proto
              Vaše dítě nemusí nikam cestovat, přijedeme za ním.
            </p>
            <Link to="schools">Zobrazit školy s kroužky</Link>
          </div>
          <div className="courseBox">
            <FaLaptop className="icon" />
            <h2>Jak dlouho kurz trvá?</h2>
            <p>
            Kurzy trvají v zásadě <strong>celé pololetí</strong>, (60 min. | 1x týdně), pokud nedojde k dohodě s ředitelem školy.
              nebo rodiči jinak. Počet studentů v jednom kurzu je omezen na 10-12 účastníků.
            </p>
            <Link to="coursesWork">Více informací o kurzech</Link>
          </div>
        </div>
      </div>
      <br></br>
      <div className="container5">
        <Main3 />
      </div>
      <br></br>
      {/* <div className="container6">
      <Contact />
    </div> */}
      <br></br>
    </>
  );
};

export default Main;
