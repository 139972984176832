import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import the useHistory hook
import "./CoursesOfSchool.css";

type Course = {
  _id: string;
  schoolName: string;
  lectorName?: string;
  language: string;
  lectorId?: string;
  time: string;
  schoolId: string;
  monthPayment: number;
  capacity: number;
  childrens?: string[];
  attendIds?: string[];
  startDate: Date;
  endDate: Date;
};

const CoursesOfSchool = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { schoolId } = useParams();
  const [schoolName, setSchoolName] = useState<string>("");
  const [selectedCourseId, setSelectedCourseId] = useState<string | null>(null);
  const navigate = useNavigate(); // Use the useHistory hook
  const [detailsClicked, setDetailsClicked] = useState<boolean>(false);

  let url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const getCourses = async () => {
      try {
        const response = await fetch(url + `course/getCoursesInSchool/${schoolId}`, {
          headers: {
            "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch courses");
        }
        const data = await response.json();
        setCourses(data.coursesInSchool);
      } catch (err) {
        setError("An error occurred while retrieving the courses in school");
        console.error(err);
      }
    };

    if (schoolId) {
      getCourses();
    }
  }, [schoolId, url]);

  useEffect(() => {
    courses.forEach((course) => {
      // set first school name
      setSchoolName(course.schoolName);
    });
  }, [courses]);

  const handleShowDetails = (courseId: string) => {
    // if details are already shown, hide them
    if (detailsClicked === true) {
      setDetailsClicked(false);
    } else {
      setDetailsClicked(true);
    }

    setSelectedCourseId((prevId) => (prevId === courseId ? null : courseId));
  };

  const handleInterest = (courseId: string) => {
    // sem vložte kód, ktorý sa má vykonať pri kliknutí na tlačidlo "Mám záujem"
    navigate("/course-interest");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="courses-container">
      <div style={{ textAlign: "center" }}>
        <button className="back-to-schools-btn" onClick={handleBack}>
          Späť na zoznam škôl
        </button>
      </div>
      {error ? <p className="error-message">{error}</p> : null}
      {courses.length === 0 ? (
        <>
          <div style={{ textAlign: "center" }}>
            <p className="no-courses-message">Na zvolenej škole nie sú zatiaľ žiadne dostupné krúžky</p>
            <button style={{ backgroundColor: "blue" }} onClick={() => handleInterest("")}>
              Mám záujem v prípade prihlásenia nového krúžku
            </button>
          </div>
        </>
      ) : (
        <div className="courses-header">
          <h3 style={{ display: "inline", marginLeft: "100px" }}>Zoznam krúžkov: </h3>
          <h2 style={{ display: "inline" }}>{schoolName}</h2>
        </div>
      )}
      <ul className="courses-list">
        {courses.map((course) => (
          <React.Fragment key={course.schoolId}>
            <li className="course-item">
              <span>
                {course.language} - {course.time}
              </span>
              <button onClick={() => handleShowDetails(course._id)}>Detaily</button>
            </li>
            {selectedCourseId === course._id && (
              <li className="course-detail-item">
                <div className="course-detail">
                  <p>
                    <strong>Progamovaci jazyk:</strong> {course.language}
                  </p>
                  <p>
                    <strong>Čas kurzu:</strong> {course.time}
                  </p>
                  <p>
                    <strong>Lektor:</strong> {course.lectorName || "Zatiaľ nepriradený"}
                  </p>
                  <p>
                    <strong>Cena:</strong> {course.monthPayment} eur / hodina
                  </p>
                  <p>
                    <strong>Kapacita:</strong> {course.capacity > 12 ? 12 : course.capacity}
                  </p>
                  <p>
                    <strong>Voľné miesta: </strong>
                    {Math.min(10, course.capacity - (course.childrens ? course.childrens.length : 0))}
                    {course.capacity - (course.childrens ? course.childrens.length : 0) > 0 ? (
                      <span role="img" aria-label="Available">
                        {" "}
                        ✅
                      </span> // zelená check fajka
                    ) : (
                      <>
                        <span role="img" aria-label="Not Available" style={{ marginRight: "20px", marginLeft: "20px" }}>
                          {" "}
                          ❌
                        </span>
                        <button style={{ backgroundColor: "blue" }} onClick={() => handleInterest(course._id)}>
                          Mám záujem v prípade voľného miesta
                        </button>
                      </>
                    )}
                  </p>
                  <p className="login-note">Pre úspešnú prihlášku dieťaťa do krúžku je potrebné prihlásenie!</p>
                </div>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
      {detailsClicked === true ? null : (
        <p className="login-note">Pre úspešnú prihlášku dieťaťa do krúžku je potrebné prihlásenie!</p>
      )}
    </div>
  );
};

export default CoursesOfSchool;
