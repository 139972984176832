import { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  InputLabel,
  FormControl,
  Select,
  Box,
  Typography,
  Tooltip,
  MenuItem,
  Button,
} from "@mui/material";
import GeneralTermsAndConditions from "../PersonalTerms/GeneralTermsAndConditions";
import PersonalDataAgreement from "../PersonalTerms/PersonalDataAgreement";
import InfoIcon from "@mui/icons-material/Info";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import { fetchSchools } from "../../utils/fetchSchools";
import Cookie from "js-cookie";
import { CSSProperties } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

// type Child = {
//   name: string;
//   className: string;
// };

interface UserChildrensProps {
  userId: string;
  userData: any;
  updateUserData: () => void;
}

interface Course {
  _id: string;
  attendIds: string[];
  capacity: number;
  endDate: Date;
  language: string;
  lectorId: string;
  lectorName: string;
  monthPayment: number;
  schoolId: string;
  schoolName: string;
  startDate: Date;
  time: string;
  childrens: string[];
}

interface newChildProps {
  name: string;
  className: string;
  userId?: string;
  schoolId?: string;
  courseId?: string;
}

interface School {
  _id: string; // Use _id as the unique identifier
  name: string;
  location: string;
  town: string;
  address: string; // Add address property
  imgURL: string; // Add imgURL property
  // Add other properties as needed
}

const ChildrenRegistration = ({ userId, userData, updateUserData }: UserChildrensProps) => {
  const [newChildren, setNewChildren] = useState<newChildProps>({
    name: "",
    className: "",
    userId: "",
    schoolId: "",
    courseId: "",
  });
  const [selectedTown, setSelectedTown] = useState<string>("");
  const [selectedSchoolId, setSelectedSchoolId] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [selectedCourseId, setSelectedCourseId] = useState<string>("");
  const [isChecked, setIsChecked] = useState(false); // Personal terms agreement
  const [userSignedPersonalData, setUserSignedPersonalData] = useState(Boolean); // Personal terms agreement
  const [isVOPChecked, setIsVOPChecked] = useState(false); // Personal terms agreement
  const [selectedPayment, setSelectedPayment] = useState<string>("");
  const { token } = useAuth();
  const [selectedCourse, setSelectedCourse] = useState<Course>({} as Course); // TODO ADD selectedCourse
  const [userSignedVOP, setUserSignedVOP] = useState(Boolean); // Personal terms agreement
  const [schools, setSchools] = useState<School[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [freeCoursePlaces, setFreeCoursePlaces] = useState<number>(0);
  const [showCourseDetails, setShowCourseDetails] = useState<boolean>(false);
  const navigate = useNavigate();

  if (!userData.city || !userData.phoneNumber || !userData.postCode || !userData.name || !userData.surname) {
    navigate("/profile");
  }
  useEffect(() => {
    if (!userData.city || !userData.phoneNumber || !userData.postCode || !userData.name || !userData.surname) {
      navigate("/profile");
    }
  }, [userData, navigate]);

  // if successMessage navigate to payments after 3 seconds
  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        navigate("/payments");
      }, 2500);
    }
  }, [successMessage, navigate]);

  const successMessageStyle: CSSProperties = {
    marginBottom: "15px",
    color: "green",
    border: "1px solid green",
    padding: "8px 12px",
    borderRadius: "5px",
    boxShadow: "0 4px 6px rgba(0, 255, 0, 0.1)",
    textAlign: "center",
    fontWeight: "bold",
  };

  const errorMessageStyle: CSSProperties = {
    marginBottom: "15px",
    color: "red",
    border: "1px solid red",
    padding: "8px 12px",
    borderRadius: "5px",
    boxShadow: "0 4px 6px rgba(255, 0, 0, 0.1)",
    textAlign: "center",
    fontWeight: "bold",
  };

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  // if selectedLanguage or selectedSchoolId is changed, set selectedCourseId to empty string
  useEffect(() => {
    setSelectedCourseId("");
  }, [selectedLanguage, selectedSchoolId]);

  // if selectedTown is changed, set selectedSchoolId to empty string
  useEffect(() => {
    setSelectedSchoolId("");
  }, [selectedTown]);

  useEffect(() => {
    setUserSignedPersonalData(userData.signedVOP);
  }, [userData.signedVOP]);

  useEffect(() => {
    setUserSignedVOP(userData.signedPersonalData);
  }, [userData.signedPersonalData]);

  useEffect(() => {
    // Fetch schools data using the fetchSchools function
    if (selectedTown !== "" && selectedTown !== undefined) {
      fetchSchools(selectedTown, false)
        .then((data) => setSchools(data))
        .catch((error) => console.log(error));
    }
  }, [selectedTown, url]);

  useEffect(() => {
    if (selectedSchoolId && selectedLanguage) {
      const fetchChildrenData = async () => {
        if (userId === "" || userId === undefined) {
          return;
        }
        try {
          const response = await fetch(
            url + "course/getCoursesBySchoolIdAndLanguage/" + selectedSchoolId + "/" + selectedLanguage,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
              },
            }
          );
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          // TODO - set childrens to the data from the response
          setCourses(data.coursesInSchool);
        } catch (error) {
          console.error("Error fetching children data:", error);
        }
      };
      fetchChildrenData();
    }
  }, [url, userId, selectedSchoolId, selectedLanguage, token]);

  const checkValues = () => {
    if (newChildren.name === "") {
      setErrorMessage("Zadajte meno dieťaťa.");
    }
    if (newChildren.className === "") {
      setErrorMessage("Zadajte triedu dieťaťa.");
    }
    if (selectedTown === "") {
      setErrorMessage("Vyberte mesto.");
    }
    if (selectedSchoolId === "") {
      setErrorMessage("Vyberte školu.");
    }
    if (selectedLanguage === "") {
      setErrorMessage("Vyberte jazyk.");
    }
    if (selectedCourseId === "") {
      setErrorMessage("Vyberte kurz.");
    }
    if (selectedPayment === "") {
      setErrorMessage("Vyberte spôsob platby.");
    }
    if (!userSignedVOP) {
      setErrorMessage("Súhlas s podmienkami.");
    }
    if (!userSignedPersonalData) {
      setErrorMessage("Súhlas s VOP.");
    }
    // freeCoursePlaces
    if (showCourseDetails && freeCoursePlaces <= 0) {
      setErrorMessage("Kurz nemá voľné miesta.");
      return false;
    }
    if (
      newChildren.name === "" ||
      newChildren.className === "" ||
      selectedTown === "" ||
      selectedSchoolId === "" ||
      selectedLanguage === "" ||
      selectedCourseId === "" ||
      selectedPayment === "" ||
      !userSignedVOP ||
      !userSignedPersonalData
    ) {
      return false;
    }
    return true;
  };

  const handleCourseSelect = (e: SelectChangeEvent) => {
    if (e.target.value === "") {
      setSelectedCourse({} as Course);
      setSelectedCourseId("");
      return;
    }

    // No need to call setSelectedCourseId here as it's already called in the Select's onChange handler

    const selectedCourse = courses.find((course) => course._id === e.target.value);
    setSelectedCourse(selectedCourse as Course);
    if (selectedCourse) {
      let freePlaces = selectedCourse.capacity - selectedCourse.childrens.length;
      setFreeCoursePlaces(freePlaces);
    }
    setShowCourseDetails(true);
  };

  const createChildren = async () => {
    const res = checkValues();
    if (res) {
      const body = {
        name: newChildren.name,
        className: newChildren.className,
        userId: userId,
        schoolId: selectedSchoolId,
        courseId: selectedCourseId,
        paymentMethod: selectedPayment,
      };

      try {
        const response = await fetch(url + "children/register", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        });

        if (!response.ok) {
          setErrorMessage("Chyba pri registrácii dieťa."); // Error message
          throw new Error("Network response was not ok.");
        }

        await response.json();
        setSuccessMessage("Úspešne zaregistrovanie dieťa."); // Success message
        updateUserData();
        // empty the newChildren object
        newChildren.name = "";
        newChildren.className = "";
        setSelectedSchoolId("");
        setSelectedCourseId("");
        setSelectedCourse({} as Course);
        setSelectedLanguage("");
        setSelectedTown("");
        setSelectedPayment("");
      } catch (error) {
        setErrorMessage("Chyba pri registrácii dieťa."); // Error message
        console.error("Error fetching children data:", error);
      }
    }
  };

  const languages = [
    {
      _id: "1",
      name: "Python",
    },
    {
      _id: "2",
      name: "Scratch",
    },
    {
      _id: "3",
      name: "Roblox",
    },
  ];

  const towns = [
    {
      _id: "0",
      name: "Banska Bystrica",
    },
    {
      _id: "1",
      name: "Bratislava",
    },
    {
      _id: "2",
      name: "Kosice",
    },
    {
      _id: "3",
      name: "Nitra",
    },
  ];

  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <Box bgcolor="#f7f7f7" border="1px solid #ddd" p={3} maxWidth="1100px" margin="20px auto">
      <Typography variant="h4" gutterBottom>
        Registrácia dieťaťa na kurz
      </Typography>
      <Grid container spacing={3} className="registration-container">
        <Grid item xs={12} md={3}>
          <TextField
            label="Meno a priezvisko žiaka"
            value={newChildren.name}
            onChange={(e) => setNewChildren({ ...newChildren, name: e.target.value })}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            label="Trieda žiaka"
            value={newChildren.className}
            onChange={(e) => setNewChildren({ ...newChildren, className: e.target.value })}
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Zvoľte mesto:</InputLabel>
            <Select value={selectedTown} onChange={(e) => setSelectedTown(e.target.value as string)}>
              {towns.map((town) => (
                <MenuItem key={town._id} value={town.name}>
                  {town.name}
                </MenuItem>
              ))}{" "}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Zvoľte školu:</InputLabel>
            <Select value={selectedSchoolId} onChange={(e) => setSelectedSchoolId(e.target.value as string)}>
              {schools.map((school) => (
                <MenuItem key={school._id} value={school._id}>
                  {school.name}
                </MenuItem>
              ))}{" "}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Vyberte programovací jazyk:</InputLabel>
            <Select value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value as string)}>
              {languages.map((language) => (
                <MenuItem key={language._id} value={language.name}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Vyberte kurz:</InputLabel>
            <Select
              value={selectedCourseId}
              onChange={(e) => {
                setSelectedCourseId(e.target.value as string);
                handleCourseSelect(e);
              }}
            >
              {courses.map((course) => (
                <MenuItem key={course._id} value={course._id}>
                  {course.time}
                </MenuItem>
              ))}{" "}
            </Select>
          </FormControl>
          {showCourseDetails &&
            (freeCoursePlaces > 0 ? <p>Voľné miesta: {freeCoursePlaces > 12 ? 12 : freeCoursePlaces }</p> : <p>Nie sú voľné miesta</p>)}
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth>
            <InputLabel>Zvoľte spôsob platby:</InputLabel>
            <Select value={selectedPayment} onChange={(e) => setSelectedPayment(e.target.value as string)}>
              <MenuItem value="">-- Zvoľte spôsob platby --</MenuItem>
              <MenuItem value="once">Jednorazová platba</MenuItem>
              <MenuItem value="monthly">Mesačná platba</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {showCourseDetails && (
          <Grid item xs={12} md={3}>
            <Typography variant="body2">
              Cena pri jednorázovej platbe: {selectedCourse.monthPayment * 0.9} €/hod
              <Tooltip title="Jednorázová platba poskytuje možnosť získať 10% zľavu na hodinovú sadzbu">
                <InfoIcon className="info-icon" />
              </Tooltip>
            </Typography>
            <Typography variant="body2">Cena pri mesačnej platbe: {selectedCourse.monthPayment} €/hod</Typography>
          </Grid>
        )}

        <Grid container alignItems="center" justifyContent="center" style={{ marginTop: "20px" }} spacing={3}>
          <Grid item xs={12} md={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <InputLabel style={{ textAlign: "center", marginBottom: "10px" }}>Všeobecné obchodné podmienky:</InputLabel>

            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <GeneralTermsAndConditions
                userId={userId}
                token={token ? token : ""}
                userData={userData}
                isChecked={isChecked}
                onCheckboxChange={setIsChecked}
                userSignedPersonalData={userSignedPersonalData}
                updateUserData={updateUserData}
              />
            </div>
          </Grid>

          <Grid item xs={12} md={6} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <InputLabel style={{ textAlign: "center", marginBottom: "10px" }}>
              Súhlas so spracovaním osobných údajov:
            </InputLabel>

            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
              <PersonalDataAgreement
                isChecked={isVOPChecked}
                onCheckboxChange={setIsVOPChecked}
                userSignedPersonalData={userSignedVOP}
                updateUserData={updateUserData}
                userId={userId}
                token={token ? token : ""}
                userData={userData}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ textAlign: "center", marginTop: "20px" }}>
          {successMessage && (
            <>
            <div style={successMessageStyle}>
              <span style={{ marginRight: "5px" }}>✓</span>
              {successMessage}
            </div>
            <div style={successMessageStyle}>
            <span style={{ marginRight: "5px" }}>✓</span>
            Na Váš email bol odoslaný potvrdzovací email.
            Prosím, skontrolujte si email, poprípade aj SPAM! 
          </div>
          </>
          )}

          {/* Error message */}
          {errorMessage && !successMessage && (
            <div style={errorMessageStyle}>
              <span style={{ marginRight: "5px" }}>✗</span>
              {errorMessage}
            </div>
          )}
          <Button variant="contained" color="primary" onClick={createChildren}>
            Záväzne prihlásiť dieťa na kurz
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChildrenRegistration;
