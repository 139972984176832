import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./style.css"; // Import the CSS file for styling
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import { formatDate } from '../../utils/date';

interface Course {
  _id: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolId: string;
  // Add other properties as needed
}

const CourseDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [course, setCourse] = useState<Course | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedCourse, setEditedCourse] = useState<Course | null>(null);
  const navigate = useNavigate();
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await fetch(url + `course/${id}`, {
          headers: {
            "Content-Type": "application/json",
            "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setCourse(data.course);
      } catch (error) {
        console.error("Error fetching course details:", error);
      }
    };
    fetchCourseDetails();
  }, [id, url]);

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedCourse(course);
  };

  const handleSaveClick = async () => {
    try {
      const response = await fetch(url + `course/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editedCourse),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      setIsEditing(false);
      setCourse(editedCourse);
    } catch (error) {
      console.error("Error updating course:", error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedCourse((prevCourse) => ({
      ...(prevCourse as Course),
      [name]: value,
    }));
  };

  const handleLectorDetails = (id: string) => async () => {
    navigate('/lector/' + id + '/details');
};

  
  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }
        
  return (
    <div className="course-details-container">
      {course ? (
        <>
          <h2>Course Details</h2>
          <div className="course-details">
            <span className="course-info">Course ID: {course._id}</span>
            {isEditing ? (
              <>
                <span className="course-info">
                  Language:
                  <input
                    type="text"
                    name="language"
                    value={editedCourse?.language || ""}
                    onChange={handleInputChange}
                    className="course-info-edit"
                  />
                </span>
                <span className="course-info">
                  Start date:
                  <input
                    type="text"
                    name="startDate"
                    value={editedCourse?.startDate || ""}
                    onChange={handleInputChange}
                    className="course-info-edit"
                  />
                </span>
                <span className="course-info">
                  End date: 
                  <input
                    type="text"
                    name="endDate"
                    value={editedCourse?.endDate || ""}
                    onChange={handleInputChange}
                    className="course-info-edit"
                  />
                </span>
                <span className="course-info">
                  Lector: 
                  <input
                    type="text"
                    name="lectorId"
                    value={editedCourse?.lectorId || ""}
                    onChange={handleInputChange}
                    className="course-info-edit"
                  />
                </span>
                <button onClick={handleSaveClick}>Save</button>
              </>
            ) : (
              <>
                <span className="course-info">Language: {course.language}</span>
                <span className="course-info">Start date: {formatDate(course.startDate)}</span>
                <span className="course-info">End date: {formatDate(course.endDate)}</span>
                <span className="course-info">School ID: {course.schoolId}</span>
                <span className="course-info">Lector: {course.lectorId} 
                <button onClick={handleLectorDetails(course.lectorId)}>Lector details</button> 
                </span>
                <button onClick={handleEditClick}>Edit</button>
              </>
            )}
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default CourseDetails;
