import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import "./style/adminCourse.css"; // Import the CSS file for styling
import { formatDate } from '../../utils/date';

interface Course {
  _id: string;
  time: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolName: string;
  lectorName: string;
  monthPayment: number;
  capacity: number;
  schoolId: string;
  childrens: string[];
  attendIds: string[];
  paymentActive: boolean;
  // Add other properties as needed
}

interface Lector {
  _id: string;
  name: string;
  surname: string;
}

interface AdminCourseProps {
  userId: string;
}

const AdminCourse = ({userId}: AdminCourseProps) => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [lectors, setLectors] = useState<Lector[]>([]);
  const [editedCourse, setEditedCourse] = useState<Course | null>(null);
  const [editedField, setEditedField] = useState<string>("");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const navigate = useNavigate();
  const [newCourse, setNewCourse] = useState<Course>({
    _id: "",
    time: "",
    language: "Python",
    startDate: "",
    endDate: "",
    lectorId: "",
    schoolName: "",
    lectorName: "",
    monthPayment: 0,
    capacity: 0,
    schoolId: "",
    childrens: [],
    attendIds: [],
    paymentActive: false,
  });
  const [schoolNames, setSchoolNames] = useState<string[]>([]);
  const [schools, setSchools] = useState<any[]>([]);
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchCourses = async () => {
      if (!userId) {
        return;
      }
      try {
        const response = await fetch(url + "course/allCourses", 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setCourses(data.courses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    const fetchLectors = async () => {
      if (!userId) {
        return;
      }
      try {
        const response = await fetch(url + "lector/allLectors",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setLectors(data);
      } catch (error) {
        console.error("Error fetching lectors:", error);
      }
    };

    const fetchSchoolNames = async () => {
      if (!userId) {
        return;
      }
      try {
        const response = await fetch(url + "school/allSchools",
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
            "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
          },
        }); 
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setSchools(data.schools);
        setSchoolNames(data.schools && data.schools.map((school: any) => school.name));
      } catch (error) {
        console.error("Error fetching school names:", error);
      }
    };

    fetchCourses();
    fetchLectors();
    fetchSchoolNames();
  }, [token, url, userId]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>
): void => {
    const { name, value } = event.target; // Destructure name and value

    setEditedField(name);
    if (editedCourse) {
      setEditedCourse({ ...editedCourse, [name]: value });
    }
};


  const handleSaveChanges = async (): Promise<void> => {
    if (editedCourse) {
      try {
        const response = await fetch(url + `course/${editedCourse._id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId // custom header for user ID
          },
          body: JSON.stringify(editedCourse),
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        setCourses(courses.map((course) => (course._id === editedCourse._id ? editedCourse : course)));
        setIsEditing(false);
        setEditedField("");
      } catch (error) {
        console.error("Error updating course:", error);
      }
    }
  };

  const handleCreateCourse = async () => {
    try {
      // get schoolId from schoolName
      const schoolPost = schools.find((school) => school.name === newCourse.schoolName);
      const schoolIdPost = schoolPost._id;
      newCourse.schoolId = schoolIdPost;

      // if some of newCourse properties are empty, remove them
      Object.keys(newCourse).forEach((key) => {
        if (newCourse[key as keyof Course] === "") {
          delete newCourse[key as keyof Course];
        }
      });

      // lectorName from lectorId
      const lectorPost = lectors.find((lector) => lector._id === newCourse.lectorId);
      const lectorNamePost = lectorPost && lectorPost.name + " " + lectorPost.surname;
      newCourse.lectorName = lectorNamePost || ""; // Set an empty string if lectorNamePost is undefined

      const response = await fetch(url + "course/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
        body: JSON.stringify(newCourse),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setCourses([...courses, data.course]);

      // get schoolId from schoolName
      setNewCourse({
        _id: "",
        time: "",
        language: "Python",
        startDate: "",
        endDate: "",
        lectorId: "",
        schoolName: "",
        lectorName: "",
        monthPayment: 0,
        capacity: 0,
        schoolId: "",
        attendIds: [],
        childrens: [],
        paymentActive: true,
      });
    } catch (error) {
      console.error("Error creating course:", error);
    }
  };

  const asignLectorToCourse = async (courseId: string): Promise<void> => {
    navigate("/assign-lector-to-course/" + courseId);

    // TODO: implement
  };

   // if token is null try token from cookies
   if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <>
    <div className="admin-course-container">
      <h3>Add new Course</h3>
      <div className="new-course-form">
        <label htmlFor="name">Course Name</label>
        <input
          type="text"
          value={newCourse.time}
          onChange={(e) => setNewCourse({ ...newCourse, time: e.target.value })}
          placeholder="Course Name"
          className="input-field"
        />
        <label htmlFor="language">Language:</label>
        <select
          value={newCourse.language}
          onChange={(e) => setNewCourse({ ...newCourse, language: e.target.value })}
          className="input-field"
        >
          <option value="Python">Python</option>
          <option value="Scratch">Scratch</option>
          <option value="Roblox">Roblox</option>
          {/* Add more options here if needed */}
        </select>
        <label htmlFor="startDate">Start Date:</label>
        <input
          type="date"
          value={newCourse.startDate}
          onChange={(e) => setNewCourse({ ...newCourse, startDate: e.target.value })}
          className="input-field"
        />
        <label htmlFor="endDate">End Date:</label>
        <input
          type="date"
          value={newCourse.endDate}
          onChange={(e) => setNewCourse({ ...newCourse, endDate: e.target.value })}
          className="input-field"
        />
        <label htmlFor="monthPayment">Month Payment:</label>
        <input
          type="number"
          value={newCourse.monthPayment}
          onChange={(e) => setNewCourse({ ...newCourse, monthPayment: parseInt(e.target.value) })}
          placeholder="Month Payment"
          className="input-field"
        />
        <label htmlFor="capacity">Capacity:</label>
        <input
          type="number"
          value={newCourse.capacity}
          onChange={(e) => setNewCourse({ ...newCourse, capacity: parseInt(e.target.value) })}
          placeholder="Capacity"
          className="input-field"
        />
        <label htmlFor="schoolName">School Name:</label>
        <select
          value={newCourse.schoolName}
          onChange={(e) => setNewCourse({ ...newCourse, schoolName: e.target.value })}
          className="input-field"
        >
          <option value="" disabled>
            Select School
          </option>
          {schoolNames &&
            schoolNames.map((schoolName) => (
              <option key={schoolName} value={schoolName}>
                {schoolName}
              </option>
            ))}
        </select>
        <label htmlFor="lectorId">Lector:</label>
        <select
          value={newCourse.lectorId}
          onChange={(e) => setNewCourse({ ...newCourse, lectorId: e.target.value })}
          className="input-field"
        >
          <option value="" disabled>
            Select Lector
          </option>
          {lectors &&
            lectors.map((lector) => (
              <option key={lector._id} value={lector._id}>
                {lector.name + " " + lector.surname}
              </option>
            ))}
        </select>
        <button className="create-button" onClick={handleCreateCourse}>
          Create Course
        </button>
      </div>
      </div>
      <div className="course-list">
      <h3>List of Courses</h3>
      {courses &&
        courses.map((course) => (
          <div key={course._id} className="course-item">
            {isEditing && editedCourse?._id === course._id ? (
              <>
                <input
                  type="text"
                  name="time"
                  value={editedCourse.time}
                  onChange={handleInputChange}
                  className={`course-info-edit ${editedField === "time" ? "edited" : ""}`}
                />
                <select
                  name="language"
                  value={editedCourse.language}
                  onChange={handleInputChange}
                  className={`course-info-edit ${editedField === "language" ? "edited" : ""}`}
                >
                  <option value="Python">Python</option>
                  <option value="Scratch">Scratch</option>
                  {/* Add more options here if needed */}
                </select>
                <input
                  type="date"
                  name="startDate"
                  value={editedCourse.startDate}
                  onChange={handleInputChange}
                  className={`course-info-edit ${editedField === "startDate" ? "edited" : ""}`}
                />
                <input
                  type="date"
                  name="endDate"
                  value={editedCourse.endDate}
                  onChange={handleInputChange}
                  className={`course-info-edit ${editedField === "endDate" ? "edited" : ""}`}
                />
                <input
                  type="number"
                  name="monthPayment"
                  value={editedCourse.monthPayment}
                  onChange={handleInputChange}
                  className={`course-info-edit ${editedField === "monthPayment" ? "edited" : ""}`}
                />
                <input
                  type="number"
                  name="capacity"
                  value={editedCourse.capacity}
                  onChange={handleInputChange}
                  className={`course-info-edit ${editedField === "capacity" ? "edited" : ""}`}
                />
                <select
                  name="lectorId"
                  value={editedCourse.lectorId}
                  onChange={handleInputChange}
                  className={`course-info-edit ${editedField === "lectorId" ? "edited" : ""}`}
                >
                  <option value="" disabled>
                    Select Lector
                  </option>
                  {lectors &&
                    lectors.map((lector) => (
                      <option key={lector._id} value={lector._id}>
                        {lector.name}
                      </option>
                    ))}
                </select>
                <select
                  name="paymentActive"
                  value={editedCourse.paymentActive ? "true" : "false"}
                  onChange={handleInputChange}
                  className={`course-info-edit ${editedField === "paymentActive" ? "edited" : ""}`}
                >
                  <option value={'true'}>Active</option>
                  <option value={'false'}>Inactive</option>
                </select>
                <button onClick={handleSaveChanges}>Save</button>
                <button onClick={() => setIsEditing(false)}>Cancel</button>
              </>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Language</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Lector ID</th>
                    <th>Lector Name</th>
                    <th>School Name</th>
                    <th>Month Payment</th>
                    <th>Capacity</th>
                    <th>Students</th>
                    <th>Free Places</th>
                    <th>Attendance IDs</th>
                    <th>Edit</th>
                    <th>Course Details</th>
                  </tr>
                </thead>
                <tbody>
                  {courses.map((course) => (
                    <tr key={course._id} className="course-item">
                      <td>{course.time}</td>
                      <td>{course.language}</td>
                      <td>{formatDate(course.startDate)}</td>
                      <td>{formatDate(course.endDate)}</td>
                      <td>
                        {course.lectorId}
                        {course.lectorId === undefined || course.lectorId === "" ? (
                          <button className="edit-button" onClick={() => asignLectorToCourse(course._id)}>
                            Assign Lector
                          </button>
                        ) : null}
                      </td>
                      <td>{course.lectorName ? `Lector name: ${course.lectorName}` : null}</td>
                      <td>{course.schoolName}</td>
                      <td>{course.monthPayment}</td>
                      <td>{course.capacity}</td>
                      <td>{course.childrens && course.childrens.length}</td>
                      <td>{course.capacity - (course.childrens ? course.childrens.length : 0)}</td>
                      <td>{course.attendIds && course.attendIds.join(', ')}</td>
                      <td>
                        <button
                          onClick={() => {
                            setEditedCourse(course);
                            setIsEditing(true);
                          }}
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <Link to={`/course/details/${course._id}`}>
                          <button>Course Details</button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        ))}
    </div>
  </>
  );
};

export default AdminCourse;
