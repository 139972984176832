import React, { useState, useEffect, useCallback } from "react";
import { TableBody, TableRow, TableCell, Button, TableHead, Table } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import "./style/adminAttendance.css";

interface School {
  _id: string;
  id: string;
  name: string;
}

interface Course {
  _id: string;
  time: string;
  id: string;
  name: string;
  schoolId: string;
  details: string;
}

interface Attendance {
  _id: string;
  courseId: string;
  lectorId: string;
  date: Date;
  attended: string[];
  absent: string[];
  apologies: string[];
  note: string;
  held: boolean;
}

interface AdminAttendanceProps {
  userId: string;
}

const AdminAttendance = ({userId}: AdminAttendanceProps) => {
  const [allSchools, setAllSchools] = useState<School[]>([]);
  const [selectedSchoolId, setSelectedSchoolId] = useState<string>("");
  const [coursesBySchool, setCoursesBySchool] = useState<Course[]>([]);
  const [selectedCourseId, setSelectedCourseId] = useState<string>("");
  const [schoolId, setSchoolId] = useState<string>("");
  const [arrayOfCourseAttendance, setArrayOfCourseAttendance] = useState<Attendance[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const navigate = useNavigate();
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  // Fetch all schools
  const fetchAllSchools = useCallback(async () => {
    try {
      // Fetch data from your API or server
      // Replace 'your_api_url' with the actual API endpoint for all schools
      const response = await fetch(url + "school/allSchools",
       {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
          "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
        },
      }); 
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setAllSchools(data.schools);
    } catch (error) {
      console.error("Error fetching all schools:", error);
    }
  }, [token, url, userId]);

  useEffect(() => {
    fetchAllSchools();
  }, [fetchAllSchools]);

  const fetchCoursesBySchool = useCallback(async () => {
    // get id from selectedSchoolId which is actually school name from allSchools
    allSchools.forEach((school) => {
      if (school.name === selectedSchoolId) {
        setSchoolId(school._id);
      }
    });
    if (!schoolId) {
      return;
    }
    try {
      // Fetch data from your API or server
      // Replace 'your_api_url' with the actual API endpoint for all courses by school
      const response = await fetch(url + "course/getCoursesInSchool/" + schoolId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
          "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
        },
      }); 
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setCoursesBySchool(data.coursesInSchool);
    } catch (error) {
      console.error("Error fetching all courses:", error);
    }
  }, [allSchools, schoolId, selectedSchoolId, url, token, userId]);

  useEffect(() => {
    fetchCoursesBySchool();
  }, [fetchCoursesBySchool]);

  // Handle selecting school
  const handleSchoolSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSchoolId(e.target.value);
    setSelectedCourseId("");
  };

  // Handle selecting course
  const handleCourseSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourseId(e.target.value);
    const courseTIme = e.target.value;
    coursesBySchool.forEach((course) => {
      if (course.time === courseTIme) {
        setSelectedCourseId(course._id);
      }
    });
  };

  // Handle Show All button click
  const handleShowAll = () => {
    setShowAll(true);
    getCourseDetails(selectedCourseId);
  };

  // Handle Show Details button click
  const handleShowDetails = (attendId: string) => {
    navigate(`/lector/attendance-history/${attendId}`);
  };

  const getCourseDetails = async (courseId: string) => {
    if (!courseId) {
      return;
    }
    // Fetch data from your API or server
    try {
      const response = await fetch(url + "attend/course/" + courseId,
       {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId // custom header for user ID
        },
      }); 
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      setArrayOfCourseAttendance(data.attendances);
    } catch (error) {
      console.error("Error fetching course details:", error);
    }
  };

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="admin-attendance-container">
      <h2>Admin Attendance</h2>
      <div className="school-select-container">
        <label>
          Select School:
          <select value={selectedSchoolId} onChange={handleSchoolSelect}>
            <option value="">-- Select School --</option>
            {allSchools &&
              allSchools.map((school) => (
                <option key={school._id} value={school.id}>
                  {school.name}
                </option>
              ))}
          </select>
        </label>
      </div>
      {selectedSchoolId && (
        <div className="course-select-container">
          <label>
            Select Course:
            <select value={selectedCourseId} onChange={handleCourseSelect}>
              <option value="">-- Select Course --</option>
              {coursesBySchool &&
                coursesBySchool.map((course) => (
                  <option key={course._id} value={course._id}>
                    {course.time}
                  </option>
                ))}
            </select>
          </label>
        </div>
      )}
      <button className="show-all-button" onClick={handleShowAll}>
        Show All
      </button>
      {showAll ? (
      <>
        <h2>Attendance for {selectedCourseId} course</h2>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Note</TableCell>
              <TableCell>Held</TableCell>
              <TableCell>Attended (count)</TableCell>
              <TableCell>Missed (count)</TableCell>
              <TableCell>Ill (count)</TableCell>
              <TableCell>Show Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrayOfCourseAttendance &&
              arrayOfCourseAttendance.map((student) => (
                <TableRow key={student._id}>
                  <TableCell>{student.date}</TableCell>
                  <TableCell>{student.note}</TableCell>
                  <TableCell>{student.held ? "Yes" : "No"}</TableCell>
                  <TableCell>{student.attended.length}</TableCell>
                  <TableCell>{student.absent.length}</TableCell>
                  <TableCell>{student.apologies.length}</TableCell>
                  <TableCell>
                    {" "}
                    <Button variant="contained" onClick={() => handleShowDetails(student._id)}>
                      Show Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        </>
      ) : null}
    </div>
  );
};

export default AdminAttendance;
