import React, { createContext, useContext, useEffect, useState } from "react";

interface AuthContextProps {
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  // Add other auth-related properties as needed
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  // Initialize other auth-related states as needed

  // if token is null try to get from cookies

  useEffect(() => {
    //token from cookies
    if (token === null || token === undefined) {
      const tokenFromCookies = document.cookie
        .split("; ")
        .find((row) => row.startsWith("token"))
        ?.split("=")[1];
      if (tokenFromCookies) {
        setToken(tokenFromCookies);
      }
    }
  }, [token]);

  return <AuthContext.Provider value={{ token, setToken }}>{children}</AuthContext.Provider>;
};
