import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./UserCourses.css";
import { formatDate } from "../../utils/date";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";
import { Typography } from "@mui/material";

interface Course {
  _id: string;
  time: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolName: string;
  lectorName: string;
  monthPayment: number;
  capacity: number;
  schoolId: string;
  childrens: string[];
  attendIds: string[];
  childrenName: string;
  // Add other properties as needed
}

const UserCourses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const { userId } = useParams<{ userId: string }>();
  const { token } = useAuth();

  let url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    const fetchCourses = async () => {
      if (!userId) {
        return;
      }
      if (!token) {
        return;
      }

      try {
        const response = await fetch(url + `course/getUsersCourses/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
        });
        const data = await response.json();
        setCourses(data.usersCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, [token, url, userId]);

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div className="courses">
      <Typography variant="h5" gutterBottom>
        Moje kurzy
      </Typography>{" "}
      {courses.length === 0 && <p>Žiadne kurzy</p>}
      {courses &&
        courses.map((course) => (
          <div key={course._id} className="course">
            <h2>
              {course.schoolName} - {course.language} - {course.time}{" "}
            </h2>
            <p>Dieťa: {course.childrenName}</p>
            <p>Čas: {course.time}</p>
            <p>Začiatok kurzu: {formatDate(course.startDate)}</p>
            <p>Koniec kurzu: {formatDate(course.endDate)}</p>
            <p>Lektor: {course.lectorName ? course.lectorName : "Zatiaľ nepripradený"}</p>
            <p>Kapacita: {course.capacity > 12 ? "10" : course.capacity}</p>
            <p>Škola: {course.schoolName}</p>
            <p>Programovací jazyk: {course.language}</p>
            <p>Cena: {course.monthPayment} €/hod</p>
          </div>
        ))}
    </div>
  );
};

export default UserCourses;
