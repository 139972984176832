import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Snackbar,
  Grid,
  TableContainer,
  Paper,
  Box,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import "./LectorAttendanceHistory.css";
import { useNavigate } from "react-router-dom";
import NotLoggedIn from "../Login/NotLoggedIn";
import { useAuth } from "../Auth/AuthContext";
import Cookie from "js-cookie";

interface Student {
  attended: Array<string>;
  absent: Array<string>;
  apologies: Array<string>;
  note: string;
  date: Date;
  held: boolean;
  _id: string;
  name: string;
  status: string; // "attended", "missed", "ill"
  className: string;
}

interface Course {
  _id: string;
  time: string;
  language: string;
  startDate: string;
  endDate: string;
  lectorId: string;
  schoolName: string;
  lectorName: string;
  monthPayment: number;
  capacity: number;
  schoolId: string;
  childrens: Student[];
  attendIds: string[];
  // Add other properties as needed
}

interface LectorAttendanceHistoryProps {
  lectorId: string;
}

const LectorAttendanceHistory: React.FC<LectorAttendanceHistoryProps> = ({ lectorId }) => {
  const [lectorCourses, setLectorCourses] = useState<Course[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const { token } = useAuth();

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  useEffect(() => {
    // fetch lector courses
    const fetchLectorCourses = async () => {
      if (!lectorId) {
        return;
      }
      try {
        const response = await fetch(url + `course/getLectorsCourses/${lectorId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-User-Id": lectorId, // custom header for user ID
          },
        });
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const data = await response.json();
        setLectorCourses(data.lectorCourses);
      } catch (error) {
        console.error("Error fetching lector courses:", error);
      }
    };

    fetchLectorCourses();
  }, [lectorId, token, url]);

  const handleCourseClick = async (course: Course) => {
    try {
      const response = await fetch(url + `attend/course/${course._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-User-Id": lectorId, // custom header for user ID
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const data = await response.json();
      course.childrens = data.attendances;
      setSelectedCourse(course);
    } catch (error) {
      console.error("Error fetching course attendance:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const showAttendanceDetails = (courseId: string) => {
    navigate(`/lector/attendance-history/${courseId}`);
  };

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }
  function formatDate(input: Date | string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    let date: Date;

    if (typeof input === "string") {
      date = new Date(input);
    } else if (input instanceof Date) {
      date = input;
    } else {
      throw new Error("Invalid input type for formatDate");
    }

    return date.toLocaleDateString(undefined, options);
  }

  return (
    <div className="lector-attendance-history-container">
      <h2>História dochádzky mojich krúžkov</h2>
      <div className="course-buttons">
        <Grid container spacing={2} sx={{ padding: "0 2rem" }}>
          {lectorCourses &&
            lectorCourses.map((course) => (
              <Grid key={course._id} item xs={12} sm={6} md={4} lg={3}>
                <Button
                  variant="contained"
                  onClick={() => handleCourseClick(course)}
                  fullWidth
                  sx={{ paddingLeft: "1rem", paddingRight: "1rem" }}
                >
                  {course.schoolName} - {course.language} - {course.time}
                </Button>
              </Grid>
            ))}
        </Grid>
      </div>

      {selectedCourse && (
        <Box className="attendance-history-table" sx={{ width: "90%", margin: "0 auto" }}>
          <h2>
            Dochádzka pre krúžok: {selectedCourse.schoolName} - {selectedCourse.time}
          </h2>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Dátum</TableCell>
                  <TableCell>Týždeň</TableCell>
                  <TableCell>Konal sa</TableCell>
                  <TableCell>Počet zúčastnených</TableCell>
                  <TableCell>Počet chýbajúcich</TableCell>
                  <TableCell>Počet ospravedlnených</TableCell>
                  <TableCell>Detaily</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedCourse.childrens &&
                  selectedCourse.childrens.map((student) => (
                    <TableRow key={student._id}>
                      <TableCell>{formatDate(student.date)}</TableCell>
                      <TableCell>{student.note}</TableCell>
                      <TableCell>{student.held ? "Yes" : "No"}</TableCell>
                      <TableCell>{student.attended.length}</TableCell>
                      <TableCell>{student.absent.length}</TableCell>
                      <TableCell>{student.apologies.length}</TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => showAttendanceDetails(student._id)}>
                          Zobraziť detaily
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <MuiAlert elevation={6} variant="filled" onClose={handleSnackbarClose} severity="success">
          Údaje o dochádzke boli úspešne načítané!
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default LectorAttendanceHistory;
