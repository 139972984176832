import {
  IconButton,
  AppBar,
  Toolbar,
  Typography,
  Stack,
  Box,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { FiLogOut, FiCreditCard } from "react-icons/fi";
import { IoMdSchool } from "react-icons/io";
import { AiOutlineFileText } from "react-icons/ai";
import { FaChild } from "react-icons/fa";
import { MdCheck } from "react-icons/md";
import { RiUserAddLine } from 'react-icons/ri';
import { RiDownload2Line } from "react-icons/ri";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";

interface LoggedInHeaderProps {
  handleLogout: () => void;
  userId: string;
  userData: any;
}

const LoggedInHeader = ({ handleLogout, userId, userData }: LoggedInHeaderProps) => {
  const [role, setRole] = useState<string>("");
  const [userChildrens, setUserChildrens] = useState<any[]>([]);
  const [isEmptyUserChildrens, setIsEmptyUserChildrens] = useState<boolean>(false);
  const [isemptyUserData, isetIemptyUserData] = useState<boolean>(false);
  const [signedPersonalData, setSignedPersonalData] = useState<boolean>(false);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (userId && userData) {
      setRole(userData.role);
    }
  }, [userId, userData]);

  useEffect(() => {
    if (userData) {
      setUserChildrens(userData.childrens);
    }
  }, [userData]);

  useEffect(() => {
    if(userChildrens && userChildrens.length === 0){
      setIsEmptyUserChildrens(true);
    }
    else{
      setIsEmptyUserChildrens(false);
    }    
  }, [userChildrens]);

  useEffect(() => {
    if (
      !userData.address ||
      !userData.phoneNumber ||
      !userData.name ||
      !userData.surname ||
      !userData.postCode ||
      !userData.city
    ) {
      isetIemptyUserData(true);
    } else {
      isetIemptyUserData(false);
    }
    setSignedPersonalData(userData.signedPersonalData);
  }, [userData]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <List>
        <ListItem button component={Link} to="/user/children/add" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <RiUserAddLine size={20} />
          </ListItemIcon>
          <ListItemText primary={"Prihlásiť dieťa"} />
        </ListItem>
        <ListItem button component={Link} to="/user/children" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FaChild size={20} />
          </ListItemIcon>
          {isEmptyUserChildrens && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton color="error" sx={{ mr: 1 }}>
                ! {/* Exclamation mark */}
              </IconButton>
            </Box>
          )}
          <ListItemText primary={"Moje dieťa"} />
        </ListItem>
        <ListItem button component={Link} to={`/user/courses/${userId}`} onClick={handleDrawerToggle}>
          <ListItemIcon>
            <IoMdSchool size={20} />
          </ListItemIcon>
          <ListItemText primary={"Moje kurzy"} />
        </ListItem>
        <ListItem button component={Link} to="/payments" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <FiCreditCard size={20} />
          </ListItemIcon>
          <ListItemText primary={"Moje platby"} />
        </ListItem>
        {/* <ListItem button component={Link} to="/userboard" onClick={handleDrawerToggle}>
          <ListItemIcon><AiOutlineFileText size={20} /></ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem> */}
        <ListItem button component={Link} to="/attend" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <MdCheck size={20} />
          </ListItemIcon>
          <ListItemText primary={"Dochádzka detí"} />
        </ListItem>
        <ListItem button component={Link} to="/request" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <HiOutlineUserCircle size={20} />
          </ListItemIcon>
          <ListItemText primary={"Žiadosti"} />
        </ListItem>
        <ListItem button component={Link} to="/for-download" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <RiDownload2Line size={20} />
          </ListItemIcon>
          <ListItemText primary={"Na stiahnutie"} />
        </ListItem>
        <ListItem button component={Link} to="/profile" onClick={handleDrawerToggle}>
          <ListItemIcon>
            <AiOutlineFileText size={20} />
          </ListItemIcon>
          {(isemptyUserData || !signedPersonalData) && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton color="error" sx={{ mr: 1 }}>
                ! {/* Exclamation mark */}
              </IconButton>
            </Box>
          )}
          <ListItemText primary={"Môj profil"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      {role === "user" ? (
        <AppBar position="static" sx={{ backgroundColor: "#336699", marginTop: "2px" }}>
          <Toolbar>
            {isMobile ? (
              <>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                  <MenuIcon />
                </IconButton>
                <Drawer anchor="left" open={mobileOpen} onClose={handleDrawerToggle}>
                  {drawer}
                </Drawer>
              </>
            ) : (
              <Stack direction="row" alignItems="center" spacing={1}>
                {/* Existing Menu Items */}
                <Link to="/user/children/add" style={{ textDecoration: "none", color: "inherit" }}>
                  <IconButton color="inherit">
                    <RiUserAddLine size={20} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Prihlásiť dieťa
                    </Typography>
                  </IconButton>
                </Link>
                <Link to="/user/children" style={{ textDecoration: "none", color: "inherit" }}>
                  {isEmptyUserChildrens ? ( // Render exclamation mark and red color when empty
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton color="error" sx={{ mr: -1 }}>
                        ! {/* Exclamation mark */}
                      </IconButton>
                      <IconButton color="inherit">
                        {" "}
                        <FaChild size={20} />
                      </IconButton>
                      <Typography variant="body1" sx={{ color: "error" }}>
                        Moje dieťa
                      </Typography>
                    </Box>
                  ) : (
                    <IconButton color="inherit">
                      <FaChild size={20} />
                      <Typography variant="body1" sx={{ ml: 1 }}>
                        Moje dieťa
                      </Typography>
                    </IconButton>
                  )}
                </Link>
                <Link to={`/user/courses/${userId}`} style={{ textDecoration: "none", color: "inherit" }}>
                  <IconButton color="inherit">
                    <IoMdSchool size={20} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Moje kurzy
                    </Typography>
                  </IconButton>
                </Link>
                <Link to="/payments" style={{ textDecoration: "none", color: "inherit" }}>
                  <IconButton color="inherit">
                    <FiCreditCard size={20} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Moje platby
                    </Typography>
                  </IconButton>
                </Link>
                {/* <Link to="/userboard" style={{ textDecoration: "none", color: "inherit" }}>
                <IconButton color="inherit">
                  <AiOutlineFileText size={20} />
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    Dashboard
                  </Typography>
                </IconButton>
              </Link> */}
                <Link to="/attend" style={{ textDecoration: "none", color: "inherit" }}>
                  <IconButton color="inherit">
                    <MdCheck size={20} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Dochádzka detí
                    </Typography>
                  </IconButton>
                </Link>
                <Link to="/for-download" style={{ textDecoration: "none", color: "inherit" }}>
                  <IconButton color="inherit">
                    <RiDownload2Line size={20} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Na stiahnutie
                    </Typography>
                  </IconButton>
                </Link>
                <Link to="/request" style={{ textDecoration: "none", color: "inherit" }}>
                  <IconButton color="inherit">
                    <HiOutlineUserCircle size={20} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Žiadosti
                    </Typography>
                  </IconButton>
                </Link>
                <Link to="/profile" style={{ textDecoration: "none", color: "inherit" }}>
                  <IconButton color="inherit">
                    {(isemptyUserData || !signedPersonalData) ? ( // Render exclamation mark and red color when empty}
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <IconButton color="error" sx={{ mr: -1 }}>
                          ! {/* Exclamation mark */}
                        </IconButton>
                      </Box>
                    ) : null}
                    <AiOutlineFileText size={20} />
                    <Typography variant="body1" sx={{ ml: 1 }}>
                      Môj profil
                    </Typography>
                  </IconButton>
                </Link>
              </Stack>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <IconButton color="inherit" onClick={handleLogout}>
              <FiLogOut size={20} />
              <Typography variant="body1" sx={{ ml: 2 }}>
                Odhlásiť sa
              </Typography>
            </IconButton>
          </Toolbar>
        </AppBar>
      ) : null}
    </>
  );
};

export default LoggedInHeader;
