import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  Snackbar,
} from "@mui/material";
import Alert from "@mui/material/Alert";

import "./Request.css";
import { useAuth } from "../Auth/AuthContext";
import NotLoggedIn from "../Login/NotLoggedIn";
import Cookie from "js-cookie";
import moment from "moment";

interface RequestProps {
  userId: string;
}

type RequestType = "Odhlásanie z kurzu" | "Ospravedlnenka" | "Zlá platba" | "Iné";

interface IRequest {
  type: RequestType;
  description: string;
  date: string;
  status?: "vybavuje sa" | "vybavené" | "zamietnutá";
  childName?: string;
  createdAt: string;
}

const Request = ({ userId }: RequestProps) => {
  const [requestType, setRequestType] = useState<RequestType | "">("");
  const [description, setDescription] = useState<string>("");
  const [requests, setRequests] = useState<IRequest[] | null>(null);
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [studentName, setStudentName] = useState<string>("");
  const { token } = useAuth();
  const [isSubmited, setIsSubmited] = useState(false);

  const url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  // if success than empty the form
  useEffect(() => {
    if (isSubmited) {
      setRequestType("");
      setDescription("");
      setStudentName("");
      setIsSubmited(false);
    }
  }, [isSubmited]);

  useEffect(() => {
    // Fetching requests data from an API based on userId
    const fetchData = async () => {
      if (!userId) return;
      if (!token) return;
      try {
        const response = await fetch(`${url}request/get-interest-by-id/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "X-User-Id": userId, // custom header for user ID
          },
        });
        const data = await response.json();
        setRequests(data);
      } catch (error) {
        console.error("There was an error fetching the data", error);
        setErrorOpen(true);
      }
    };
    fetchData();
  }, [userId, url, token, isSubmited]);

  const handleSubmit = async () => {
    try {
      if (!userId) return;
      if (!token) return;
      const response = await fetch(`${url}request/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "X-User-Id": userId, // custom header for user ID
        },
        body: JSON.stringify({
          type: requestType,
          description: description,
          childName: studentName,
          userId: userId,
          status: "vybavuje sa",
        }),
      });

      if (response.ok) {
        setIsSubmited(true);
        setSuccessOpen(true);
        const newRequest = await response.json();
        setRequests((prevRequests) => [...(prevRequests || []), newRequest]);
      } else {
        throw new Error("Failed to register request");
      }
    } catch (error) {
      console.error("There was an error submitting the request", error);
      setErrorOpen(true);
    }
  };

  // if token is null try token from cookies
  if (!token) {
    const tokenFromCookie = Cookie.get("token");
    if (!tokenFromCookie) {
      return <NotLoggedIn />;
    }
  }

  return (
    <div>
      <div className="request-container">
        <br />  
        <Typography variant="h4">Vytvoriť žiadosť</Typography>
        <form noValidate autoComplete="on">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                label="Typ žiadosti"
                value={requestType}
                onChange={(e) => setRequestType(e.target.value as RequestType)}
                fullWidth
                variant="outlined"
              >
                {["Odhlásanie z kurzu", "Ospravedlnenka", "Zlá platba", "Iné"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Meno dieťaťa"
                fullWidth
                variant="outlined"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Popis"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} className="Grid-item-center">
              <Button className="button-class" variant="contained" color="primary" onClick={handleSubmit}>
                Odoslať žiadosť
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
      <Typography variant="h5" style={{ marginTop: "20px" }}>
        Moje žiadosti
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Typ žiadosti</TableCell>
              <TableCell>Popis</TableCell>
              <TableCell>Meno dieťaťa</TableCell>
              <TableCell>Vytvorené</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests?.map((request, index) => (
              <TableRow key={index}>
                <TableCell>{request.type}</TableCell>
                <TableCell>{request.description}</TableCell>
                <TableCell>{request.childName}</TableCell>
                <TableCell>{moment(request.createdAt).format("MMMM Do YYYY, h:mm:ss a")}</TableCell>
                <TableCell>{request.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Success and error snackbars */}
      <Snackbar open={successOpen} autoHideDuration={6000} onClose={() => setSuccessOpen(false)}>
        <Alert onClose={() => setSuccessOpen(false)} severity="success">
          Úspešne odoslaná žiadosť!
        </Alert>
      </Snackbar>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={() => setErrorOpen(false)}>
        <Alert onClose={() => setErrorOpen(false)} severity="error">
          Pri odosielaní žiadosti sa vyskytla chyba!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Request;
