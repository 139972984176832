import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, Button, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { SchoolInfo } from "./Schools"; // Update the path to match your file structure

const School = () => {
  const [selectedTown, setSelectedTown] = useState<string>("");
  const [filteredSchools, setFilteredSchools] = useState<SchoolInfo[]>([]);
  const [schools, setSchools] = useState<SchoolInfo[]>([]);
  const navigate = useNavigate();

  let url =
    process.env.REACT_APP_ENVIRONMENT === "prod" ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_DEV_URL;

  // Fetch schools from API
  useEffect(() => {
    try {
      fetch(url + "school/allSchools/public",
      {
        headers: {
          "X-Public-Key": process.env.REACT_APP_JWT_SECRET || "",
        },
      }
      )
        .then((response) => response.json())
        .then((data) => {
          setSchools(data.schools);
          setFilteredSchools(data.schools);
        });
    } catch (error) {
      console.log(error);
    }
  }, [url]);

  useEffect(() => {
    setFilteredSchools(schools.filter((school) => school.town === selectedTown || !selectedTown));
  }, [schools, selectedTown]);

  const handleEnroll = (schoolId: string) => {
    // Handle enrollment logic here
    navigate("/courses-of-school/" + schoolId);
  };

  const handleTownChange = (event: any) => {
    setSelectedTown(event.target.value as string);
  };

  return (
    <>
      <br></br>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <FormControl variant="filled" fullWidth style={{ marginBottom: 20 }}>
            <InputLabel id="town-label">Vyber mesto</InputLabel>
            <Select
              labelId="town-label"
              id="town-select"
              value={selectedTown}
              onChange={handleTownChange}
              style={{ fontSize: "1.2rem" }} // Increase font size to make it look bigger
            >
              {Array.from(new Set(schools.map((school) => school.town))).map((town, index) => (
                <MenuItem key={index} value={town}>
                  {town}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <br></br>
      <Grid container spacing={2}>
        {filteredSchools.map((school, index) => (
          <Grid key={index} item xs={12} sm={6} md={4}>
            <Card style={{ height: "100%" }}>
              <CardContent>
                <Typography variant="h5" component="h2"></Typography>
                <Typography gutterBottom>
                  <strong>Mesto:</strong> {school.town}
                </Typography>
                <Typography gutterBottom>
                  <strong>Škola:</strong> {school.name}
                </Typography>
                <Typography gutterBottom>
                  <strong>Adresa:</strong> {school.address}
                </Typography>
                {/* <Typography gutterBottom>
                  <strong>Number of Students:</strong> {school.studentsCount}
                </Typography> */}
                <Typography gutterBottom>
                  <strong>Programovacie jazyky:</strong> Scratch, Python, Roblox
                </Typography>
                <Typography gutterBottom>
                  <strong>Počet krúžkov:</strong> {school.courseId.length ? school.courseId.length : 3}
                </Typography>
                <img
                  src={school.imgURL || "https://jumbodium.com/images/school-default.jpg"}
                  alt="School"
                  style={{ width: "100%", maxHeight: 200, objectFit: "cover" }}
                />
                <Typography>{school.description}</Typography>
                {/* <Typography>
                  <a href={school.websiteUrl} target="_blank" rel="noopener noreferrer">
                    Navštíviť web školy
                  </a>
                </Typography> */}
                  <Button variant="contained" onClick={() => handleEnroll(school._id)} style={{ marginTop: 16 }}>
                    Zobraziť kurzy
                  </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default School;
